import React from "react";
import { Trans } from "react-i18next";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import styles from "./styles.module.scss";
import { Toggle } from "../../../../../../shared/Toggle/Toggle";

export function Heading({ isConected, changeConnection, isLoading }) {
  const { tSmartConfig } = useAppTranslation.SmartConfig();

  return (
    <div className={styles.root}>
      <div className={styles.text}>
        <h4>{tSmartConfig("patientPortal.customDomain.title")}</h4>
        <br />
        <Trans
          i18nKey={tSmartConfig("patientPortal.customDomain.description")}
          components={{
            b: <b />,
            br: <br />,
          }}
        />
        <div className={styles.importantInfo}>
          {tSmartConfig("patientPortal.customDomain.importantInformation")}
        </div>
      </div>
      <Toggle
        isEnabled={Boolean(isConected)}
        isDisabled={isLoading}
        onChange={() => changeConnection()}
      />
    </div>
  );
}
