import React, { useState } from "react";
import { Input } from "../../../../../../shared/Input/Input";
import { Button } from "../../../../../../shared/Button/Button";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { isValidDomainName } from "../../../../../../utilities/general";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";
import styles from "./SubDomainForm.module.scss";

export function SubDomainForm({ onAddDomain, isLoading }) {
  const { tSmartConfig } = useAppTranslation.SmartConfig();
  const [error, setError] = useState(false);
  const [domainName, setDomainName] = useState(null);

  const addDomain = () => {
    if (domainName && isValidDomainName(domainName)) {
      setError(false);
      onAddDomain({ domain: domainName });
    } else {
      setError(true);
    }
  };

  const handleNameChange = (e) => {
    const name = e.target?.value;
    setDomainName(name);
  };

  return (
    <div className={styles.root}>
      <div className={styles.domainForm}>
        <label className={styles.formLabel} htmlFor="domainForm">
          {tSmartConfig("patientPortal.customDomain.domain")}{" "}
          <span className={styles.required}>*</span>
        </label>
        <Input
          id="domainForm"
          value={domainName}
          onChange={(e) => handleNameChange(e)}
        />
      </div>
      {error && (
        <div className={styles.error}>
          {tSmartConfig("patientPortal.customDomain.error.domainName")}
        </div>
      )}
      <div className={styles.btnContinue}>
        <Button
          isDisabled={isLoading}
          isLoading={isLoading}
          onClick={() => addDomain()}
          rightAdornment={isLoading ? <CircularProgress color="white" /> : null}
        >
          {tSmartConfig("patientPortal.customDomain.continue")}
        </Button>
      </div>
    </div>
  );
}
