import React from "react";
import { AlertBox } from "../../../../../shared/AlertBox/AlertBox";
import styles from "./styles.module.scss";

export function UsedInPackagesAlert({ usedInPackages }) {
  if (
    !usedInPackages ||
    (!usedInPackages.charting?.length && !usedInPackages.discount?.length)
  ) {
    return null;
  }

  return (
    <AlertBox closable size="small" variant="error" className="m-t-10">
      <h4>
        This product is in use in the following items. Please remove it before
        deleting.
      </h4>
      <br />
      <div className={styles.content}>
        {usedInPackages?.charting?.length > 0 && (
          <div>
            <h5>Pricing Variation</h5>
            <ul className="m-l-20">
              {usedInPackages.charting.map((x) => (
                <li key={x}>{x}</li>
              ))}
            </ul>
          </div>
        )}
        {usedInPackages?.discount?.length > 0 && (
          <div>
            <h5>Promotions</h5>
            <ul className="m-l-20">
              {usedInPackages.discount.map((x) => (
                <li key={x}>{x}</li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </AlertBox>
  );
}
