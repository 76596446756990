import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useQueryClient } from "@tanstack/react-query";
import classes from "./sass/ClientHandler.module.scss";
import { EntityRow } from "../../components/EntityRow";
import { MODE } from "./ClientHandler.consts";
import { FormDefault } from "./containers/FormDefault";
import { FormEdit } from "./containers/FormEdit";
import { useAutoResetState } from "../../../../utilities/hooks/useAutoResetState";
import { FormCreate } from "./containers/FormCreate";
import { QUERY_KEYS } from "../../../../consts/api";
import { apiDateToDob, dobToApiDate } from "./ClientHandler.utils";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { APPOINTMENT_TYPES } from "../../Event.consts";
import { phoneUtil } from "../../../../utilities/phone";
import GfeInputs from "../../components/appointment/GfeInputs";
import { useCurrentUserQuery } from "../../../../api/queries/useUserQuery";

export function ClientHandler({
  readonly,
  id,
  name,
  email,
  phone,
  dob: dob_,
  isNameError,
  isEmailError,
  isPhoneError,
  isDobError,
  onSelect,
  onUpdate,
  onNameChange,
  changeIsSubmitActive,
  appointmentType,
  isGfeRequired,
  handleDob,
  hasDobError,
  state,
  handleState,
  hasStateError,
}) {
  const { tCommon } = useAppTranslation.Common();
  const queryClient = useQueryClient();
  const [mode, setMode] = useState(MODE.default);
  const [formAlert, setFormAlert] = useAutoResetState(null, null);
  const { data: user } = useCurrentUserQuery();

  const dob = apiDateToDob(dob_, user?.dateFormat);

  const onUpdateSuccess = (id, formValues, alertMessage) => {
    onUpdate({
      id,
      ...formValues,
      phone: phoneUtil.compose(formValues.phone),
      dob: dobToApiDate(formValues.dob, user?.dateFormat),
    });
    setMode(MODE.default);
    setFormAlert(alertMessage);
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.patientByPattern],
    });
  };

  const isPhoneRequired = appointmentType === APPOINTMENT_TYPES.virtual;

  useEffect(() => {
    if (mode !== MODE.default) {
      changeIsSubmitActive(false);
    } else {
      changeIsSubmitActive(true);
    }
  }, [mode]);

  return (
    <EntityRow
      width="none"
      label={tCommon("label.patient")}
      className={classes.clientEntity}
    >
      {(readonly || mode === MODE.default) && (
        <FormDefault
          readonly={readonly}
          name={name}
          onNameChange={onNameChange}
          email={email}
          phone={phone}
          dob={dob}
          isNameError={isNameError}
          isEmailError={isEmailError}
          isPhoneError={isPhoneError}
          isDobError={isDobError}
          onSelectClient={onSelect}
          setMode={setMode}
          alertMessage={formAlert}
          isClientSelected={Boolean(id)}
        />
      )}
      {mode === MODE.edit && (
        <FormEdit
          id={id}
          name={name}
          email={email}
          phone={phone}
          dob={dob}
          onSuccess={(id, values) =>
            onUpdateSuccess(id, values, tCommon("info.updatePatientProfile"))
          }
          setMode={setMode}
          isPhoneRequired={isPhoneRequired}
        />
      )}
      {mode === MODE.create && (
        <FormCreate
          name={name}
          onSuccess={(id, values) =>
            onUpdateSuccess(id, values, tCommon("info.createPatientProfile"))
          }
          setMode={setMode}
          isPhoneRequired={isPhoneRequired}
        />
      )}
      {isGfeRequired && (
        <GfeInputs
          dob={dob}
          handleDob={handleDob}
          state={state}
          handleState={handleState}
          hasDobError={hasDobError}
          hasStateError={hasStateError}
        />
      )}
    </EntityRow>
  );
}

ClientHandler.propTypes = {
  readonly: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  isNameError: PropTypes.bool,
  isEmailError: PropTypes.bool,
  isPhoneError: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onNameChange: PropTypes.func.isRequired,
  onPhoneCodeChange: PropTypes.func.isRequired,
  changeIsSubmitActive: PropTypes.func.isRequired,
  appointmentType: PropTypes.string.isRequired,
};

ClientHandler.defaultProps = {
  readonly: false,
  id: null,
  name: "",
  email: "",
  phone: "",
  isNameError: false,
  isEmailError: false,
  isPhoneError: false,
};
