import { useMutation } from "@tanstack/react-query";
import { http } from "../../../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../../../consts/api";

export const useEditAdditionalClinicSurges = (
  { clinicId, id },
  options = {},
) => {
  const res = useMutation(
    (dto) =>
      http.put(HTTP_ENDPOINTS.customTax.editCustomTax({ clinicId, id }), dto),
    { ...options },
  );
  return res;
};
