import React from "react";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";
import StripePaymentForm from "../../../../../Sales/FrontdeskCheckout/StripePaymentForm";

const NewCard = ({ showLoader, onSubmit }) => {
  return showLoader ? (
    <div className="m-h-200 justify-center items-center">
      <CircularProgress />
    </div>
  ) : (
    <StripePaymentForm
      type={"setupIntent"}
      onSubmit={onSubmit}
      onSubmitText={"Save"}
    />
  );
};

export default NewCard;
