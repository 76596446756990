export const REDUCER_NAME = "inventory/productPricingMember";

export const DEFAULT_MEMBERSHIP = {
  label: "For All Memberships",
  value: 0,
};

export const ACTION_TYPES = {
  setClinics: `${REDUCER_NAME}/setClinics`,
  setInitialized: `${REDUCER_NAME}/setInitialized`,
  setForAllMemberships: `${REDUCER_NAME}/setForAllMemberships`,
  setForAllClinics: `${REDUCER_NAME}/setForAllClinics`,
  selectMembership: `${REDUCER_NAME}/selectMembership`,
  setMemberships: `${REDUCER_NAME}/setMemberships`,
  setPrices: `${REDUCER_NAME}/setPrices`,
  changePrice: `${REDUCER_NAME}/changePrice`,
  setOriginalToChangedPrices: `${REDUCER_NAME}/setOriginalToChangedPrices`,
  setDefaultMembershipAfterInit: `${REDUCER_NAME}/setDefaultMembershipAfterInit`,
};
