import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";
import { Checkbox } from "../../../../shared/Checkbox/Checkbox";
import styles from "./TemplateSelector.module.scss";

export const formatTemplates = {
  soap: {
    id: "soap",
    label: "SOAP Note",
  },
  procedure: {
    id: "procedure",
    label: "Procedure Note",
  },
  surgical: {
    id: "surgical",
    label: "Surgical Note",
  },
  followup: {
    id: "followup",
    label: "Follow-up Visit",
  },
};

export function TemplateSelector({
  text,
  onChange,
  onClose,
  hideNoteSaveOption = false,
}) {
  const { tCommon } = useAppTranslation.Common();
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [customPrompt, setCustomPrompt] = useState("");
  const [saveAsProcedure, setSaveAsProcedure] = useState(true);

  const templates = Object.entries(formatTemplates).map(([id, template]) => ({
    id,
    label: template.label,
  }));

  const handleSubmit = () => {
    if (!text) {
      uiNotification.error(tCommon("dictation.error.noTextToFormat"));
      return;
    }

    if (!selectedTemplateId && !customPrompt) {
      uiNotification.error(tCommon("dictation.error.selectTemplateOrPrompt"));
      return;
    }

    onChange({
      selectedTemplateId,
      customPrompt,
      saveAsProcedure,
    });
  };

  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <h1 className={styles.title}>
          {tCommon("dictation.title.selectTemplate")}
        </h1>

        <div className={styles.modalContentInner}>
          <div className={styles.customPromptContainer}>
            <div className={styles.customPromptHeader}>
              <h2>Create a Custom Prompt</h2>
            </div>
            <div className={styles.customPromptInputWrapper}>
              <textarea
                value={customPrompt}
                onChange={(e) => setCustomPrompt(e.target.value)}
                placeholder={tCommon("dictation.placeholder.customPrompt")}
                className={styles.customPromptInput}
              />
            </div>
          </div>

          <div className={styles.templateOptions}>
            {templates.map((template) => (
              <button
                key={template.id}
                onClick={() => setSelectedTemplateId(template.id)}
                className={clsx(
                  styles.templateButton,
                  selectedTemplateId === template.id &&
                    styles.templateButtonSelected,
                )}
              >
                {template.label}
              </button>
            ))}
          </div>

          <div className={styles.saveOption}>
            {!hideNoteSaveOption && (
              <Checkbox
                size="small"
                inverted
                isChecked={saveAsProcedure}
                onChange={() => setSaveAsProcedure(!saveAsProcedure)}
                label={tCommon("dictation.checkbox.saveAsProcedureNote")}
              />
            )}
          </div>

          <div className={styles.modalActions}>
            <button onClick={onClose} className={styles.cancelButton}>
              {tCommon("button.cancel")}
            </button>
            <button
              onClick={handleSubmit}
              className={styles.saveButton}
              disabled={!selectedTemplateId && !customPrompt}
            >
              {tCommon("button.save")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

TemplateSelector.propTypes = {
  text: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  hideNoteSaveOption: PropTypes.bool,
};

TemplateSelector.defaultProps = {
  hideNoteSaveOption: false,
};
