import React, { useEffect, useRef, useState } from "react";
import queryString from "query-string";
import { withRouter } from "react-router-dom";
import { ConfirmModal } from "../../boxes/ConfirmModal/ConfirmModal";
import { removeNullishFromShape } from "../../utilities/general";

export var SKIP_QUERY_KEY = "skipPrompt";

export const RouterPrompt = withRouter((props) => {
  const {
    when,
    whenFn,
    title,
    text,
    nativePrompt,
    confirmTitle,
    size,
    customConfirm,
    handleCancel,
  } = props;

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState("");
  const [isConfirming, setIsConfirming] = useState(false);

  const unblockRef = useRef();

  function handleShowModal() {
    setShowPrompt(true);
  }

  function onCancel() {
    setShowPrompt(false);
    unblockRef.current?.();
    handleCancel?.(currentPath);
  }

  useEffect(() => {
    unblockRef.current = props.history.block((location) => {
      var searchParams = queryString.parse(location.search);

      if (searchParams?.[SKIP_QUERY_KEY] === "true") {
        const nextSearch = queryString.stringify(
          removeNullishFromShape({
            ...searchParams,
            [SKIP_QUERY_KEY]: undefined,
          }),
        );

        location.search = nextSearch ? `?${nextSearch}` : "";

        return true;
      }

      if (when || whenFn?.()) {
        if (nativePrompt) {
          return window.confirm(text);
        }
        setCurrentPath(location.pathname);
        handleShowModal();
        return false;
      }

      return true;
    });
    return () => {
      unblockRef.current && unblockRef.current();
    };
  }, [when, whenFn]);

  function handleConfirm() {
    try {
      if (customConfirm) {
        unblockRef.current?.();
        customConfirm(currentPath);
        setShowPrompt(false);
      } else {
        if (unblockRef) {
          unblockRef.current();
        }
        setShowPrompt(false);
        props.history.push(currentPath);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    } finally {
      setIsConfirming(false);
    }
  }

  return showPrompt ? (
    <ConfirmModal
      size={size}
      isOpen={true}
      title={title}
      confirmTitle={confirmTitle}
      isConfirming={isConfirming}
      onConfirm={handleConfirm}
      onCancel={onCancel}
      onClose={() => {
        setShowPrompt(false);
      }}
    >
      {text}
    </ConfirmModal>
  ) : null;
});
