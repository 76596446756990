import { removeNullishFromShape } from "../../../utilities/general";
import { REDUCER_NAME } from "./config";

export const selectors = {
  /**
   * @returns {boolean}
   */
  selectForAllClinics: (state) => state[REDUCER_NAME].forAllClinics,

  /**
   * @returns {boolean}
   */
  selectForAllMemberships: (state) => state[REDUCER_NAME].forAllMemberships,

  /**
   * @returns {{
   *    label: string;
   *    value: number;
   * } | null}
   */
  selectMembership: (state) => state[REDUCER_NAME].membership || null,

  /**
   * @returns {Array<{
   *    label: string;
   *    value: number;
   * }>}
   */
  selectMemberships: (state) => state[REDUCER_NAME].memberships || [],

  /**
   * @returns {() => {
   *    perUnit: number;
   *    member: number;
   *    perClinic: {
   *      [clinicId: string]: {
   *        perUnit: number;
   *        member: number;
   *        membershipId: number;
   *      };
   *    };
   * }}
   */
  selectOriginalPrices: (state) => () =>
    state[REDUCER_NAME]?.prices?.original || {},

  /**
   * @returns {(clinicId: string | number) => string | number}
   */
  selectPerUnitPrice: (state) => (clinicId) => {
    if (clinicId) {
      return (
        state[REDUCER_NAME]?.prices?.regular?.perClinic?.[clinicId]?.perUnit ||
        0
      );
    }

    return state[REDUCER_NAME]?.prices?.regular?.perUnit || 0;
  },

  /**
   * @returns {(clinicId: string | number) => string | number}
   */
  selectMemberPrice: (state) => (clinicId) => {
    if (clinicId) {
      return state[REDUCER_NAME]?.prices?.changed?.perClinic?.[clinicId]
        ?.member;
    }

    return state[REDUCER_NAME]?.prices?.changed?.member;
  },

  selectChangedPricesForRequest: (state) => () => {
    const state_ = state[REDUCER_NAME] || {};

    const changedPrices = state_.prices?.changed || {};
    const forAllClinics = state_.forAllClinics;

    const forAllMemberships = state_.forAllMemberships;

    const membership = state_.membership;
    const clinicIds = state_.clinics?.map((c) => c.id) || [];

    return clinicIds.map((clinicId) =>
      removeNullishFromShape({
        clinic_id: Number(clinicId),
        membership_tier_id: forAllMemberships ? 0 : membership?.value,
        location_price_per_member: parseFloat(
          forAllClinics
            ? changedPrices.member
            : changedPrices.perClinic?.[clinicId]?.member,
        ),
      }),
    );
  },

  selectInitialized: (state) => Boolean(state[REDUCER_NAME].initialized),

  /**
   * @returns {Array<{
   *    id: number;
   *    name: string;
   * }>}
   */
  selectClinics: (state) => state[REDUCER_NAME].clinics || [],

  /**
   * @returns {() => {
   *    perUnit: number | string;
   *    member: number | string;
   *    perClinic: {
   *      [clinicId: string]: {
   *        perUnit: number | string;
   *        member: number | string;
   *        membershipId: number;
   *      };
   *    };
   * }}
   */
  selectChangedPrices: (state) => () =>
    state[REDUCER_NAME].prices?.changed || {},

  selectSetDefaultMembershipAfterInit: (state) =>
    state[REDUCER_NAME].setDefaultMembershipAfterInit || false,
};
