import React from "react";
import { Button } from "../../../../../../../shared/Button/Button";
import { CircularProgress } from "../../../../../../../shared/CircularProgress/CircularProgress";
import styles from "./styles.module.scss";

export function Footer({ onCancel, onSave, isSaving }) {
  return (
    <div className={styles.root}>
      <Button variant="outlined" onClick={onCancel}>
        Cancel
      </Button>
      <Button
        onClick={onSave}
        isDisabled={isSaving}
        leftAdornment={
          isSaving ? <CircularProgress color="white" size="small" /> : undefined
        }
      >
        Save
      </Button>
    </div>
  );
}
