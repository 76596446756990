import { ACTION_TYPES } from "./consts";
import { PROVIDER_ROOM_PROCEDURE_TYPE } from "../../api/queries/useProviderRoomProceduresQuery";

const initialState = {
  signProceduresData: null,
  isSigning: false,
  isSigned: false,
  proceduresListForSign: [],
  activeTab: PROVIDER_ROOM_PROCEDURE_TYPE.cosmetic,
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.signProceduresDataChange: {
      return {
        ...state,
        signProceduresData: action.payload || null,
      };
    }
    case ACTION_TYPES.signProceduresDataReset: {
      return {
        ...state,
        signProceduresData: null,
      };
    }
    case ACTION_TYPES.proceduresSigningStatusChange: {
      return {
        ...state,
        isSigning: action.payload.status,
      };
    }
    case ACTION_TYPES.proceduresSignedStatusChange: {
      return {
        ...state,
        isSigned: action.payload.status,
      };
    }
    case ACTION_TYPES.addProcedureToSignList: {
      return {
        ...state,
        proceduresListForSign: [
          ...state.proceduresListForSign,
          action.payload.procedureId,
        ],
      };
    }
    case ACTION_TYPES.addFilledSignList: {
      return {
        ...state,
        proceduresListForSign: action.payload.filledSignList,
      };
    }
    case ACTION_TYPES.removeProcedureFromSignList: {
      return {
        ...state,
        proceduresListForSign: state.proceduresListForSign.filter(
          (id) => id !== action.payload.procedureId,
        ),
      };
    }
    case ACTION_TYPES.clearProcedureSignList: {
      return {
        ...state,
        proceduresListForSign: [],
      };
    }
    case ACTION_TYPES.setActiveTab: {
      return {
        ...state,
        activeTab:
          action.payload.newTab || PROVIDER_ROOM_PROCEDURE_TYPE.cosmetic,
      };
    }
    default: {
      return state;
    }
  }
}
