import React, { useState, useMemo } from "react";
import { Table } from "../../../../../shared/Table/Table";
import { Button } from "../../../../../shared/Button/Button";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { DeleteIcon } from "../../../../../assets/Icons/DeleteIcon";
import { EditIcon } from "../../../../../assets/Icons/EditIcon";
import { useDeleteDomainMutation } from "../../../../../api/customDomain/mutations/useDeleteDomainMutation";
import { uiNotification } from "../../../../../services/UINotificationService";
import { ConfirmModal } from "../../../../../boxes/ConfirmModal/ConfirmModal";
import { SUBDOMAIN_STATUSES } from "../../../../../api/customDomain/queries/useGetCustomDomainsQuery";
import { showFormattedDate } from "../../../../../Utils";
import styles from "./ExistingSubDomains.module.scss";

export function ExistingSubDomains({ domainsList, setSubdomain }) {
  const { tSmartConfig } = useAppTranslation.SmartConfig();
  const { isLoading, mutateAsync: deleteDomain } = useDeleteDomainMutation({
    onSuccess: () => {
      setLoadingQueue((prev) => prev.filter((el) => el !== currentDomain));
      uiNotification.success(
        tSmartConfig("patientPortal.customDomain.success.delete"),
      );
    },
    onError: () => {
      uiNotification.error(
        tSmartConfig("patientPortal.customDomain.error.delete"),
      );
    },
  });

  const [loadingQueue, setLoadingQueue] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentDomain, setCurrentDomain] = useState(null);

  const openConfirmModal = (id) => {
    setCurrentDomain(id);
    setIsOpen(true);
  };

  const onDeleteDomain = () => {
    if (currentDomain) {
      setLoadingQueue((prev) => [...prev, currentDomain]);
      deleteDomain({ domainId: currentDomain });
      setIsOpen(false);
    }
  };

  const onEditDomain = (domain) => {
    setSubdomain({ subdomain: domain });
  };

  const onClose = () => {
    setCurrentDomain(null);
    setIsOpen(false);
    setSubdomain({ subdomain: "" });
  };

  const renderActions = (domain) => {
    return (
      <div className={styles.actionButtons}>
        <Button
          isLoading={loadingQueue.includes(domain.id)}
          isDisabled={loadingQueue.includes(domain.id)}
          onClick={() => openConfirmModal(domain.id)}
          size="small"
          variant="outlined"
        >
          <DeleteIcon width="16px" />
        </Button>
        <Button
          isLoading={loadingQueue.includes(domain.id)}
          isDisabled={loadingQueue.includes(domain.id)}
          size="small"
          variant="outlined"
          onClick={() => onEditDomain(domain.id)}
        >
          <EditIcon width="16px" />
        </Button>
      </div>
    );
  };

  const renderStatus = (status) => {
    switch (status) {
      case SUBDOMAIN_STATUSES.active:
        return (
          <span className={styles.verified}>
            {tSmartConfig("patientPortal.customDomain.verified")}
          </span>
        );
      case SUBDOMAIN_STATUSES.pending:
        return (
          <span className={styles.pending}>
            {tSmartConfig("patientPortal.customDomain.pending")}
          </span>
        );
      default:
        return (
          <span className={styles.inactive}>
            {tSmartConfig("patientPortal.customDomain.inactive")}
          </span>
        );
    }
  };

  const adaptatedData = useMemo(() => {
    return domainsList?.map((item) => ({
      ...item,
      status: renderStatus(item.status),
      actions: renderActions(item),
      verified_at: item?.verified_at
        ? showFormattedDate(item?.verified_at, false)
        : "",
    }));
  }, [domainsList]);

  const cols = [
    {
      data: tSmartConfig("patientPortal.customDomain.subdomain"),
      accessor: "domain",
    },
    {
      data: tSmartConfig("patientPortal.customDomain.status"),
      accessor: "status",
    },
    {
      data: tSmartConfig("patientPortal.customDomain.connectionData"),
      accessor: "verified_at",
    },
    {
      data: tSmartConfig("patientPortal.customDomain.actions"),
      accessor: "actions",
    },
  ];

  return (
    <div className={styles.root}>
      <div>
        <h4>{tSmartConfig("patientPortal.customDomain.existingSubDomains")}</h4>
        <div>
          <i>{tSmartConfig("patientPortal.customDomain.domainDisclaimer")}</i>
        </div>
      </div>
      <div className={styles.tableWrapper}>
        <Table
          headColClassName={styles.tableHeader}
          className={styles.table}
          data={adaptatedData}
          cols={cols}
        />
      </div>

      {isOpen && (
        <ConfirmModal
          onClose={() => onClose()}
          isLoading={isLoading}
          isDisabled={isLoading}
          onCancel={() => onClose()}
          isOpen={isOpen}
          confirmTitle="Delete"
          onConfirm={() => onDeleteDomain()}
        >
          {tSmartConfig("patientPortal.customDomain.warning")}
        </ConfirmModal>
      )}
    </div>
  );
}
