import { useMemo } from "react";
import { useClinicPaymentAbilitiesAndCancelPolicyQuery } from "../../../../api/queries/useClinicPaymentAbilitiesAndCancelPolicyQuery";
import { sortAlphabetically } from "../../../../utilities/general";

export function useClinics() {
  const { data: clinicsData, isLoading } =
    useClinicPaymentAbilitiesAndCancelPolicyQuery();

  const clinics = useMemo(() => {
    const clinics = Object.entries(clinicsData?.data?.data?.clinics || {})
      .filter(([_, { can_take_payment }]) => can_take_payment)
      .map(([id, { name }]) => ({
        value: Number(id),
        label: name,
      }));
    clinics.sort(sortAlphabetically("label"));
    return clinics;
  }, [clinicsData?.data?.data?.clinics]);

  return {
    data: clinics,
    isLoading,
  };
}
