import { useEffect } from "react";
import { dispatch } from "../../../../../../../store/store";
import { productPricingMember } from "../../../../../../../store/inventory/productPricingMember";
import {
  prepareMemberships,
  preparePrices,
} from "../../../../../../../store/inventory/productPricingMember/utilities";
import { useClinics } from "../../hooks/useClinics";
import { DEFAULT_MEMBERSHIP } from "../../../../../../../store/inventory/productPricingMember/config";

const { actions } = productPricingMember;

export function useInit(initData, isInitialized, setDefaultMembership) {
  const clinics = useClinics();

  const { membership_tiers, product } = initData || {
    membership_tiers: [],
    product: {},
  };

  useEffect(() => {
    if (!isInitialized && clinics.isSuccess) {
      dispatch(actions.setMemberships(prepareMemberships(membership_tiers)));

      const prices = [...(product?.product_price_per_clinic || [])];
      prices.reverse();

      dispatch(
        actions.setPrices(
          preparePrices({
            clinics: clinics.data,
            rawPrices: prices,
          }),
          prices,
        ),
      );

      dispatch(
        actions.setClinics(
          clinics.data.map((c) => ({
            id: c.id,
            name: c.clinic_name,
          })),
        ),
      );

      if (setDefaultMembership) {
        dispatch(actions.selectMembership(DEFAULT_MEMBERSHIP));
        dispatch(actions.setForAllClinics(false));
      }
      dispatch(actions.setDefaultMembershipAfterInit(false));
      dispatch(actions.setInitialized(true));
    }
  }, [
    clinics.data,
    clinics.isSuccess,
    product?.product_price_per_clinic,
    isInitialized,
    membership_tiers,
    setDefaultMembership,
  ]);

  return {
    isLoading: clinics.isLoading,
  };
}
