import React from "react";
import classes from "./scss/SalesRestrictedReportWarning.module.scss";

const SalesRestrictedReportWarning = () => {
  return (
    <div className={classes.warningBox}>
      <p>
        This report is only available to Aesthetic Record accounts using Point
        of Sale. Please enable your Point of Sale to unlock this report.
      </p>
    </div>
  );
};

export default SalesRestrictedReportWarning;
