import { useQuery } from "@tanstack/react-query";
import { http } from "../../../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../../../consts/api";

export const useAdditionalClinicSurges = (clinicId, options = {}) => {
  const res = useQuery(
    ["additionalClinicSurges"],
    () => {
      return http.get(HTTP_ENDPOINTS.customTax.getCustomTaxes(clinicId));
    },
    { cacheTime: 0, select: (data) => data.data.data, ...options },
  );

  return res
};
