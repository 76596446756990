export const REDUCER_NAME = "providerRoom";

export const ACTION_TYPES = {
  signProceduresDataChange: `${REDUCER_NAME}/signProceduresDataChange`,
  signProceduresDataReset: `${REDUCER_NAME}/signProceduresDataReset`,
  proceduresSigningStatusChange: `${REDUCER_NAME}/proceduresSigningStatusChange`,
  proceduresSignedStatusChange: `${REDUCER_NAME}/proceduresSignedStatusChange`,
  addProcedureToSignList: `${REDUCER_NAME}/addProcedureToSignList`,
  removeProcedureFromSignList: `${REDUCER_NAME}/removeProcedureFromSignList`,
  clearProcedureSignList: `${REDUCER_NAME}/clearProcedureSignList`,
  setActiveTab: `${REDUCER_NAME}/setActiveTab`,
  addFilledSignList: `${REDUCER_NAME}/addFilledSignList`,
};
