import React from "react";
import { connect } from "react-redux";
import classes from "./styles.module.scss";
import { Select } from "../../../../../../shared/Select/Select";
import { productPricingMember } from "../../../../../../store/inventory/productPricingMember";
import { dispatch } from "../../../../../../store/store";
import { useInit } from "./hooks/useInit";
import {
  isDefaultMembership,
  isPricesChanged,
} from "../../../../../../store/inventory/productPricingMember/utilities";
import { MemberPriceInfoLabel } from "./shared/MemberPriceInfoLabel/MemberPriceInfoLabel";
import { Checkbox } from "../shared/Checkbox/Checkbox";
import { Input } from "../shared/Input/Input";
import { SaveButton } from "../shared/SaveButton/SaveButton";
import { ClinicRow } from "../shared/ClinicRow/ClinicRow";
import commonClasses from "../styles.module.scss";
import { PerUnitPrice } from "./shared/PerUnitPrice/PerUnitPrice";
import { ContentLoader } from "../../../../../../boxes/ContentLoader/ContentLoader";
import { isValidPrice } from "../../../../../../store/inventory/utilities";
import { useConfirmUnsaved } from "../hooks/useConfirmUnsaved";

const { selectors, actions } = productPricingMember;

const mapStateToProps = (state) => ({
  forAllClinics: selectors.selectForAllClinics(state),
  forAllMemberships: selectors.selectForAllMemberships(state),
  membership: selectors.selectMembership(state),
  memberships: selectors.selectMemberships(state),
  clinics: selectors.selectClinics(state),
  getPerUnitPrice: selectors.selectPerUnitPrice(state),
  getMemberPrice: selectors.selectMemberPrice(state),
  getOriginalPrices: selectors.selectOriginalPrices(state),
  getChangedPrices: selectors.selectChangedPrices(state),
  isInitialized: selectors.selectInitialized(state),
  setDefaultMembershipAfterInit:
    selectors.selectSetDefaultMembershipAfterInit(state),
});

export const PricingMember = connect(mapStateToProps)(
  ({
    productId,
    initData,
    onSave,
    isSaving,
    perMemberErrors,
    setPerMemberErrors,
    forAllClinicsError,
    setForAllClinicsError,
    forAllClinics,
    forAllMemberships,
    membership,
    memberships,
    clinics,
    getPerUnitPrice,
    getMemberPrice,
    getOriginalPrices,
    getChangedPrices,
    isInitialized,
    setDefaultMembershipAfterInit,
  }) => {
    const { isLoading } = useInit(
      initData,
      isInitialized,
      setDefaultMembershipAfterInit,
    );

    const [ConfirmUnsavedModal, confirm] = useConfirmUnsaved();

    const changeMembershipAction = async (action) => {
      const isChanged = isPricesChanged({
        preparedPrices: getChangedPrices(),
        initialPreparedPrices: getOriginalPrices(),
        forAllClinics,
      });

      if (isChanged && productId && (await confirm())) {
        await onSave();
      }

      action();
    };

    var handleChange =
      (clinicId = undefined) =>
      (e) => {
        var value = e.target.value;

        dispatch(
          actions.changePrice({
            value,
            clinicId,
          }),
        );

        var isError = !isValidPrice(value);

        if (clinicId) {
          setPerMemberErrors((prev) => prev.set(clinicId, isError));
        } else {
          setForAllClinicsError(isError);
        }
      };

    return (
      <ContentLoader isLoading={isLoading}>
        <div className="row">
          <div className="col-xs-12">
            <div className={commonClasses.wrap}>
              <h4>Membership Price</h4>
              <div className={commonClasses.right}>
                <Checkbox
                  label="Same for All Clinics"
                  checked={forAllClinics}
                  className={classes.checkbox}
                  onChange={() => {
                    dispatch(actions.setForAllClinics(!forAllClinics));
                  }}
                />
                <Checkbox
                  label="Same for All Memberships"
                  checked={forAllMemberships}
                  className={classes.checkbox}
                  onChange={() => {
                    dispatch(actions.setForAllMemberships(!forAllMemberships));
                  }}
                />
                <div>
                  <span className={classes.selectMembership}>
                    Select a Membership
                  </span>
                  <Select
                    className={classes.membershipSelect}
                    size="small"
                    options={memberships}
                    value={membership}
                    onChange={(option) => {
                      changeMembershipAction(() => {
                        dispatch(actions.selectMembership(option));
                      });
                    }}
                  />
                </div>
                <SaveButton isLoading={isSaving} onClick={onSave} />
              </div>
            </div>
            {forAllClinics ? (
              <div className="row">
                <div className="col-sm-4 col-xs-12">
                  <PerUnitPrice>{getPerUnitPrice()}</PerUnitPrice>
                </div>
                <div className="col-lg-5 col-md-6 col-sm-8 col-xs-12">
                  <Input
                    placeholder="Price for Members"
                    value={getMemberPrice()}
                    onChange={handleChange()}
                    isError={forAllClinicsError}
                    label={
                      isDefaultMembership(membership)
                        ? "Member Price"
                        : membership?.label
                    }
                  />
                </div>
              </div>
            ) : (
              clinics.map((clinic) => (
                <ClinicRow label={clinic.name} key={clinic.id}>
                  <div className="col-sm-4 col-xs-12">
                    <PerUnitPrice>{getPerUnitPrice(clinic.id)}</PerUnitPrice>
                  </div>
                  <div className="col-md-4 col-sm-5 col-xs-12">
                    <Input
                      placeholder="Price for Members"
                      value={getMemberPrice(clinic.id)}
                      onChange={handleChange(clinic.id)}
                      isError={perMemberErrors.get(clinic.id)}
                      label={
                        !isDefaultMembership(membership) ? (
                          membership.label
                        ) : (
                          <MemberPriceInfoLabel />
                        )
                      }
                    />
                  </div>
                </ClinicRow>
              ))
            )}
          </div>
        </div>
        <ConfirmUnsavedModal />
      </ContentLoader>
    );
  },
);
