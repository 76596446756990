import { positionFooterCorrectly } from "../../Utils/services.js";
import { unwrapOr } from "../../utilities/general.js";
import { http } from "../../services/HttpService.js";
import { uiNotification } from "../../services/UINotificationService.js";
import { queryClient } from "../../api/queryClient.js";
import { QueryKeys } from "../../_legacy/Queries/QueryKeys.js";

positionFooterCorrectly();

const languageData = JSON.parse(localStorage.getItem("languageData"));

export function getAllClients(formData) {
  const data = { params: {} };
  for (let prop in formData.params) {
    if (formData.params.hasOwnProperty(prop) && formData.params[prop]) {
      data.params[prop] = formData.params[prop];
    }
  }
  return async (dispatch) => {
    return http
      .get("clients", data)
      .then((response) => {
        dispatch({ type: "CLIENTS_LIST", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "CLIENTS_LIST", payload: error.response?.data });
        return Promise.reject(error.response?.data);
      });
  };
}

export function getClinicsProvidersProducts() {
  return (dispatch) => {
    http
      .get("patient-filters/create")
      .then((response) => {
        dispatch({ type: "GET_CPP_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_CPP_DATA", payload: error.response.data });
      });
  };
}

export function createFilter(formData) {
  return (dispatch) => {
    http
      .post("patient-filters", formData)
      .then((response) => {
        dispatch({ type: "CREATE_FILTER", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CREATE_FILTER", payload: error.response.data });
      });
  };
}

export function getAllFilters() {
  return (dispatch) => {
    http
      .get("patient-filters")
      .then((response) => {
        dispatch({ type: "GET_ALL_FILTERS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_ALL_FILTERS", payload: error.response.data });
      });
  };
}

export function getOneFilter(filterId) {
  return (dispatch) => {
    http
      .get("patient-filters/" + filterId)
      .then((response) => {
        dispatch({ type: "GET_ONE_FILTER", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_ONE_FILTER", payload: error.response.data });
      });
  };
}

export function updateFilter(formData, filterId) {
  return (dispatch) => {
    http
      .put("patient-filters/" + filterId, formData)
      .then((response) => {
        dispatch({ type: "UPDATE_FILTER", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "UPDATE_FILTER", payload: error.response.data });
      });
  };
}

export function deleteFilter(filterId) {
  return (dispatch) => {
    http
      .delete("patient-filters/" + filterId)
      .then((response) => {
        dispatch({ type: "DELETE_FILTER", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "DELETE_FILTER", payload: error.response.data });
      });
  };
}

export function saveClientFields(formData) {
  return (dispatch) => {
    http
      .post("clients/save_fields", formData)
      .then((response) => {
        dispatch({ type: "SAVE_CLIENT_FIELD", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SAVE_CLIENT_FIELD", payload: error.response.data });
      });
  };
}

export function getClientDetail(clientID, scopes) {
  return async (dispatch) => {
    return http
      .get("clients/" + clientID + "?scopes=" + scopes)
      .then((response) => {
        dispatch({ type: "GET_CLIENT_DETAIL", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "GET_CLIENT_DETAIL",
          payload: unwrapOr(() => error.response.data, {}),
        });
        return Promise.reject(unwrapOr(() => error.response.data, {}));
      });
  };
}

export function fireClient(clientID) {
  return (dispatch) => {
    http
      .put("clients/fire/" + clientID)
      .then((response) => {
        dispatch({ type: "FIRE_CLIENT", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "FIRE_CLIENT", payload: error.response.data });
      });
  };
}

export function changePortaAccess(clientID) {
  return (dispatch) => {
    http
      .put("clients/change_patient_portal_access/" + clientID)
      .then((response) => {
        dispatch({ type: "CHANGE_PORTAL_CLIENT", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CHANGE_PORTAL_CLIENT",
          payload: error.response.data,
        });
      });
  };
}

export function getClientCardData(clientID) {
  return (dispatch) => {
    http
      .get("clients/get_cards/" + clientID)
      .then((response) => {
        dispatch({ type: "GET_CLIENT_CARDS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_CLIENT_CARDS", payload: error.response.data });
      });
  };
}

export function saveClientCard(clientID, formData) {
  return async (dispatch) => {
    return http
      .post("clients/save_credit_card/" + clientID, formData)
      .then((response) => {
        dispatch({ type: "SAVE_CLIENT_CARD", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "SAVE_CLIENT_CARD", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function updateNewMedicalHistory(formData) {
  return async (dispatch) => {
    return http
      .post("clients/new_medical_history", formData)
      .then((response) => {
        dispatch({
          type: "UPDATE_NEW_MEDICAL_HISTORY",
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_NEW_MEDICAL_HISTORY",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
}

export function resendWelcomeEmail(clientID) {
  return (dispatch) => {
    http
      .get("clients/resend_portal_email/" + clientID)
      .then((response) => {
        dispatch({ type: "RESEND_WELCOME_EMAIL", payload: response.data });
        if (response.data.status === 200) {
          uiNotification.success(languageData.global[response.data.message]);
        }
      })
      .catch((error) => {
        dispatch({
          type: "RESEND_WELCOME_EMAIL",
          payload: error.response.data,
        });
      });
  };
}

export function resetPortalPassword(clientID, formData) {
  return (dispatch) => {
    http
      .post("clients/reset_portal_password/" + clientID, formData)
      .then((response) => {
        dispatch({
          type: "RESET_CLIENT_PORTAL_PASSWORD",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "RESET_CLIENT_PORTAL_PASSWORD",
          payload: error.response.data,
        });
      });
  };
}

export function createClient(formData) {
  return async (dispatch) => {
    return http
      .post("clients", formData)
      .then((response) => {
        dispatch({ type: "CREATE_CLIENT", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "CREATE_CLIENT", payload: error.response.data });
        return Promise.reject(error.response?.data);
      });
  };
}

export function updateClient(clientID, formData) {
  return async (dispatch) => {
    return http
      .patch(
        process.env.REACT_APP_API_URL + "clients/" + clientID,
        formData ? formData : "",
      )
      .then((response) => {
        dispatch({ type: "UPDATE_CLIENT", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "UPDATE_CLIENT", payload: error.response?.data });
        return Promise.reject(error.response?.data);
      });
  };
}

export function getClientShopifyDetail(clientID) {
  return async (dispatch) => {
    return http
      .get("shopify/patient-orders", {
        params: { patient_id: clientID },
      })
      .then((response) => {
        dispatch({ type: "GET_CLIENT_SHOPIFY_DETAIL", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "GET_CLIENT_SHOPIFY_DETAIL",
          payload: unwrapOr(() => error.response.data, {}),
        });
        return Promise.reject(unwrapOr(() => error.response.data, {}));
      });
  };
}

export function getClientShopifyPurchased(clientID) {
  return (dispatch) => {
    dispatch({ type: "GET_CLIENT_SHOPIFY_PURCHASED_LOADING" });
    http
      .get("shopify/patient-products", {
        params: { patient_id: clientID },
      })
      .then((response) => {
        dispatch({
          type: "GET_CLIENT_SHOPIFY_PURCHASED",
          payload: response.data,
        });
        dispatch({ type: "GET_CLIENT_SHOPIFY_PURCHASED_DONE" });
      })
      .catch((error) => {
        dispatch({
          type: "GET_CLIENT_SHOPIFY_PURCHASED",
          payload: error.response.data,
        });
        dispatch({ type: "GET_CLIENT_SHOPIFY_PURCHASED_DONE" });
      });
  };
}

export function getClientWallet(clientID) {
  return (dispatch) => {
    dispatch({ type: "GET_CLIENT_WALLET_LOADING" });
    http
      .get("client-wallet/" + clientID)
      .then((response) => {
        dispatch({ type: "GET_CLIENT_WALLET", payload: response.data });
        dispatch({ type: "GET_CLIENT_WALLET_DONE" });
      })
      .catch((error) => {
        dispatch({ type: "GET_CLIENT_WALLET", payload: error.response.data });
        dispatch({ type: "GET_CLIENT_WALLET_DONE" });
      });
  };
}

export function addCreditToWallet(clientID, formData) {
  return (dispatch) => {
    http
      .post("add-credit/" + clientID, formData)
      .then((response) => {
        dispatch({ type: "ADD_CREDIT_TO_WALLET", payload: response.data });
        if (response.data.status !== 200) {
          uiNotification.error(languageData.global[response.data.message]);
        } else {
          uiNotification.success(languageData.global[response.data.message]);
        }
      })
      .catch((error) => {
        dispatch({
          type: "ADD_CREDIT_TO_WALLET",
          payload: error.response.data,
        });
      });
  };
}

export function removeCreditFromWallet(clientID, formData) {
  return (dispatch) => {
    http
      .post("remove-credit/" + clientID, formData)
      .then((response) => {
        dispatch({ type: "REMOVE_CREDIT_FROM_WALLET", payload: response.data });
        if (response.data.status !== 200) {
          uiNotification.error(languageData.global[response.data.message]);
        } else {
          uiNotification.success(languageData.global[response.data.message]);
        }
      })
      .catch((error) => {
        dispatch({
          type: "REMOVE_CREDIT_FROM_WALLET",
          payload: error.response.data,
        });
      });
  };
}

export function updateWalletPackage(clientID, formData) {
  return (dispatch) => {
    http
      .post("edit-package/" + clientID, formData)
      .then((response) => {
        dispatch({ type: "UPDATE_WALLET_PACKAGE", payload: response.data });
        if (response.data.status !== 200) {
          uiNotification.error(languageData.global[response.data.message]);
        } else {
          uiNotification.success(languageData.global[response.data.message]);
        }
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_WALLET_PACKAGE",
          payload: error.response.data,
        });
      });
  };
}

export function removeWalletPackage(clientID, formData) {
  return (dispatch) => {
    http
      .post("edit-package/" + clientID, formData)
      .then((response) => {
        dispatch({ type: "REMOVE_WALLET_PACKAGE", payload: response.data });
        if (response.data.status !== 200) {
          uiNotification.error(languageData.global[response.data.message]);
        } else {
          uiNotification.success(languageData.global[response.data.message]);
        }
      })
      .catch((error) => {
        dispatch({
          type: "REMOVE_WALLET_PACKAGE",
          payload: error.response.data,
        });
      });
  };
}

export function removeInventoryVariation(clientID, formData) {
  return (dispatch) => {
    http
      .post("edit-inventory-variation/" + clientID, formData)
      .then((response) => {
        dispatch({
          type: "REMOVE_INVENTORY_VARIATION",
          payload: response.data,
        });
        if (response.data.status !== 200) {
          uiNotification.error(languageData.global[response.data.message]);
        } else {
          uiNotification.success(languageData.global[response.data.message]);
        }
      })
      .catch((error) => {
        dispatch({
          type: "REMOVE_INVENTORY_VARIATION",
          payload: error.response.data,
        });
      });
  };
}

export function updateMembershipCC(clientID, formData) {
  return (dispatch) => {
    http
      .post("update-membership-card-details/" + clientID, formData)
      .then((response) => {
        dispatch({ type: "UPDATE_MEMBERSHIP_CC", payload: response.data });
        uiNotification.success(languageData.global[response.data.message]);
        queryClient.invalidateQueries(QueryKeys.GET_PATIENT_MEMBERSHIPS);
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_MEMBERSHIP_CC",
          payload: error.response.data,
        });
      });
  };
}

export function cancelMembership(clientID, formData) {
  return (dispatch) => {
    http
      .post("cancel-membership-subscription/" + clientID, formData)
      .then((response) => {
        let memberships = response.data.data.memberships;
        let expired_memberships = response.data.data.expired_memberships;

        let data = {
          ...response.data,
          data: {
            ...response.data.data,
            memberships: memberships
              ? [...memberships, ...(expired_memberships || [])]
              : memberships,
          },
        };
        dispatch({ type: "CANCEL_MEMBERSHIP", payload: data });
        uiNotification.success(response.data.message);
        queryClient.invalidateQueries(QueryKeys.GET_PATIENT_MEMBERSHIPS);
      })
      .catch((error) => {
        dispatch({ type: "CANCEL_MEMBERSHIP", payload: error.response.data });
      });
  };
}

export function addMonthyMembership(clientID, formData) {
  return (dispatch) => {
    http
      .post("add-membership-subscription/" + clientID, formData)
      .then((response) => {
        dispatch({ type: "ADD_MONTHLY_MEMBERSHIP", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "ADD_MONTHLY_MEMBERSHIP",
          payload: error.response.data,
        });
      });
  };
}

export function searchProduct(formData) {
  return (dispatch) => {
    http
      .post("search-product", formData)
      .then((response) => {
        dispatch({ type: "SEARCH_WALLET_PRODUCT", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SEARCH_WALLET_PRODUCT",
          payload: error.response.data,
        });
      });
  };
}

export function addPackageProduct(formData) {
  return (dispatch) => {
    http
      .post("add-package-product", formData)
      .then((response) => {
        dispatch({ type: "ADD_PACKAGE_PRODUCT", payload: response.data });
        if (response.data.status !== 200) {
          uiNotification.error(languageData.global[response.data.message]);
        } else {
          uiNotification.success(languageData.global[response.data.message]);
        }
      })
      .catch((error) => {
        dispatch({ type: "ADD_PACKAGE_PRODUCT", payload: error.response.data });
      });
  };
}

export function getBogoPackageDetails(formData) {
  return (dispatch) => {
    http
      .post("get-bogo-package-details", formData)
      .then((response) => {
        dispatch({ type: "GET_BOGO_PACKAGE_DETAILS", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_BOGO_PACKAGE_DETAILS",
          payload: error.response.data,
        });
      });
  };
}

export function getProductPriceByClinic(formData) {
  return (dispatch) => {
    http
      .post("get-product-price", formData)
      .then((response) => {
        dispatch({
          type: "GET_PRODUCT_PRICE_BY_CLINIC",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_PRODUCT_PRICE_BY_CLINIC",
          payload: error.response.data,
        });
      });
  };
}

export function exportClients(formData) {
  const data = { params: {} };
  for (let prop in formData.params) {
    if (formData.params.hasOwnProperty(prop) && formData.params[prop]) {
      data.params[prop] = formData.params[prop];
    }
  }
  return (dispatch) => {
    http
      .get("clients_export", data)
      .then((response) => {
        dispatch({ type: "EXPORT_FILE", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "EXPORT_FILE", payload: error.response.data });
      });
  };
}

export function exportClientPDF(formData) {
  return (dispatch) => {
    http
      .post("clients/export_patient", formData)
      .then((response) => {
        dispatch({ type: "EXPORT_CLIENT_PDF", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "EXPORT_CLIENT_PDF", payload: error.response.data });
      });
  };
}

export function refundFees(formData) {
  return (dispatch) => {
    http
      .post("refundFees", formData)
      .then((response) => {
        dispatch({ type: "REFUND_FEES", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "REFUND_FEES", payload: error.response.data });
      });
  };
}

export function exportEmptyData() {
  return (dispatch) => {
    dispatch({
      type: "EMPTY_DATA",
      payload: { data: "", status: 200, message: "" },
    });
  };
}

export function exportClientProcedures(formData) {
  return (dispatch) => {
    http
      .post("clients/export_procedures", formData)
      .then((response) => {
        dispatch({ type: "EXPORT_CLIENT_PROCEDURES", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "EXPORT_CLIENT_PROCEDURES",
          payload: error.response.data,
        });
      });
  };
}

export function clientBulkUpload(formData) {
  return (dispatch) => {
    http
      .post("patient-bulk-upload", formData)
      .then((response) => {
        dispatch({ type: "CLIENT_BULK_UPLOAD", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "CLIENT_BULK_UPLOAD", payload: error.response.data });
      });
  };
}

export function viewFilledSurveys(appointmentID, clientID, procedureID) {
  return (dispatch) => {
    http
      .get(
        "get_survey_details/" +
          appointmentID +
          "/" +
          clientID +
          "/" +
          procedureID,
      )
      .then((response) => {
        dispatch({ type: "VIEW_FILLED_SURVEYS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "VIEW_FILLED_SURVEYS", payload: error.response.data });
      });
  };
}

export function sendPostInstructions(procedureID) {
  return async (dispatch) => {
    return http
      .get(`send-post-treatment-instructions/${procedureID}`)
      .then((response) => {
        dispatch({ type: "SEND_POST_INSTRUCTION", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "SEND_POST_INSTRUCTION",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
}

export function getClientDocuments(formData) {
  return (dispatch) => {
    http
      .get("client-documents", formData)
      .then((response) => {
        dispatch({ type: "CLIENT_DOCUMENT_LIST", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CLIENT_DOCUMENT_LIST",
          payload: error.response.data,
        });
      });
  };
}

export function getClientDocumentData(documentId, formData) {
  return async (dispatch) => {
    return http
      .get("client-documents/" + documentId, formData)
      .then((response) => {
        dispatch({ type: "CLIENT_DOCUMENT_DATA", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "CLIENT_DOCUMENT_DATA",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
}

export function saveClientDocument(formData) {
  return (dispatch) => {
    http
      .post("client-documents", formData)
      .then((response) => {
        dispatch({ type: "CLIENT_DOCUMENT_SAVE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CLIENT_DOCUMENT_SAVE",
          payload: error.response.data,
        });
      });
  };
}

export function updateClientDocument(documentId, formData) {
  return (dispatch) => {
    http
      .put("client-documents/" + documentId, formData)
      .then((response) => {
        dispatch({ type: "CLIENT_DOCUMENT_UPDATE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CLIENT_DOCUMENT_UPDATE",
          payload: error.response.data,
        });
      });
  };
}

export function deleteClientDocument(documentId) {
  return async (dispatch) => {
    return await http
      .delete("client-documents/" + documentId)
      .then((response) => {
        dispatch({ type: "CLIENT_DOCUMENT_DELETE", payload: response.data });
        uiNotification.success(languageData.global[response.data.message]);
      })
      .catch((error) => {
        dispatch({
          type: "CLIENT_DOCUMENT_DELETE",
          payload: error.response.data,
        });
      });
  };
}

export function getAfterPhotos(procedureID) {
  return (dispatch) => {
    http
      .get("view_after_images/" + procedureID)
      .then((response) => {
        dispatch({ type: "VIEW_AFTER_PHOTOS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "VIEW_AFTER_PHOTOS", payload: error.response.data });
      });
  };
}

export function getMembershipData(clientID) {
  return (dispatch) => {
    http
      .get("get-membership-type/" + clientID)
      .then((response) => {
        dispatch({ type: "GET_MEMBERSHIP_DETAILS", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_MEMBERSHIP_DETAILS",
          payload: error.response.data,
        });
      });
  };
}

export function applyCouponCode(clientID, formData) {
  return (dispatch) => {
    http
      .post("validate-coupon-code/" + clientID, formData)
      .then((response) => {
        dispatch({ type: "APPLY_DISCOUNT_COUPON", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "APPLY_DISCOUNT_COUPON",
          payload: error.response.data,
        });
      });
  };
}

export function pastDueInvoiceData(formData) {
  return (dispatch) => {
    http
      .post("pay-past-due-invoice", formData)
      .then((response) => {
        dispatch({ type: "PAST_DUE_INVOICE_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "PAST_DUE_INVOICE_DATA",
          payload: error.response.data,
        });
      });
  };
}

export function changeMembershipClinic(formData) {
  return (dispatch) => {
    http
      .post("membership-edit-clinic", formData)
      .then((response) => {
        dispatch({ type: "CHANGE_MEMBERSHIP_CLIENT", payload: response.data });
        queryClient.invalidateQueries(QueryKeys.GET_PATIENT_MEMBERSHIPS);
        uiNotification.success(languageData.global[response.data.message]);
      })
      .catch((error) => {
        dispatch({
          type: "CHANGE_MEMBERSHIP_CLIENT",
          payload: error.response.data,
        });
      });
  };
}

export function getClientProfiles() {
  return (dispatch) => {
    http
      .get("duplicate_patients")
      .then((response) => {
        dispatch({
          type: "GET_DUPLICATE_PROFILES",
          payload: { data: response.data, status: response.status },
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_DUPLICATE_PROFILES",
          payload: error.response.data,
        });
      });
  };
}

export function getMergeData(formData, step) {
  return (dispatch) => {
    http
      .post("get_merge_patients/" + step, formData)
      .then((response) => {
        dispatch({ type: "GET_MERGE_PROFILES_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_MERGE_PROFILES_DATA",
          payload: error.response.data,
        });
      });
  };
}

export function getProcedureData(formData, step) {
  return async (dispatch) => {
    await http
      .post("get_merge_patients/" + step, formData)
      .then((response) => {
        dispatch({ type: "GET_PROCEDURE_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_PROCEDURE_DATA", payload: error.response });
      });
  };
}

export function getWalletData(formData, step) {
  return async (dispatch) => {
    await http
      .post("get_merge_patients/" + step, formData)
      .then((response) => {
        dispatch({ type: "GET_WALLET_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_WALLET_DATA", payload: error.response });
      });
  };
}

export function getTreatmentPlans(formData, step) {
  return async (dispatch) => {
    await http
      .post("get_merge_patients/" + step, formData)
      .then((response) => {
        dispatch({ type: "GET_TREATMENT_PLANS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_TREATMENT_PLANS", payload: error.response });
      });
  };
}

export function getMedicalHistory(formData, step) {
  return async (dispatch) => {
    await http
      .post("get_merge_patients/" + step, formData)
      .then((response) => {
        dispatch({ type: "GET_MEDICAL_HISTORY", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_MEDICAL_HISTORY", payload: error.response });
      });
  };
}

export function getUpcomingAppointment(formData, step) {
  return async (dispatch) => {
    return http
      .post("get_merge_patients/" + step, formData)
      .then((response) => {
        dispatch({
          type: "GET_UPCOMING_APPOINTMENT_AND_PAYMENT_HISTORY",
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "GET_UPCOMING_APPOINTMENT_AND_PAYMENT_HISTORY",
          payload: error.response,
        });
        return Promise.reject(error.response);
      });
  };
}

export function getCustomerNotes(formData, step) {
  return async (dispatch) => {
    await http
      .post("get_merge_patients/" + step, formData)
      .then((response) => {
        dispatch({ type: "GET_CUSTOMER_NOTES", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_CUSTOMER_NOTES", payload: error.response });
      });
  };
}

export function getPatientDocuments(formData, step) {
  return async (dispatch) => {
    await http
      .post("get_merge_patients/" + step, formData)
      .then((response) => {
        dispatch({ type: "GET_PATIENT_DOCUMENTS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_PATIENT_DOCUMENTS", payload: error.response });
      });
  };
}
export function updateMembershipHoldStatus(formData) {
  return (dispatch) => {
    http
      .post("hold-resume-membership", formData)
      .then((response) => {
        let memberships = response.data.data.memberships;
        let expired_memberships = response.data.data.expired_memberships;

        let data = {
          ...response.data,
          data: {
            ...response.data.data,
            memberships: memberships
              ? [...memberships, ...(expired_memberships || [])]
              : memberships,
          },
        };
        dispatch({ type: "UPDATE_MEMBERSHIP_STATUS", payload: data });
        uiNotification.success(response.data.message);
        queryClient.invalidateQueries(QueryKeys.GET_PATIENT_MEMBERSHIPS);
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_MEMBERSHIP_STATUS",
          payload: error.response.data,
        });
      });
  };
}

export function getBAVideos(procedureID) {
  return (dispatch) => {
    http
      .get("get-procedure-ba-videos/" + procedureID)
      .then((response) => {
        dispatch({ type: "VIEW_BAVIDEOS", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "VIEW_BAVIDEOS", payload: error.response.data });
      });
  };
}

export function fetchAgreement(tierId) {
  return (dispatch) => {
    http
      .get("get-membership-agreement/" + tierId)
      .then((response) => {
        dispatch({ type: "GET_MEMBERSHIP_AGREEMENT", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_MEMBERSHIP_AGREEMENT",
          payload: error.response.data,
        });
      });
  };
}

export function mergeClient(formData) {
  return async (dispatch) => {
    return http
      .post("merge_patients", formData)
      .then((response) => {
        dispatch({ type: "MERGE_PATIENT_DATA", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "MERGE_PATIENT_DATA", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function getTreatmentTemplates() {
  return (dispatch) => {
    http
      .get("get-treatment-plan-templates")
      .then((response) => {
        dispatch({ type: "GET_TREAT_TEMPLATES", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_TREAT_TEMPLATES", payload: error.response.data });
      });
  };
}

export function getTemplateDetails(templateID) {
  return async (dispatch) => {
    return http
      .get("get-treatment-plan-template/" + templateID)
      .then((response) => {
        dispatch({ type: "GET_PLAN_TEMPLATE_DATA", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "GET_PLAN_TEMPLATE_DATA",
          payload: error.response.data,
        });
        return Promise.reject(error.response?.data);
      });
  };
}

export function savePAYGTreatmentPlan(formData) {
  return async (dispatch) => {
    return http
      .post("save-pay-as-u-go-treatment-plan", formData)
      .then((response) => {
        dispatch({ type: "SAVE_PAYG_TREATMENT_PLAN", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "SAVE_PAYG_TREATMENT_PLAN",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
}

export function getPlanDataByID(planID) {
  return async (dispatch) => {
    dispatch({ type: "GET_PLAN_DATA_BY_ID_LOADING" });
    return http
      .get("get-treatment-plan-by-id/" + planID)
      .then((response) => {
        dispatch({ type: "GET_PLAN_DATA_BY_ID", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "GET_PLAN_DATA_BY_ID", payload: error.response.data });
        return Promise.reject(error.response?.data);
      });
  };
}

export function saveMonthlyTreatmentPlan(formData) {
  return async (dispatch) => {
    return http
      .post("save-monthly-treatment-plan", formData)
      .then((response) => {
        dispatch({
          type: "SAVE_MONTHLY_TREATMENT_PLAN",
          payload: response.data,
        });
        uiNotification.success(languageData.global[response.data.message]);
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "SAVE_MONTHLY_TREATMENT_PLAN",
          payload: error.response.data,
        });

        const message = error.response.data.message;

        if (message === "treatment_name_required") {
          uiNotification.error("Treatment Plan Name is required");
        } else {
          uiNotification.error(
            languageData.global[error.response.data.message],
          );
        }

        return Promise.reject(error.response?.data);
      });
  };
}

export function getProgramData(clientID, planID) {
  return (dispatch) => {
    http
      .get("get-data-for-program/" + clientID + "/" + planID)
      .then((response) => {
        dispatch({ type: "GET_START_PROGRAM_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_START_PROGRAM_DATA",
          payload: error.response.data,
        });
      });
  };
}

export function applyStartProgramDiscount(formData) {
  return async (dispatch) => {
    return http
      .post("apply-discount-amount", formData)
      .then((response) => {
        dispatch({
          type: "START_PROGRAM_APPLY_DISCOUNT",
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "START_PROGRAM_APPLY_DISCOUNT",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
}

export function changeTreatmentPlanClinic(formData) {
  return async (dispatch) => {
    http
      .post("change-treatment-plan-clinic", formData)
      .then((response) => {
        dispatch({ type: "CHANGE_PLAN_CLINIC", payload: response.data });
        uiNotification.success(languageData.global[response.data.message]);
      })
      .catch((error) => {
        dispatch({ type: "CHANGE_PLAN_CLINIC", payload: error.response.data });
      });
  };
}

export function viewPriceBreakUp(planID) {
  return (dispatch) => {
    http
      .get("view-break-down/" + planID)
      .then((response) => {
        dispatch({ type: "VIEW_PRICE_BREAK_UP", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "VIEW_PRICE_BREAK_UP", payload: error.response.data });
      });
  };
}

export function startTreatmentProgram(formData) {
  return async (dispatch) => {
    return http
      .post("start-treatment-plan", formData)
      .then((response) => {
        dispatch({ type: "START_TREATMENT_PROGRAM", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "START_TREATMENT_PROGRAM",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
}

export function cancelTreatmentPlan(formData) {
  return async (dispatch) => {
    return http
      .post("cancel-treatment-plan", formData)
      .then((response) => {
        dispatch({ type: "CANCEL_TREATMENT_PLAN", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "CANCEL_TREATMENT_PLAN",
          payload: error.response.data,
        });
        return Promise.reject(error.response.data);
      });
  };
}

export function savePlanAsTemplate(formData) {
  return (dispatch) => {
    http
      .post("save-treatment-plan-template", formData)
      .then((response) => {
        dispatch({ type: "SAVE_PLAN_AS_TEMPLATE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SAVE_PLAN_AS_TEMPLATE",
          payload: error.response.data,
        });
      });
  };
}

export function updatePlanCard(formData) {
  return (dispatch) => {
    http
      .post("update-treatment-plan-card", formData)
      .then((response) => {
        dispatch({ type: "UPDATE_PLAN_CARD", payload: response.data });
        uiNotification.success(languageData.global[response.data.message]);
      })
      .catch((error) => {
        dispatch({ type: "UPDATE_PLAN_CARD", payload: error.response.data });
        uiNotification.error(error.response.data.message);
      });
  };
}

export function getPrescribeOnlyDetails(planID) {
  return (dispatch) => {
    http
      .get("get-prescribed-plan?plan_id=" + planID)
      .then((response) => {
        dispatch({
          type: "GET_PRESCRIBE_ONLY_DETAILS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_PRESCRIBE_ONLY_DETAILS",
          payload: error.response.data,
        });
      });
  };
}

export function updatePrescribeOnly(formData) {
  return (dispatch) => {
    http
      .post("update-prescribe-only", formData)
      .then((response) => {
        dispatch({ type: "UPDATE_PRESCRIBE_ONLY", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_PRESCRIBE_ONLY",
          payload: error.response.data,
        });
      });
  };
}

export function markUsersAsNotDuplicate(formData) {
  return (dispatch) => {
    http
      .post("mark_no_duplicate", formData)
      .then((response) => {
        dispatch({
          type: "MARK_NOT_DUPLICATE",
          payload: { data: response.data, status: response.status },
        });
      })
      .catch((error) => {
        dispatch({ type: "MARK_NOT_DUPLICATE", payload: error.response.data });
      });
  };
}

export function getNewMembershipData(clientID) {
  return (dispatch) => {
    http
      .get("get-membership-type/" + clientID)
      .then((response) => {
        let memberships = response.data.data.memberships;
        let expired_memberships = response.data.data.expired_memberships;

        let data = {
          ...response.data,
          data: {
            ...response.data.data,
            memberships: memberships
              ? [...memberships, ...(expired_memberships || [])]
              : memberships,
          },
        };
        dispatch({ type: "GET_NEW_MEMBERSHIP_DETAILS", payload: data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_NEW_MEMBERSHIP_DETAILS",
          payload: error.response.data,
        });
      });
  };
}

export function newMembershipType(clientID) {
  return (dispatch) => {
    http
      .get("new-membership-type/" + clientID)
      .then((response) => {
        dispatch({ type: "ADD_NEW_MEMBERSHIP_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "ADD_NEW_MEMBERSHIP_DATA",
          payload: error.response.data,
        });
      });
  };
}

export function doThisAction(planID, actionType) {
  return (dispatch) => {
    http
      .get("treatment-plan-action/" + planID + "/" + actionType)
      .then((response) => {
        dispatch({ type: "PERFORM_TREATMENT_ACTIONS", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "PERFORM_TREATMENT_ACTIONS",
          payload: error.response.data,
        });
      });
  };
}

export function changeMembershipDrawDay(formData) {
  return (dispatch) => {
    http
      .post("membership-edit-draw-day", formData)
      .then((response) => {
        dispatch({ type: "UPDATE_DRAW_DAY", payload: response.data });
        queryClient.invalidateQueries(QueryKeys.GET_PATIENT_MEMBERSHIPS);
        uiNotification.success(languageData.global[response.data.message]);
      })
      .catch((error) => {
        dispatch({ type: "UPDATE_DRAW_DAY", payload: error.response.data });
      });
  };
}

export function saveNewPrescription(formData) {
  return (dispatch) => {
    http
      .post("save_procedure_prescription", formData)
      .then((response) => {
        dispatch({
          type: "IS_RELOAD_CLIENT_PROCEDURES",
          isReloadClientProcedure: true,
        });
        dispatch({ type: "SAVE_HEALTH_PRO_PRES", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SAVE_HEALTH_PRO_PRES",
          payload: error.response.data,
        });
      });
  };
}

export function savePharmacy(formData) {
  return (dispatch) => {
    http
      .post("save_pharmacy", formData)
      .then((response) => {
        dispatch({ type: "SAVE_PHARMACY", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "SAVE_PHARMACY", payload: error.response.data });
      });
  };
}

export function generatePrescriptionPDF(proID) {
  return (dispatch) => {
    http
      .get("export_prescription/" + proID)
      .then((response) => {
        dispatch({ type: "GENERATE_PRESC_PDF", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GENERATE_PRESC_PDF", payload: error.response.data });
      });
  };
}

export function faxPrescriptionPDF(proID) {
  return (dispatch) => {
    http
      .get("fax_prescription/" + proID)
      .then((response) => {
        dispatch({ type: "FAX_PRESC_PDF", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "FAX_PRESC_PDF", payload: error.response.data });
      });
  };
}

export function emailPrescriptionPDF(proID) {
  return (dispatch) => {
    http
      .get("email_prescription/" + proID)
      .then((response) => {
        dispatch({ type: "EMAIL_PRESC_PDF", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "EMAIL_PRESC_PDF", payload: error.response.data });
      });
  };
}

export function downloadMembershipAgreement(membershipId, formData) {
  return (dispatch) => {
    http
      .get("download-membership-signature/" + membershipId, formData)
      .then((response) => {
        dispatch({
          type: "DOWNLOAD_MEMBERSHIP_AGREEMENT",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "DOWNLOAD_MEMBERSHIP_AGREEMENT",
          payload: error.response.data,
        });
      });
  };
}
export function getInvoiceData(procedureID) {
  return async (dispatch) => {
    return http
      .get("procedure-invoice/" + procedureID)
      .then((response) => {
        dispatch({ type: "GET_PROCEDURE_INVOICE", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "GET_PROCEDURE_INVOICE",
          payload: error.response.data,
        });
      });
  };
}

export function fetchSelectedQuestionnaireData(procedureID, questionnaireID) {
  return (dispatch) => {
    http
      .get("get-questionnarie-details/" + procedureID + "/" + questionnaireID)
      .then((response) => {
        dispatch({
          type: "GET_SELECTED_QUESTIONNAIRE_DETAILS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_SELECTED_QUESTIONNAIRE_DETAILS",
          payload: error.response.data,
        });
      });
  };
}

export function fetchSelectedConsentData(patientId, consentID) {
  return (dispatch) => {
    http
      .get("get-consent-details/" + patientId + "/" + consentID)
      .then((response) => {
        dispatch({
          type: "GET_SELECTED_CONSENT_DETAILS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_SELECTED_CONSENT_DETAILS",
          payload: error.response.data,
        });
      });
  };
}

export function getAgreementDetails(membershipID, endpoint, objectId = "") {
  return async (dispatch) => {
    return http
      .get(endpoint + "/" + membershipID + "/" + objectId)
      .then((response) => {
        dispatch({ type: "GET_AGREEMENT_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_AGREEMENT_DATA", payload: error.response.data });
      });
  };
}

export function exportProcedureData(clientID, procedureID) {
  return (dispatch) => {
    dispatch({
      type: "EXPORT_PROCEDURE_DATA",
      payload: { status: "loading", data: null },
    });
    http
      .get("export_procedure/" + clientID + "/" + procedureID)
      .then((response) => {
        dispatch({
          type: "EXPORT_PROCEDURE_DATA",
          payload: { status: "success", data: response.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: "EXPORT_PROCEDURE_DATA",
          payload: { status: "error", data: error.response.data },
        });
      });
  };
}

export function showFile(clientID, fileID) {
  return (dispatch) => {
    dispatch({
      type: "GET_CLIENT_DOCUMENT",
      payload: { status: "loading", data: null },
    });
    http
      .get("client-documents/" + fileID + "?patient_id=" + clientID)
      .then((response) => {
        dispatch({
          type: "GET_CLIENT_DOCUMENT",
          payload: { status: "success", data: response.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_CLIENT_DOCUMENT",
          payload: { status: "error", data: error.response.data },
        });
      });
  };
}

export function fetchAppointmentQuestionnaires(formData) {
  return (dispatch) => {
    http
      .post("get_appointment_questionnaire", formData)
      .then((response) => {
        dispatch({
          type: "GET_APPOINTMENT_QUESTIONNAIRE",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_APPOINTMENT_QUESTIONNAIRE",
          payload: error.response.data,
        });
      });
  };
}

export function fetchDocumentHealthQuestionnaire(formData) {
  return (dispatch) => {
    http
      .post("get_health_questionnaire", formData)
      .then((response) => {
        dispatch({
          type: "GET_APPOINTMENT_HEALTH_QUESTIONNAIRE",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_APPOINTMENT_HEALTH_QUESTIONNAIRE",
          payload: error.response.data,
        });
      });
  };
}

export function fetchAppointmentConsents(formData) {
  return async (dispatch) => {
    http
      .post("get_patient_consent", formData)
      .then((response) => {
        dispatch({ type: "GET_APPOINTMENT_CONSENTS", payload: response.data });
        return response?.data;
      })
      .catch((error) => {
        dispatch({
          type: "GET_APPOINTMENT_CONSENTS",
          payload: error.response.data,
        });
        return Promise.reject(error?.response?.data);
      });
  };
}

export function getDocumentProcedureHealthQuestionnaire(formData) {
  return (dispatch) => {
    http
      .post("get_procedure_health_questionnaire", formData)
      .then((response) => {
        dispatch({
          type: "GET_PROCEDURE_HEALTH_QUESTIONNAIRE",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_PROCEDURE_HEALTH_QUESTIONNAIRE",
          payload: error.response.data,
        });
      });
  };
}
export function updateInvoice(formData, procedureID) {
  return (dispatch) => {
    http
      .post("save-procedure-invoice/" + procedureID, formData)
      .then((response) => {
        dispatch({
          type: "IS_RELOAD_CLIENT_PROCEDURES",
          isReloadClientProcedure: true,
        });
        dispatch({
          type: "SAVE_HEALTH_PROCEDURE_INVOICE",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SAVE_HEALTH_PROCEDURE_INVOICE",
          payload: error.response.data,
        });
      });
  };
}

export function takePayment(formData, procedureID) {
  return (dispatch) => {
    http
      .post("save-procedure-invoice/" + procedureID, formData)
      .then((response) => {
        dispatch({
          type: "IS_RELOAD_CLIENT_PROCEDURES",
          isReloadClientProcedure: true,
        });
        dispatch({ type: "TAKE_PAYMENT_INVOICE", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "TAKE_PAYMENT_INVOICE",
          payload: error.response.data,
        });
      });
  };
}

export function saveWhileWorking(formData, procedureID) {
  return async (dispatch) => {
    return http
      .post("save-procedure-invoice/" + procedureID, formData)
      .then((response) => {
        dispatch({
          type: "IS_RELOAD_CLIENT_PROCEDURES",
          isReloadClientProcedure: true,
        });
        dispatch({ type: "SAVE_WHILE_WORKING", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "SAVE_WHILE_WORKING", payload: error.response.data });
      });
  };
}

export function searchProductForInvoice(formData, data) {
  let type = "";
  if (data == "product") {
    type = "SEARCH_PRODUCT_FOR_INVOICE";
  } else {
    type = "SEARCH_SERVICE_FOR_INVOICE";
  }
  return (dispatch) => {
    http
      .post("search-product-service", formData)
      .then((response) => {
        dispatch({ type: type, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: type, payload: error.response.data });
      });
  };
}
export function fetchHealthProcedureConsents(formData) {
  return async (dispatch) => {
    http
      .post("get_patient_health_consent", formData)
      .then((response) => {
        dispatch({ type: "GET_APPOINTMENT_CONSENTS", payload: response.data });
        return response?.data;
      })
      .catch((error) => {
        dispatch({
          type: "GET_APPOINTMENT_CONSENTS",
          payload: error.response.data,
        });
        return Promise.reject(error?.response?.data);
      });
  };
}

export function getClientProfileDetail(clientID, scopes) {
  return (dispatch) => {
    http
      .get("clients/" + clientID + "?scopes=" + scopes)
      .then((response) => {
        dispatch({
          type: "CLIENT_PROFILE_DATA",
          payload: response.data,
          clientId: clientID,
          isQualiphyEnabled: response?.data?.global_settings?.qualiphy || false,
        });
      })
      .catch((error) => {
        dispatch({
          type: "CLIENT_PROFILE_DATA",
          payload: error.response.data,
          clientId: clientID,
        });
      });
  };
}
export function getAllClientProcedures(formData) {
  return (dispatch) => {
    http
      .post("clients/export_all_procedures", formData)
      .then((response) => {
        dispatch({ type: "GET_ALL_CLIENT_PROCEDURES", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_ALL_CLIENT_PROCEDURES",
          payload: error.response.data,
        });
      });
  };
}

export function updateProcedureLockStatus({ id, is_lock }) {
  return (dispatch) => {
    dispatch({ type: "UPDATE_PROCEDURE_LOCK_STATUS", id, is_lock });
  };
}

export function writeClientProcedureData(clientProcedureData) {
  return (dispatch) => {
    dispatch({
      type: "WRITE_CLIENT_PROCEDURE_DATA",
      clientProcedureData: clientProcedureData,
    });
  };
}

export function readClientProcedureData() {
  return (dispatch) => {
    dispatch({ type: "READ_CLIENT_PROCEDURE_DATA" });
  };
}

export function readClientProfileData() {
  return (dispatch) => {
    dispatch({ type: "READ_CLIENT_PROFILE_DATA" });
  };
}

export function readClientProfileAndProcedureData() {
  return (dispatch) => {
    dispatch({ type: "READ_CLIENT_PROFILE_AND_PROOCURE_DATA" });
  };
}

export function getProcedurePrescription(formData) {
  return (dispatch) => {
    http
      .post("get-prescription", formData)
      .then((response) => {
        dispatch({
          type: "GET_PROCEDURE_PRESCRIPTION_DATA",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_PROCEDURE_PRESCRIPTION_DATA",
          payload: error.response.data,
        });
      });
  };
}

export function verifyPortalEmail(formData) {
  return (dispatch) => {
    http
      .post("verify-patient-user", formData)
      .then((response) => {
        dispatch({ type: "VERIFY_PORTAL_EMAIL", payload: response.data });
        if (response.data.status === 200) {
          uiNotification.success(languageData.global[response.data.message]);
        }
      })
      .catch((error) => {
        dispatch({ type: "VERIFY_PORTAL_EMAIL", payload: error.response.data });
      });
  };
}

export function payRefund(formData) {
  return (dispatch) => {
    http
      .post("sales/invoices/pay-refund", formData)
      .then((response) => {
        dispatch({ type: "ISSUE_REFUND_FROM_HISTORY", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "ISSUE_REFUND_FROM_HISTORY",
          payload: error.response.data,
        });
      });
  };
}

export function getClearentKey(clinic_id) {
  return (dispatch) => {
    http
      .get("get-clearent-config?clinic_id=" + clinic_id)
      .then((response) => {
        dispatch({ type: "GET_CLEARENT_KEY", payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: "GET_CLEARENT_KEY", payload: error.response.data });
      });
  };
}

export function checkScriptSurePatient(clientID) {
  return (dispatch) => {
    http
      .get("check_script_sure_patient/" + clientID)
      .then((response) => {
        dispatch({ type: "GET_SCRIPT_SURE_PATIENT", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_SCRIPT_SURE_PATIENT",
          payload: error.response.data,
        });
      });
  };
}

export function fetchAPIsData() {
  return (dispatch) => {
    dispatch({
      type: "CALL_BASIC_CLIENTS",
      payload: { data: {}, status: 200, message: "" },
    });
  };
}

export function createScriptSurePatient(formData, clientID) {
  return (dispatch) => {
    http
      .post("create_script_sure_patient/" + clientID, formData)
      .then((response) => {
        dispatch({ type: "SAVE_SCRIPT_SURE_PATIENT", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "SAVE_SCRIPT_SURE_PATIENT",
          payload: error.response.data,
        });
      });
  };
}
export function fetchAppointmentConsentData(appointmentID) {
  return (dispatch) => {
    http
      .get("get-appointment-consents?appointment_id=" + appointmentID)
      .then((response) => {
        dispatch({ type: "GET_APPOINTMENT_CONSENTS", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_APPOINTMENT_CONSENTS",
          payload: error.response.data,
        });
      });
  };
}
export function getAppointmentQuestionnaires(appointmentID) {
  return (dispatch) => {
    http
      .get("get-appointment-questionnaires?appointment_id=" + appointmentID)
      .then((response) => {
        dispatch({
          type: "GET_APPOINTMENT_QUESTIONNAIRES",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_APPOINTMENT_QUESTIONNAIRES",
          payload: error.response.data,
        });
      });
  };
}

export function sendPreInstructionsInAppointment(formData) {
  return (dispatch) => {
    http
      .post("send_pre_treatment_instructions_touchmd", formData)
      .then((response) => {
        dispatch({
          type: "SEND_PRE_INSTRUCTION_FOR_APPOINTMENT",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SEND_PRE_INSTRUCTION_FOR_APPOINTMENT",
          payload: error.response.data,
        });
      });
  };
}

export function onClosePrescriptionIframe(formData) {
  return (dispatch) => {
    http
      .get("download-e-prescription", formData)
      .then((response) => {
        dispatch({ type: "CLOSE_PRESCRIPTION_IFRAME", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "CLOSE_PRESCRIPTION_IFRAME",
          payload: error.response.data,
        });
      });
  };
}
export function downloadPrescriptionPdf(id, formData) {
  return (dispatch) => {
    dispatch({
      type: "DOWNLOAD_PRESCRIPTION_PDF",
      payload: { status: "loading", data: null },
    });
    http
      .get(`get-prescription-pdf/${id}`, formData)
      .then((response) => {
        dispatch({
          type: "DOWNLOAD_PRESCRIPTION_PDF",
          payload: { status: "success", data: response.data },
        });
      })
      .catch((error) => {
        dispatch({
          type: "DOWNLOAD_PRESCRIPTION_PDF",
          payload: { status: "error", data: error.response.data },
        });
      });
  };
}

export function getMedicineNames(formData) {
  return async (dispatch) => {
    return http
      .get("medicine_names/suggestion", {
        params: { ...formData },
      })
      .then((response) => {
        dispatch({ type: "GET_MEDICINE_NAMES", payload: response.data });
        return response.data;
      })
      .catch((error) => {
        dispatch({ type: "GET_MEDICINE_NAMES", payload: error.response.data });
        return Promise.reject(error.response.data);
      });
  };
}

export function exportMembershipDocument(
  documentId,
  patientId,
  isExpired = false,
) {
  return (dispatch) => {
    dispatch({
      type: "EXPORT_MEMBERSHIP_DOCUMENT",
      payload: { status: "loading", downloadLink: null, error: null },
    });
    http
      .get(`export_agreement/${patientId}/${documentId}/${Number(isExpired)}`)
      .then((response) => {
        if (response.data.status === 200) {
          dispatch({
            type: "EXPORT_MEMBERSHIP_DOCUMENT",
            payload: { status: "success", downloadLink: response.data.data },
          });
        } else {
          dispatch({
            type: "EXPORT_MEMBERSHIP_DOCUMENT",
            payload: { status: "error", error: response.data.message },
          });
        }
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "EXPORT_MEMBERSHIP_DOCUMENT",
          payload: { status: "error", error: error.response.data.message },
        });
      });
  };
}

export const getDefaultTypeExpirationDate = async (params) => {
  return http.get("get-default-type-expiration-date", {
    params: {
      document_type: params.documentType,
      created_date: params.createdDate,
    },
  });
};

export function exportConsentDocument(
  appointment_id,
  consent_id,
  patient_id,
  subtype,
) {
  return (dispatch) => {
    dispatch({
      type: "EXPORT_CONSENT_DOCUMENT",
      payload: { status: "loading", downloadLink: null, error: null },
    });
    http
      .get(
        `export_consent/${appointment_id}/${consent_id}/${patient_id}/${subtype}`,
      )
      .then((response) => {
        if (response.data.status === 200) {
          dispatch({
            type: "EXPORT_CONSENT_DOCUMENT",
            payload: { status: "success", downloadLink: response.data.data },
          });
        } else {
          dispatch({
            type: "EXPORT_CONSENT_DOCUMENT",
            payload: { status: "error", error: response.data.message },
          });
        }
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "EXPORT_CONSENT_DOCUMENT",
          payload: { status: "error", error: error.response.data.message },
        });
      });
  };
}

export const downloadEfaxPdf = async (faxId, formData) => {
  return await http.get(`efax/${faxId}`, formData);
};
