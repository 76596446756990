import React from "react";
import { Input } from "../../../../../../../../shared/Input/Input";
import CopyIcon from "../../../../../../../../assets/Icons/CopyIcon";
import styles from "./inputWithCopyButton.module.scss";

export function InputWithCopyButton({
  value,
  onChange,
  disabled = false,
  isReadOnly = false,
}) {
  const copyInfoToBuffer = async () => {
    return await navigator.clipboard.writeText(value);
  };

  return (
    <div className={styles.root}>
      <Input
        disabled={disabled}
        isReadonly={isReadOnly}
        className={styles.input}
        value={value}
        onChange={(e) => onChange(e)}
      />
      <button
        onClick={() => copyInfoToBuffer()}
        className={styles.rightAdornment}
      >
        <div className={styles.icon}>
          <CopyIcon height="18px" />
        </div>
      </button>
    </div>
  );
}
