import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { unwrapOr } from "../../utilities/general";

const responseSchema = yup
  .array()
  .of(
    yup.object({
      id: yup.number().required(),
      name: yup.string().required(),
      active: yup.boolean().required(),
    }),
  )
  .required();

const composeResponse = (res) => {
  const data = unwrapOr(() => res.data, []);
  return data.map((t) => ({
    id: t.id,
    name: t.tier_name,
    active: t.active === 0,
  }));
};

function createMembershipTiersQueryKey() {
  return [QUERY_KEYS.membershipTiers];
}

export function useMembershipTiersQuery(options = {}) {
  return useQuery(
    createMembershipTiersQueryKey(),
    async () => {
      const res = await http.get(HTTP_ENDPOINTS.getMembershipTiers());
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
