import { useMemo } from "react";
import moment from "moment";
import { useInvoiceUnattachedProceduresQuery } from "../../../../../api/procedure/useInvoiceUnattachedProceduresQuery";
import { uiNotification } from "../../../../../services/UINotificationService";
import { extractApiError } from "../../../../../utilities/api";
import { useCurrentUserQuery } from "../../../../../api/queries/useUserQuery";

var MESSAGES = {
  invoice_not_found: "Invoice not found",
  _fallback: "An error occurred while fetching the procedures",
};

export function useProcedures(invoiceId) {
  var { data: user } = useCurrentUserQuery();

  var { data, isFetching, isError } = useInvoiceUnattachedProceduresQuery({
    payload: {
      invoiceId,
    },
    options: {
      enabled: !!invoiceId,
      staleTime: 0,
      onError: (error) => {
        var message = extractApiError(error);
        uiNotification.error(MESSAGES[message] || MESSAGES._fallback);
      },
    },
  });

  var options = useMemo(() => {
    return (
      data?.map((p) => ({
        label: `${p.procedure_name} - ${moment
          .utc(p.procedure_date)
          .format(user.previewDateFormat)}`,
        value: p.id,
      })) || []
    );
  });

  return {
    options,
    isLoading: isFetching,
    isError,
  };
}
