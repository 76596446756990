import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "../../api/queryClient";
import { http } from "../../services/HttpService";
import formatUrlParams from "../../Utils/formatUrlParams";
import { QueryKeys } from "./QueryKeys";

const GetPriceList = (payload) => {
  const params = formatUrlParams({
    clinic_id: payload.clinic,
    sortby: payload.sort_by,
    sortdir: payload.sort_dir,
    perpage: payload.per_page,
    page: payload.page,
    term: payload.search,
    type: payload.type,
    membership_id: payload.membership || 0,
  });
  return http.get(`/price/list`, { params });
};

export const useGetPriceListQuery = (payload) => {
  const { params } = payload;

  return useQuery(
    [
      QueryKeys.GET_PRICELIST,
      params.clinic,
      params.sort_by,
      params.sort_dir,
      params.per_page,
      params.page,
      params.search,
      params.type,
      params.membership,
    ].filter(Boolean),
    () => GetPriceList(params),
    {
      enabled: Boolean(params.type && params.per_page && params.clinic),
      staleTime: 0,
      cacheTime: 0,
    },
  );
};

const BulkUpdatePriceList = (data) => {
  return http.post(`/price/bulk_update`, data);
};

export const useBulkUpdatePriceListQuery = () => {
  return useMutation(BulkUpdatePriceList, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.GET_PRICELIST]);
    },
  });
};
