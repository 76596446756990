import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Tabs } from "../../../../../../shared/Tabs/Tabs";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { CARD_FORM_TYPES } from "../ViewChargeCard.consts";
import FormManual from "./Forms/FormManual";
import FormSwipeCard from "./Forms/FormSwipeCard";
import { ModalCharge } from "../../../shared/ModalCharge/ModalCharge";
import FormCardOnFile from "./Forms/FormCardOnFile";
import { useInvoice } from "../../../hooks/invoice/useInvoice";
import { getClientCardDataStripe } from "../../../../../../Actions/Stripe/StripeActions";
import Loader from "../../../../../../Components/Common/Loader";

const Stripe = (props) => {
  const { cardList, getClientCardDataStripe } = props;
  const { tSales } = useAppTranslation.Sales();
  const [formType, setFormType] = useState(CARD_FORM_TYPES.manual);
  const { invoice, isGuest } = useInvoice();
  const [showLoader, setShowLoader] = useState(false);

  const getStripeCardList = async () => {
    setShowLoader(true);
    await getClientCardDataStripe({ patient_id: invoice.patient.id });
    setShowLoader(false);
  };

  useEffect(() => {
    if (invoice?.patient?.id && !isGuest) {
      getStripeCardList();
    }
  }, []);

  const cardsList = useMemo(
    () => cardList?.filter((card) => card.type === "card"),
    [cardList],
  );
  const linkList = useMemo(
    () => cardList?.filter((card) => card.type === "link"),
    [cardList],
  );

  const formByType = {
    [CARD_FORM_TYPES.manual]: <FormManual />,
    [CARD_FORM_TYPES.onFile]: <FormCardOnFile type="card" />,
    [CARD_FORM_TYPES.swipe]: <FormSwipeCard />,
    [CARD_FORM_TYPES.link]: <FormCardOnFile type="link" />,
  };

  return (
    <ModalCharge.Content>
      <Tabs
        variant="tiled"
        tabClassName="min-w-100"
        active={formType}
        options={[
          {
            label: tSales("checkoutInvoice.cardFormLabel.manual"),
            value: CARD_FORM_TYPES.manual,
            onClick: () => setFormType(CARD_FORM_TYPES.manual),
          },
          ...(cardsList?.length > 0
            ? [
                {
                  label: tSales("checkoutInvoice.cardFormLabel.savedCards"),
                  value: CARD_FORM_TYPES.onFile,
                  onClick: () => setFormType(CARD_FORM_TYPES.onFile),
                },
              ]
            : []),
          {
            label: tSales("checkoutInvoice.cardFormLabel.swipe"),
            value: CARD_FORM_TYPES.swipe,
            onClick: () => setFormType(CARD_FORM_TYPES.swipe),
            isDisabled: invoice.subtotalAmount === 0,
          },
          ...(linkList?.length > 0
            ? [
                {
                  label: tSales("checkoutInvoice.cardFormLabel.link"),
                  value: CARD_FORM_TYPES.linked,
                  onClick: () => setFormType(CARD_FORM_TYPES.link),
                },
              ]
            : []),
        ]}
      />
      {formByType[formType]}
      {showLoader && <Loader showLoader={true} />}
    </ModalCharge.Content>
  );
};

Stripe.propTypes = {
  cardList: PropTypes.array,
  getClientCardDataStripe: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    cardList: state.StripeReducer.cardList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getClientCardDataStripe }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Stripe);
