/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";

class AppointmentsProviderDetailReport extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      appointmentLang: languageData.appointments,
      globalLang: languageData.global,
      salesLang: languageData.sales,
      languageData: languageData.business_insights,
      showLoadingText: false,
      action_type: props.action_type ? props.action_type : "active",
    };

    window.onscroll = () => {
      return false;
    };
  }

  static getDerivedStateFromProps() {
    let returnState = {};
    return returnState;
  }

  dismissCloseDetail = () => {
    this.props.dismissCloseDetail({
      showGeneralReport: true,
      business_insight_child_id: 0,
      startFresh: true,
      next_page_url: "",
      detailReportListData: [],
      childPage: 1,
    });
  };

  render() {
    return (
      <div className="juvly-section setting-setion m-b-10 m-t-10">
        <div className="juvly-container memberShip-revenue p-b-20">
          <div className="juvly-title no-margin">
            {this.props.childTotal} {this.props.grammar}{" "}
            <a
              onClick={() => this.dismissCloseDetail()}
              className="pull-right crossIcon m-b-10"
            >
              <img src="/images/close.png" alt="" />
            </a>
          </div>

          <div className="row">
            <div className="col-12">
              <b>Hours Scheduled:</b>{" "}
              {(
                this.props.parentState?.detailReportListData
                  ?.minutes_scheduled / 60
              ).toFixed(2)}
            </div>
            <div className="col-12">
              <b>Hours Booked:</b>{" "}
              {(
                this.props.parentState?.detailReportListData?.minutes_booked /
                60
              ).toFixed(2)}
            </div>
          </div>

          <div className="mainRightActions">
            <a
              className="easy-link relative"
              onClick={() => this.props.downloadData()}
            >
              <i className="fa fa-download"></i> Download Excel
            </a>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12 col-md-6 col-lg-4">
            <div className="cash-drawer-stats">
              <div className="cash-drawer-stats-title">Free</div>
              <div className="cash-drawer-content">
                {this.props.parentState &&
                  this.props.parentState.detailReportListData &&
                  this.props.parentState.detailReportListData
                    .bi_free_percentage}
                %
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6 col-lg-4">
            <div className="cash-drawer-stats">
              <div className="cash-drawer-stats-title">Occupied</div>
              <div className="cash-drawer-content">
                {this.props.parentState &&
                  this.props.parentState.detailReportListData &&
                  this.props.parentState.detailReportListData
                    .bi_occupied_percentage}
                %
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-md-6 col-lg-4">
            <div className="cash-drawer-stats">
              <div className="cash-drawer-stats-title">Total Appointments</div>
              <div className="cash-drawer-content">
                {this.props.parentState &&
                  this.props.parentState.detailReportListData &&
                  this.props.parentState.detailReportListData
                    .bi_appointment_count}
              </div>
            </div>
          </div>
        </div>

        <div className="table-responsive clients-table no-border">
          <table className="table-updated  table-min-width no-td-border no-hover">
            <thead className="table-updated-thead">
              <tr>
                {this.props.tableColumns &&
                  this.props.tableColumns.length > 0 &&
                  this.props.tableColumns.map((tobj, tidx) => {
                    return (
                      <th key={tidx} className="table-updated-th">
                        {this.state.languageData[tobj]}
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody className="ajax_body">
              {this.props.detailReportListData &&
                this.props.detailReportListData.length > 0 &&
                this.props.detailReportListData.map((robj, ridx) => {
                  return (
                    <tr key={ridx} className="table-updated-tr">
                      <>
                        {this.props.tableColumns &&
                          this.props.tableColumns.length > 0 &&
                          this.props.tableColumns.map((tobj, tidx) => {
                            return (
                              <td
                                key={ridx + `_` + tidx}
                                className={"table-updated-td break-all"}
                              >
                                {robj[tobj]}
                              </td>
                            );
                          })}
                      </>
                    </tr>
                  );
                })}
              {this.props.detailReportListData &&
                this.props.detailReportListData.length <= 0 &&
                this.props.parentState.showLoader === false && (
                  <tr className="table-updated-tr">
                    <td
                      className="table-updated-td no-record no-float"
                      colSpan={10}
                    >
                      {
                        this.props.parentState.businessInsightLang
                          .bi_no_record_found
                      }
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>

        <div
          className={
            this.props.parentState.showLoadingText
              ? "loading-please-wait no-margin-top"
              : "loading-please-wait no-margin-top no-display"
          }
        >
          {this.props.parentState.globalLang.loading_please_wait_text}
        </div>
      </div>
    );
  }
}

export default AppointmentsProviderDetailReport;
