import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  PROVIDER_ROOM_PROCEDURE_TYPE,
  PROVIDER_ROOM_PROCEDURE_VERSION,
} from "../../../api/queries/useProviderRoomProceduresQuery";
import { useProcedures } from "../hooks/useProcedures";
import { TabCosmetic } from "./components/TabCosmetic";
import { TabHealth } from "./components/TabHealth";
import { Layout } from "../components/Layout/Layout";
import { providerRoom } from "../../../store/providerRoom";
import { useCurrentUserQuery } from "../../../api/queries/useUserQuery";
import { unwrapOr } from "../../../utilities/general";
import { useApiClinics } from "../../Event/hooks/useApiClinics";

function TabChartAudit({ isSigning, isSigned, history, activeTab }) {
  const { data: user } = useCurrentUserQuery();
  const isNeedMd = unwrapOr(() => Boolean(user.isMdConsentRequired), false);
  const historyData = history?.location?.state;
  const chartAuditUserId = historyData?.chartUserId?.toString();
  const {
    procedures,
    fetchNextProcedures,
    proceduresStatus,
    proceduresSearchTerm,
    proceduresType,
    selectClinic,
  } = useProcedures({
    version: PROVIDER_ROOM_PROCEDURE_VERSION.pending,
    clinicID: historyData?.clinic_id,
    providerUserId: chartAuditUserId,
    activeProcedureTab: activeTab,
  });
  const { clinics: availableClinicsList } = useApiClinics();

  const tabByType = {
    [PROVIDER_ROOM_PROCEDURE_TYPE.cosmetic]: (
      <TabCosmetic
        isVisible={proceduresStatus.isVisible}
        onChangeTab={proceduresType.onChange}
        procedures={procedures}
        isSigning={isSigning}
        isSigned={isSigned}
        isNeedMd={isNeedMd}
        availableClinicsList={availableClinicsList}
        onChangeClinic={selectClinic.onChange}
        clinicId={selectClinic.value}
        showHideActionButton={Boolean(historyData?.isFromChartAuditReport)}
        providerUserId={chartAuditUserId}
      />
    ),
    [PROVIDER_ROOM_PROCEDURE_TYPE.health]: (
      <TabHealth
        isVisible={proceduresStatus.isVisible}
        onChangeTab={proceduresType.onChange}
        procedures={procedures}
        isSigning={isSigning}
        isSigned={isSigned}
        isNeedMd={isNeedMd}
        availableClinicsList={availableClinicsList}
        onChangeClinic={selectClinic.onChange}
        clinicId={selectClinic.value || historyData?.clinic_id}
        showHideActionButton={Boolean(historyData?.isFromChartAuditReport)}
        providerUserId={chartAuditUserId}
      />
    ),
  };

  return (
    <Layout
      search={proceduresSearchTerm}
      status={proceduresStatus}
      loadMore={fetchNextProcedures}
    >
      {tabByType[activeTab] || tabByType[PROVIDER_ROOM_PROCEDURE_TYPE.cosmetic]}
    </Layout>
  );
}

TabChartAudit.propTypes = {
  isSigning: PropTypes.bool,
  isSigned: PropTypes.bool,
  activeTab: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    isSigning: providerRoom.selectors.selectProcedureSigningStatus(state),
    isSigned: providerRoom.selectors.selectProcedureSignedStatus(state),
    activeTab: providerRoom.selectors.selectCurrentTab(state),
  };
}

export default connect(mapStateToProps)(TabChartAudit);
