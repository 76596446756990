import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { http } from "../../../services/HttpService";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../../consts/api";

const requestSchema = yup.object({
  domain: yup.string().required(),
});

const responseSchema = yup.object({
  id: yup.number().required(),
  domain: yup.string().required(),
  status: yup.string().required(),
  records: yup.object({
    cname: yup.array().of(
      yup.object({
        cname: yup.string().required(),
        cnameTarget: yup.string().required(),
      }),
    ),
    txt: yup.array().of(
      yup.object({
        name: yup.string().required(),
        value: yup.string().required(),
      }),
    ),
  }),
});

const composeResponse = (res) => {
  const data = res?.data?.data;

  return {
    id: data?.custom_domain?.id,
    domain: data?.custom_domain?.domain,
    status: data?.custom_domain?.status,
    records: {
      cname: [
        {
          cname: data?.records?.cname?.[0]?.cname,
          cnameTarget: data?.records?.cname?.[0]?.cname_target,
        },
      ],
      txt: data?.records?.txt,
    },
  };
};

export function useAddCustomDomainMutation({ onError, onSuccess } = {}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (dto) => {
      const req = requestSchema.validateSync(dto, {
        strict: true,
      });

      return http.post(HTTP_ENDPOINTS.customDomain.addDomain(), {
        domain: req.domain,
      });
    },
    onError: () => {
      onError?.();
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.customDomains.customDomains],
      });

      const res = responseSchema.validateSync(composeResponse(response), {
        strict: true,
      });

      onSuccess?.(res);
    },
  });
}
