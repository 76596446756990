import { useMemo, useState } from "react";
import {
  PROVIDER_ROOM_PROCEDURE_TYPE,
  useProviderRoomProceduresQuery,
} from "../../../api/queries/useProviderRoomProceduresQuery";
import { TABLE_SIZE } from "../ProviderRoom.consts";
import { useDebouncedState } from "../../../utilities/hooks/useDebouncedState";
import { uiNotification } from "../../../services/UINotificationService";
import { useAppTranslation } from "../../../i18n/useAppTranslation";
import { dispatch } from "../../../store/store";
import { providerRoom } from "../../../store/providerRoom";

export function useProcedures({
  version,
  clinicID = "",
  providerUserId,
  activeProcedureTab,
}) {
  const [type, setType] = useState(PROVIDER_ROOM_PROCEDURE_TYPE.cosmetic);
  const [subAction, setSubAction] = useState(
    activeProcedureTab === PROVIDER_ROOM_PROCEDURE_TYPE.information
      ? "changes-needed"
      : "",
  );
  const [clinicId, setClinicId] = useState(clinicID);
  const [searchTerm, debouncedSearchTerm, setSearchTerm] =
    useDebouncedState("");
  const { tCommon } = useAppTranslation.Common();

  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  } = useProviderRoomProceduresQuery({
    payload: {
      pageSize: TABLE_SIZE,
      procedureVersion: version,
      procedureType:
        activeProcedureTab || PROVIDER_ROOM_PROCEDURE_TYPE.cosmetic,
      searchTerm: debouncedSearchTerm,
      subAction,
      clinicId,
      provider_user_id: providerUserId || "",
    },
    options: {
      staleTime: 0,
      onError: () => {
        uiNotification.error(tCommon("providerRoom.error.fetchProcedures"));
      },
    },
  });

  const procedures = useMemo(() => {
    if (data && data.pages) {
      return data.pages
        .map((p) => p.procedures)
        .reduce((r, p) => [...r, ...p], []);
    }
    return [];
  }, [data]);

  const isEmpty = !isLoading && procedures.length === 0;

  const changeType = (type) => {
    setType(type);
    dispatch(providerRoom.actions.setActiveTab(type));
    if (type === PROVIDER_ROOM_PROCEDURE_TYPE.information) {
      return setSubAction("changes-needed");
    }

    setSubAction("");
  };

  return {
    procedures,
    refetchProcedures: refetch,
    fetchNextProcedures: fetchNextPage,

    proceduresSearchTerm: {
      value: searchTerm,
      onChange: setSearchTerm,
    },

    proceduresType: {
      value: type,
      onChange: changeType,
    },

    selectClinic: {
      value: clinicId,
      onChange: setClinicId,
    },

    proceduresStatus: {
      isLoading: isLoading && !searchTerm,
      isNextFetching: isFetchingNextPage,
      isSearching: isLoading && Boolean(searchTerm),
      isVisible: !isLoading && !isEmpty,
      isEmpty,
      hasMore: hasNextPage,
    },
  };
}
