import React, { useMemo } from "react";
import styles from "./styles.module.scss";
import { Input } from "../../../../../../../shared/Input/Input";
import { useDebouncedState } from "../../../../../../../utilities/hooks/useDebouncedState";
import { Checkbox } from "../../../../../../../shared/Checkbox/Checkbox";
import { shortenString } from "../../../../../../../utilities/general";
import { CrossIcon } from "../../../../../../../assets/Icons/CrossIcon";

export function List({ selectedIds, products, setSelectedIds }) {
  var { 0: search, 1: debouncedSearch, 2: setSearch } = useDebouncedState("");

  var list = useMemo(
    () =>
      products?.filter((product) =>
        product.product_name.toLowerCase().includes(debouncedSearch),
      ),
    [products],
  );

  var selectedItems = useMemo(
    () => list.filter((product) => selectedIds.includes(product.id)),
    [list, selectedIds],
  );

  var removeItem = (id) => {
    setSelectedIds((ids) => ids.filter((i) => i !== id));
  };

  return (
    <div className={styles.root}>
      <Input
        size="small"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        placeholder="Search..."
      />
      <div className={styles.badges}>
        {selectedItems.map((i) => (
          <div key={i.id}>
            <span>{shortenString(i.product_name, 40)}</span>
            <button onClick={() => removeItem(i.id)}>
              <CrossIcon />
            </button>
          </div>
        ))}
      </div>
      <ul>
        {list.map((product) => {
          const isChecked = selectedIds.includes(product.id);
          return (
            <li key={product.id}>
              <Checkbox
                inverted
                size="small"
                label={product.product_name}
                isChecked={isChecked}
                onChange={() => {
                  if (isChecked) {
                    removeItem(product.id);
                  } else {
                    setSelectedIds((ids) => [...ids, product.id]);
                  }
                }}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}
