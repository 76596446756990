import { useClinicPaymentAbilitiesAndCancelPolicyQuery } from "../../../../../../api/queries/useClinicPaymentAbilitiesAndCancelPolicyQuery";

export function useClinics() {
  const {
    data: clinicsData,
    isLoading,
    isSuccess,
  } = useClinicPaymentAbilitiesAndCancelPolicyQuery();

  const clinics = Object.entries(clinicsData?.data?.data?.clinics || {})
    .filter(([_, { can_take_payment }]) => can_take_payment)
    .map(([id, { name }]) => ({
      id: Number(id),
      clinic_name: name,
    }));

  return {
    data: clinics,
    isLoading,
    isSuccess,
  };
}
