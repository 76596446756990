import React, { useMemo } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import styles from "../scss/AdditionalClinicSurgesModal.module.scss";
import { rulesOptions } from "../AdditionalClinicSurgesModal.utils.js";
import { Button } from "../../../../../../../shared/Button/Button.js";
import { Input } from "../../../../../../../shared/Input/Input.js";
import { Select } from "../../../../../../../shared/Select/Select.js";
import { Modal } from "../../../../../../../shared/Modal/Modal.js";
import { InputLabel } from "../../../../../../../shared/InputLabel/InputLabel.js";
import { Checkbox } from "../../../../../../../shared/Checkbox/Checkbox.js";
import { useAddAdditionalClinicSurges } from "../hooks/useAddAdditionalClinicSurges.js";
import { uiNotification } from "../../../../../../../services/UINotificationService.js";
import { CircularProgress } from "../../../../../../../shared/CircularProgress/CircularProgress.js";
import { useQueryClient } from "@tanstack/react-query";
import { useEditAdditionalClinicSurges } from "../hooks/useEditAdditionalClinicSurges.js";

const AdditionalClinicSurgesModal = ({ clinicId, modal, setModal }) => {
  const { mutate: addTax, isLoading } = useAddAdditionalClinicSurges(clinicId);
  const { mutate: editTax, isLoading: isEditing } =
    useEditAdditionalClinicSurges({ clinicId, id: modal.data?.id });
  const queryClient = useQueryClient();

  const schema = yup.object().shape({
    taxable: yup.boolean().required(),
    name: yup.string().required(),
    percentage: yup.number().min(1).max(100).required(),
    rules: yup.array().min(1).required(),
  });

  const optionFormattedTypes = useMemo(() => {
    if (!modal.data?.type_array) return [];
    return modal.data.type_array.map((type) =>
      rulesOptions.find((rule) => type === rule.value),
    );
  }, [modal.data?.type_array]);

  const closeModal = () => setModal({ show: false, data: null });

  const handleMutationSuccess = (message) => {
    closeModal();
    uiNotification.success(message);
    queryClient.invalidateQueries(["additionalClinicSurges"]);
  };

  const mutateTax = (dto) => {
    const mutation = modal.data ? editTax : addTax;
    const successMessage = modal.data
      ? "Additional Clinic Surcharge Updated Successfully"
      : "Additional Clinic Surcharge Created Successfully";

    mutation(dto, {
      onSuccess: () => handleMutationSuccess(successMessage),
      onError: (error) => uiNotification.error(error.response.data.message),
    });
  };

  const { values, errors, setFieldValue, handleSubmit } = useFormik({
    validationSchema: schema,
    initialValues: {
      taxable: modal.data?.taxable || false,
      name: modal.data?.label || "",
      percentage: modal.data?.percentage || "",
      rules: optionFormattedTypes || [],
    },
    validateOnChange: false,
    validateOnBlur: false,

    onSubmit: (values) => {
      const formattedRulesArray = values.rules.map((rule) => rule.value);
      const dto = {
        label: values.name,
        percentage: values.percentage,
        type: formattedRulesArray,
        taxable: values.taxable,
      };

      mutateTax(dto);
    },
  });

  const handleInputChange = (event) => {
    setFieldValue(event.target.name, event.target.value);
  };

  return (
    <Modal
      isOpen
      onClose={closeModal}
      header={<Modal.Title>Create Additional Clinic Surcharges</Modal.Title>}
      footer={
        <div className={styles.footerContainer}>
          <Button
            variant="outlined"
            onClick={closeModal}
            isDisabled={isLoading || isEditing}
          >
            Back
          </Button>
          <Button
            onClick={handleSubmit}
            leftAdornment={
              isLoading || isEditing ? (
                <CircularProgress size="tiny" color="white" />
              ) : null
            }
            isDisabled={isLoading || isEditing}
          >
            Save
          </Button>
        </div>
      }
      footerNoBorder
    >
      <div className={styles.modalContent}>
        <div>
          <InputLabel>Taxable</InputLabel>
          <Checkbox
            type="text"
            name="taxable"
            isChecked={values.taxable}
            onChange={() => setFieldValue("taxable", !values.taxable)}
            className={styles.taxableCheckbox}
          ></Checkbox>
        </div>
        <div>
          <InputLabel>Name</InputLabel>
          <Input
            type="text"
            name="name"
            size="small"
            value={values.name}
            onChange={handleInputChange}
            isError={Boolean(errors.name)}
          ></Input>
        </div>
        <div>
          <InputLabel>%</InputLabel>
          <Input
            type="text"
            name="percentage"
            size="small"
            value={values.percentage}
            onChange={handleInputChange}
            maxLength={3}
            isError={Boolean(errors.percentage)}
          ></Input>
        </div>
        <div>
          <InputLabel>Rules</InputLabel>
          <Select
            size="small"
            value={values.rules}
            options={rulesOptions}
            onChange={(option) => {
              setFieldValue("rules", option);
            }}
            isMulti
            isError={Boolean(errors.rules)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AdditionalClinicSurgesModal;
