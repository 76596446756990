import * as yup from "yup";
import { useInfiniteQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, ORDER_TYPES, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { unwrapOr } from "../../utilities/general";
import {
  composePaginationSchema,
  getNextPaginationPage,
  paginationSchema,
} from "../../schemas/pagination";

export const PROVIDER_ROOM_PROCEDURE_VERSION = {
  pending: "pending",
  signed: "signed",
  sentToMd: "sent-to-md",
  signedByMd: "signed-by-md",
  hidden: "hidden",
};

export const PROVIDER_ROOM_PROCEDURE_TYPE = {
  cosmetic: "cosmetic",
  health: "health",
  information: "all",
};

/*         *
 * SCHEMAS *
 *         */

/* Request Schema Start */

const requestSchema = yup.object({
  pageSize: yup.number().required(),

  order: yup.string().test({
    test: (value) => !value || Object.values(ORDER_TYPES).includes(value),
  }),

  searchTerm: yup.string(),

  clinicId: yup.string().nullable(),
  user_id: yup.string().nullable(),

  procedureVersion: yup
    .string()
    .test({
      test: (value) =>
        Object.values(PROVIDER_ROOM_PROCEDURE_VERSION).includes(value),
    })
    .required(),

  procedureType: yup
    .string()
    .test({
      test: (value) =>
        Object.values(PROVIDER_ROOM_PROCEDURE_TYPE).includes(value),
    })
    .required(),
});

/* Request Schema End */

/* Response Schema Start */

const responseSchema = paginationSchema.concat(
  yup.object({
    procedures: yup.array().of(
      yup.object({
        id: yup.number().required(),

        name: yup.string().required(),

        type: yup.string().nullable(),

        date: yup.string().nullable(),

        info: yup.array().of(
          yup.object({
            id: yup.number().required(),
            procedureId: yup.number().required(),
            field: yup.string().nullable(),
            unit: yup.string().nullable(),
            value: yup.string().nullable(),
            createdAt: yup.string().required(),
          }),
        ),

        appointmentId: yup.number().required(),

        createdAt: yup.string().required(),

        doctorFullName: yup.string().nullable(),

        isAfterPhotos: yup.bool().required(),

        isConsult: yup.bool().required(),

        isHidden: yup.bool().required(),

        isMdSigned: yup.bool().required(),

        isSentToMd: yup.bool().required(),

        isProcedureImage: yup.bool().required(),

        notesCount: yup.number().required(),

        showConsentsBtn: yup.number().nullable(),

        consentIds: yup.array().of(yup.number()).required(),

        patientQuestionnairesAnswersCount: yup.number().required(),

        showQuestionnaireBtn: yup.number().nullable(),

        prescriptionCount: yup.number().required(),

        scriptsurePrescription: yup.object().nullable(),

        posInvoicesCount: yup.number().required(),

        image: yup.string().nullable(),

        image45: yup.string().nullable(),

        patientId: yup.number().nullable(),

        procedureType: yup.string().required(),

        mdNote: yup.string().nullable(),

        mdUser: yup
          .object({
            id: yup.number().required(),
            firstName: yup.string().required(),
            lastName: yup.string().nullable(),
            fullName: yup.string().required(),
            roleName: yup.string().nullable(),
            imageUrl: yup.string().nullable(),
            signatureUrl: yup.string().nullable(),
          })
          .nullable(),

        patient: yup
          .object({
            id: yup.number().required(),
            firstName: yup.string().required(),
            lastName: yup.string().nullable(),
            fullName: yup.string().required(),
          })
          .nullable(),

        hasTraceability: yup.boolean().nullable(),
        clinic: yup
          .object({
            id: yup.number().required(),
            clinic_name: yup.string().required(),
          })
          .nullable(),

        traceInjections: yup
          .array()
          .of(
            yup.object({
              id: yup.number().required(),
              procedureId: yup.number().required(),
              productId: yup.number().nullable(),
              productInventoryId: yup.number().nullable(),
              chartingPackageId: yup.number().nullable(),
              unitsConsumed: yup.number().required(),
              price: yup.number().nullable(),
              status: yup.number().required(),
              createdAt: yup.string().required(),
            }),
          )
          .required(),

        injections: yup
          .array()
          .of(
            yup.object({
              id: yup.number().required(),
              procedureId: yup.number().required(),
              productName: yup.string().nullable(),
              quantity: yup.number().required(),
              productId: yup.number().nullable(),
              product: yup
                .object({
                  id: yup.number().required(),
                  toxinType: yup.string().nullable(),
                  price: yup.number().required(),
                  imageUrl: yup.string().nullable(),
                })
                .nullable(),
            }),
          )
          .required(),

        injectionList: yup
          .array()
          .of(
            yup.object({
              productName: yup.string().nullable(),
              quantity: yup.number().required(),
              unit: yup.string().nullable(),
              productId: yup.number().nullable(),
              procedureId: yup.number().nullable(),
              price: yup.number().required(),
            }),
          )
          .required(),
      }),
    ),
  }),
);

/* Response Schema End */

/*           *
 * COMPOSERS *
 *           */

/* Compose Response Start */

const composeResponse = (res, req) => {
  const data = unwrapOr(() => res.data.data.procedures.data, []);

  const extractConcentIds = (value) => {
    if (typeof value === "string") {
      return value
        .split(",")
        .map(Number)
        .filter((i) => !isNaN(i))
        .filter(Boolean);
    }
    return [];
  };

  const extractQuantity = (v) => (isNaN(Number(v)) ? 0 : Number(v));

  return {
    ...composePaginationSchema({
      response: unwrapOr(() => res.data.data.procedures, {}),
      pageSize: req.pageSize,
    }),
    procedures: data.map((p) => ({
      id: p.id,

      name: p.procedure_name,

      type: p.type || null,

      date: p.procedure_date || null,

      info: (p.procedure_information || []).map((i) => ({
        id: i.id,
        procedureId: i.procedure_id,
        field: i.field || null,
        unit: i.unit || null,
        value: i.value || null,
        createdAt: i.date_created,
      })),

      appointmentId: p.appointment_id,

      createdAt: p.created,

      doctorFullName: p.doctor_name || null,

      isAfterPhotos: Boolean(p.is_after_photos),

      isConsult: Boolean(p.is_consult),

      isHidden: Boolean(p.is_hidden),

      isMdSigned: Boolean(p.md_signed),

      isSentToMd: Boolean(p.sent_to_md),

      isProcedureImage: Boolean(p.is_procedure_image),

      notesCount: p.procedure_notes_count || 0,

      showConsentsBtn: p.show_consents_btn || null,

      consentIds: extractConcentIds(p.consent_ids),

      patientQuestionnairesAnswersCount:
        p.patientQuestionnairesAnswersCount || 0,

      showQuestionnaireBtn: p.show_questionnaires_btn || null,

      prescriptionCount: p.procedure_prescription_count || 0,

      scriptsurePrescription: p.procedure_scriptsure_prescription || null,

      posInvoicesCount: p.pos_invoices_count || 0,

      image: p.procedure_image || null,

      image45: p.procedure_image_45 || null,

      patientId: p.patient_id || null,

      procedureType: p.procedure_type || null,

      mdNote: p.md_note || null,

      mdUser: p.md_user
        ? {
            id: p.md_user.id,
            firstName: p.md_user.firstname,
            lastName: p.md_user.lastname || null,
            fullName: p.md_user.full_name,
            roleName: p.md_user.role_name || null,
            imageUrl: p.md_user.user_image_url || null,
            signatureUrl: p.md_user.signature_url || null,
          }
        : null,

      patient: p.patient
        ? {
            id: p.patient.id,
            firstName: p.patient.firstname,
            lastName: p.patient.lastname || null,
            fullName: p.patient.full_name,
          }
        : null,

      hasTraceability: p.is_trace_complete || false,
      clinic: p.clinic
        ? {
            id: p.clinic.id,
            clinic_name: p.clinic.clinic_name,
          }
        : null,

      traceInjections: (p.trace_injections || []).map((i) => ({
        id: i.id,
        procedureId: i.procedure_id,
        productId: i.product_id || null,
        productInventoryId: i.product_inventory_id || null,
        chartingPackageId: i.charting_package_id || null,
        unitsConsumed: i.units_consumed || 0,
        price: i.price || null,
        status: i.status || 0,
        createdAt: i.created,
      })),

      injections: (p.injections || []).map((i) => ({
        id: i.id,
        procedureId: i.procedure_id,
        productName: i.product_name || null,
        quantity: extractQuantity(i.quantity),
        productId: i.product_id || null,
        product: i.product
          ? {
              id: i.product.id,
              toxinType: i.product.toxin_type || null,
              price: i.product.price,
              imageUrl: i.product.product_image_url || null,
            }
          : null,
      })),

      injectionList: (p.injection_array || []).map((i) => ({
        productName: i.product_name || null,
        quantity: extractQuantity(i.quantity),
        unit: i.unit || null,
        productId: i.product_id || null,
        procedureId: i.procedure_id || null,
        price: i.price,
      })),
    })),
  };
};

/* Compose Response Procedures End */

// ---------

function createProviderRoomProceduresQueryKey({
  searchTerm,
  order,
  pageSize,
  version,
  type,
  clinic_id,
  user_id,
}) {
  return [
    QUERY_KEYS.providerRoomProcedures,
    searchTerm,
    order,
    pageSize,
    version,
    type,
    clinic_id,
    user_id,
  ].filter(Boolean);
}

export function useProviderRoomProceduresQuery({ payload, options = {} }) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });
  return useInfiniteQuery({
    queryKey: createProviderRoomProceduresQueryKey({
      searchTerm: req.searchTerm,
      order: req.order,
      pageSize: req.pageSize,
      version: req.procedureVersion,
      type: req.procedureType,
      clinic_id: req.clinicId,
      user_id: req.provider_user_id,
    }),
    queryFn: async ({ pageParam = 1 }) => {
      const res = await http.get(HTTP_ENDPOINTS.getProviderRoomProcedures(), {
        params: {
          page: pageParam,
          pagesize: req.pageSize,
          sortorder: req.order || ORDER_TYPES.asc,
          term: req.searchTerm || "",
          action: req.procedureVersion,
          sub_action: req.subAction,
          procedure_type: req.procedureType,
          clinic_id: req.clinicId || "",
          user_id: req.provider_user_id || "",
        },
      });
      return responseSchema.validateSync(composeResponse(res, req), {
        strict: true,
      });
    },
    ...options,
    getNextPageParam: getNextPaginationPage,
  });
}
