import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { http } from "../../../services/HttpService";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../../consts/api";

const requestSchema = yup.object({
  domainId: yup.number().required(),
});

export function useActivateDomainMutation({ onError, onSuccess } = {}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (dto) => {
      const req = requestSchema.validateSync(dto, {
        strict: true,
      });

      return http.post(HTTP_ENDPOINTS.customDomain.activateDomain(), {
        domain_id: req.domainId,
      });
    },
    onError: () => {
      onError?.();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.customDomains.customDomains],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.customDomains.customDomain],
      });
      onSuccess?.();
    },
  });
}
