import { getIsPosEnabled } from "../../Utils";

export const isSalesRestrictedReport = (reportCategory, reportSysName) => {
  const isPosEnabled = getIsPosEnabled();
  const isRestricted = reportCategory?.some(
    ({ sysname }) => sysname === reportSysName,
  );

  return isRestricted && !isPosEnabled;
};
