import React from "react";
import { Button } from "../../../../../../shared/Button/Button";
import { useAppTranslation } from "../../../../../../i18n/useAppTranslation";
import { Input } from "../../../../../../shared/Input/Input";
import { InputWithCopyButton } from "./shared/InputWithCopyButton/InputWithCopyButton";
import { useGetVerificationStatusMutation } from "../../../../../../api/customDomain/mutations/useGetVerificationStatusMutation";
import { useDeactivateDomainMutation } from "../../../../../../api/customDomain/mutations/useDeactivatDomainMutation";
import { useGetDomainInfoQuery } from "../../../../../../api/customDomain/queries/useGetDomainInfoQuery";
import { useActivateDomainMutation } from "../../../../../../api/customDomain/mutations/useActivateDomainMutation";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { SUBDOMAIN_STATUSES } from "../../../../../../api/customDomain/queries/useGetCustomDomainsQuery";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";
import Loader from "../../../../../Common/Loader";
import styles from "./SubDomainEditor.module.scss";

export function SubDomainEditor({ domainId, onClose }) {
  const { tSmartConfig } = useAppTranslation.SmartConfig();

  const { data, isLoading } = useGetDomainInfoQuery(
    { domainId: Number(domainId) },
    {
      onError: () => {
        uiNotification.error(
          tSmartConfig("patientPortal.customDomain.error.info"),
        );
      },
    },
  );

  const {
    mutateAsync: deactivateDomain,
    isLoading: isDeactivateDomainPending,
  } = useDeactivateDomainMutation({
    onError: () => {
      uiNotification.error(
        tSmartConfig("patientPortal.customDomain.error.deactivate"),
      );
    },
    onSuccess: () => {
      uiNotification.success(
        tSmartConfig("patientPortal.customDomain.success.deactivate"),
      );
      onClose();
    },
  });

  const { mutateAsync: activateDomain, isLoading: isActivateDomainPending } =
    useActivateDomainMutation({
      onSuccess: () => {
        uiNotification.success(
          tSmartConfig("patientPortal.customDomain.success.activate"),
        );
        onClose();
      },
      onError: () => {
        uiNotification.error(
          tSmartConfig("patientPortal.customDomain.error.activate"),
        );
      },
    });

  const { isLoading: isVerificationPending, mutateAsync: getSubdomainStatus } =
    useGetVerificationStatusMutation({
      onSuccess: (data) => {
        uiNotification.success(
          tSmartConfig("patientPortal.customDomain.success.currentStatus", {
            status: data?.domainStatus,
          }),
        );
      },
      onError: () => {
        uiNotification.error(
          tSmartConfig("patientPortal.customDomain.error.check"),
        );
      },
    });

  const getVerificationStatus = async () => {
    await getSubdomainStatus({ domainId: data.id });
  };

  const onDeactivateDomain = async () => {
    await deactivateDomain({ domainId: data.id });
  };

  const onActivateDomain = async () => {
    await activateDomain({ domainId: data.id });
  };

  return (
    <div className={styles.root}>
      <Loader showLoader={isLoading} isFullWidth={true} />
      {!isLoading && (
        <div>
          <h4>{tSmartConfig("patientPortal.customDomain.edit.title")}</h4>
          <div className={styles.info}>
            <h5>{tSmartConfig("patientPortal.customDomain.edit.editInfo")}</h5>
            <div>
              <i>{tSmartConfig("patientPortal.customDomain.edit.info")}</i>
            </div>
          </div>
          <div className={styles.subdomainName}>
            {tSmartConfig("patientPortal.customDomain.edit.subdomain")}{" "}
            {data?.domain}
          </div>
          <div className={styles.infoWrapper}>
            <div>
              <div className={styles.infoTitle}>
                {tSmartConfig("patientPortal.customDomain.edit.record")}
              </div>
              <Input isReadonly disabled className={styles.txt} value={"TXT"} />
            </div>
            <div>
              <div className={styles.infoTitle}>
                {tSmartConfig("patientPortal.customDomain.edit.host")}
              </div>
              <InputWithCopyButton
                disabled={true}
                isReadOnly={true}
                value={data?.records.txt[0].name}
              />
            </div>
            <div>
              <div className={styles.infoTitle}>
                {tSmartConfig("patientPortal.customDomain.edit.requeiredValue")}
              </div>
              <InputWithCopyButton
                disabled={true}
                isReadOnly={true}
                value={data?.records.txt[0].value}
              />
            </div>
          </div>
          <div className={styles.infoWrapper}>
            <div>
              <div className={styles.infoTitle}>
                {tSmartConfig("patientPortal.customDomain.edit.record")}
              </div>
              <Input isReadonly disabled className={styles.txt} value={"TXT"} />
            </div>
            <div>
              <div className={styles.infoTitle}>
                {tSmartConfig("patientPortal.customDomain.edit.host")}
              </div>
              <InputWithCopyButton
                disabled={true}
                isReadOnly={true}
                value={data?.records.txt[1].name}
              />
            </div>
            <div>
              <div className={styles.infoTitle}>
                {tSmartConfig("patientPortal.customDomain.edit.requeiredValue")}
              </div>
              <InputWithCopyButton
                disabled={true}
                isReadOnly={true}
                value={data?.records.txt[1].value}
              />
            </div>
          </div>
          <div className={styles.infoWrapper}>
            <div>
              <div className={styles.infoTitle}>
                {tSmartConfig("patientPortal.customDomain.edit.record")}
              </div>
              <Input
                isReadonly
                disabled
                className={styles.txt}
                value={"CNAME"}
              />
            </div>
            <div>
              <div className={styles.infoTitle}>
                {tSmartConfig("patientPortal.customDomain.edit.host")}
              </div>
              <InputWithCopyButton
                disabled={true}
                isReadOnly={true}
                value={data?.records?.cname[0].cname}
              />
            </div>
            <div>
              <div className={styles.infoTitle}>
                {tSmartConfig("patientPortal.customDomain.edit.requeiredValue")}
              </div>
              <InputWithCopyButton
                disabled={true}
                isReadOnly={true}
                value={data?.records?.cname[0].cnameTarget}
              />
            </div>
          </div>
          <div className={styles.bottomBlock}>
            <div>
              <div href="#" className={styles.videoLink}>
                {tSmartConfig("patientPortal.customDomain.edit.help")}
              </div>
            </div>
            <div className={styles.bottomButtonsWrapper}>
              <Button onClick={() => onClose()} variant="outlined">
                Back
              </Button>
              {data?.status === SUBDOMAIN_STATUSES.active && (
                <Button
                  onClick={() => onDeactivateDomain()}
                  isLoading={isDeactivateDomainPending}
                  disabled={isDeactivateDomainPending}
                  rightAdornment={
                    isDeactivateDomainPending ? (
                      <CircularProgress size="small" color="white" />
                    ) : null
                  }
                >
                  {tSmartConfig(
                    "patientPortal.customDomain.edit.deactivateDomain",
                  )}
                </Button>
              )}
              {data?.status === SUBDOMAIN_STATUSES.inactive && (
                <Button
                  onClick={() => onActivateDomain()}
                  isLoading={isActivateDomainPending}
                  disabled={isActivateDomainPending}
                  rightAdornment={
                    isActivateDomainPending ? (
                      <CircularProgress size="small" color="white" />
                    ) : null
                  }
                >
                  {tSmartConfig(
                    "patientPortal.customDomain.edit.activateDomain",
                  )}
                </Button>
              )}
              {data?.status === SUBDOMAIN_STATUSES.pending && (
                <Button
                  isLoading={isVerificationPending}
                  isDisabled={isVerificationPending}
                  onClick={() => getVerificationStatus()}
                  rightAdornment={
                    isVerificationPending ? (
                      <CircularProgress size="small" color="white" />
                    ) : null
                  }
                >
                  {tSmartConfig("patientPortal.customDomain.edit.checkStatus")}
                </Button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
