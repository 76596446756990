import { useMutation } from "@tanstack/react-query";
import { http } from "../../../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../../../consts/api";

export const useAddAdditionalClinicSurges = (clinicId, options = {}) => {
  const res = useMutation(
    (dto) => http.post(HTTP_ENDPOINTS.customTax.addCustomTax(clinicId), dto),
    { ...options },
  );
  return res;
};
