import { positionFooterCorrectly } from "../../Utils/services.js";
import { http } from "../../services/HttpService.js";

positionFooterCorrectly();

export function emptyMembershipWallet() {
  return (dispatch) => {
    dispatch({ type: "MEMBRTSHIP_WALLET_EMPTY_DATA" });
  };
}

export function getMembershipWallet() {
  return (dispatch) => {
    http
      .get("account/wallet-membership-setting")
      .then((response) => {
        dispatch({
          type: "GET_MEMBERSHIP_WALLET_DATA",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "GET_MEMBERSHIP_WALLET_DATA",
          payload: error.response.data,
        });
      });
  };
}

export function updateWallet(formData) {
  return async (dispatch) => {
    return http
      .put("settings-wallet", formData)
      .then((response) => {
        dispatch({
          type: "UPDATE_SETTING_WALLET_DATA",
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_SETTING_WALLET_DATA",
          payload: error.response.data,
        });
        return Promise.reject(error.response?.data || {});
      });
  };
}
export function updateMembership(formData) {
  return async (dispatch) => {
    return http
      .put("settings-membership", formData)
      .then((response) => {
        dispatch({
          type: "UPDATE_SETTING_MEMBERSHIP_DATA",
          payload: response.data,
        });
        return response.data;
      })
      .catch((error) => {
        dispatch({
          type: "UPDATE_SETTING_MEMBERSHIP_DATA",
          payload: error.response.data,
        });
        return Promise.reject(error.response?.data || {});
      });
  };
}

const serialize = function (obj) {
  var str = [];

  for (var p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  }

  return str.join("&");
};

export const searchProductByName = (formData) => {
  return (dispatch) => {
    http
      .get("discount-packages/search-product?" + serialize(formData.params))
      .then((response) => {
        dispatch({
          type: "MEMBERSHIP_PRODUCT_SEARCH_LIST",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "MEMBERSHIP_PRODUCT_SEARCH_LIST",
          payload: error.response.data,
        });
      });
  };
};

export function getMembershipMultiTier(membershipTierId) {
  return (dispatch) => {
    http
      .get("save-multitier-program/" + membershipTierId)
      .then((response) => {
        dispatch({ type: "GET_MEMBERSHIP_MULTI_TIER", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "GET_MEMBERSHIP_MULTI_TIER",
          payload: error.response.data,
        });
      });
  };
}

export function saveMembershipMultiTier(membershipTierId, formData) {
  return async (dispatch) => {
    await http
      .post("save-multitier-program/" + membershipTierId, formData)
      .then((response) => {
        dispatch({
          type: "SAVE_MEMBERSHIP_MULTI_TIER",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "SAVE_MEMBERSHIP_MULTI_TIER",
          payload: error.response.data,
        });
      });
  };
}

export function changeMembershipMultiTierStatus(membershipTierId, formData) {
  return (dispatch) => {
    http
      .put("save-multitier-program/" + membershipTierId, formData)
      .then((response) => {
        dispatch({
          type: "CHANGE_MEMBERSHIP_MULTI_TIER_STATUS",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "CHANGE_MEMBERSHIP_MULTI_TIER_STATUS",
          payload: error.response.data,
        });
      });
  };
}

export function deleteMembershipMultiTier(membershipTierId) {
  return (dispatch) => {
    http
      .delete("save-multitier-program/" + membershipTierId)
      .then((response) => {
        dispatch({
          type: "DELETE_MEMBERSHIP_MULTI_TIER",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "DELETE_MEMBERSHIP_MULTI_TIER",
          payload: error.response.data,
        });
      });
  };
}

export function getMembershipAgreement() {
  return (dispatch) => {
    http
      .get("membership-get-all")
      .then((response) => {
        dispatch({
          type: "MEMEBERSHIP_AGREEMENT_DATA",
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: "MEMEBERSHIP_AGREEMENT_DATA",
          payload: error.response.data,
        });
      });
  };
}
export function getMembershipLogs(page = 1) {
  return (dispatch) => {
    http
      .get("membership-logs", {
        params: { page },
      })
      .then((response) => {
        dispatch({ type: "MEMEBERSHIP_LOGS_DATA", payload: response.data });
      })
      .catch((error) => {
        dispatch({
          type: "MEMEBERSHIP_LOGS_DATA",
          payload: error.response.data,
        });
      });
  };
}
