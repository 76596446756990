import React, { useMemo } from "react";
import { useMultiselectEntity } from "../../../../utilities/hooks/useMultiselectEntity";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { PROCEDURE_STATE_TYPES } from "../../../../consts/general";
import { unwrapOr } from "../../../../utilities/general";
import { CompleteIcon } from "../../components/CompleteIcon/CompleteIcon";
import { ProcedureTableInfo } from "../../components/ProcedureTableInfo/ProcedureTableInfo";
import { CompleteLink } from "../../components/CompleteLink/CompleteLink";
import {
  getProcedureRoutes,
  getProcedureStatuses,
} from "../../ProviderRoom.utils";
import { ProcedureTreatmentSummary } from "../../components/ProcedureTreatmentSummary/ProcedureTreatmentSummary";
import parentClasses from "../../sass/ProviderRoom.module.scss";
import { useCurrentUserQuery } from "../../../../api/queries/useUserQuery";
import { Button } from "../../../../shared/Button/Button";

const type = PROCEDURE_STATE_TYPES.sentToMd;

export function useTableInfo({
  procedures = [],
  showHideActionButton,
  providerUserId,
  openRequestModal,
}) {
  const { tCommon } = useAppTranslation.Common();
  const { data: user } = useCurrentUserQuery();

  const { selected, resetSelected } = useMultiselectEntity({
    allEntities: procedures.map((p) => p.id),
    initialSelect: [],
    isSelectAllOnLoadMore: true,
  });

  const isScriptsureEnabled = unwrapOr(
    () => Boolean(user.account.integrations.isScriptsureEnabled),
    false,
  );

  const cols = [
    {
      data: tCommon("providerRoom.table.procedureInfo"),
      accessor: "info",
    },
    {
      data: tCommon("label.patient"),
      accessor: "patientName",
    },
    {
      data: tCommon("providerRoom.table.clinic"),
      accessor: "clinicName",
    },
    {
      data: tCommon("providerRoom.table.treatmentSummary"),
      accessor: "treatmentSummary",
    },
    {
      data: tCommon("providerRoom.table.latestNote"),
      accessor: "hasLatestNote",
      headColClassName: parentClasses.tableHeadCentered,
    },
    {
      data: tCommon("providerRoom.table.consent"),
      accessor: "hasConsent",
      headColClassName: parentClasses.tableHeadCentered,
    },
    {
      data: tCommon("providerRoom.table.questionnaire"),
      accessor: "hasQuestionnaire",
      headColClassName: parentClasses.tableHeadCentered,
    },
    ...(isScriptsureEnabled
      ? [
          {
            data: tCommon("providerRoom.table.prescription"),
            accessor: "hasPrescription",
            headColClassName: parentClasses.tableHeadCentered,
          },
        ]
      : []),
    {
      data: tCommon("providerRoom.table.consultOnly"),
      accessor: "consultOnly",
      headColClassName: parentClasses.tableHeadCentered,
    },
    {
      data: tCommon("providerRoom.table.actions"),
      accessor: "actions",
      headColClassName: parentClasses.tableActionHead,
    },
  ];
  const data = useMemo(
    () =>
      procedures.map((p) => {
        const { hasNotes, hasConsent, hasQuestionnaire, hasPrescription } =
          getProcedureStatuses(p);

        const {
          infoRoute,
          notesRoute,
          consentRoute,
          questionnaireRoute,
          prescriptionRoute,
          viewQuestionnaireRoute,
          viewConsentRoute,
        } = getProcedureRoutes({
          procedure: p,
          type,
        })[p.procedureType];

        const routeWithState = {
          pathname: infoRoute,
          state: {
            chartUserId: providerUserId,
            isFromChartAuditReport: showHideActionButton,
          },
        };

        return {
          key: p.id,

          info: (
            <ProcedureTableInfo
              title={p.name}
              rawDate={p.date}
              link={
                showHideActionButton || providerUserId
                  ? routeWithState
                  : infoRoute
              }
            />
          ),

          mdName: unwrapOr(() => p.mdUser.fullName, tCommon("symbol.longDash")),

          patientName: unwrapOr(
            () => p.patient.fullName,
            tCommon("symbol.longDash"),
          ),

          clinicName: unwrapOr(
            () => p.clinic.clinic_name,
            tCommon("symbol.longDash"),
          ),

          treatmentSummary: (
            <ProcedureTreatmentSummary
              injectionsList={p.injectionList}
              info={p.info}
            />
          ),

          hasLatestNote: (
            <CompleteLink
              isCompleted={hasNotes}
              to={notesRoute}
              isDisabled={showHideActionButton}
            >
              <CompleteIcon isCompleted={hasNotes} />
            </CompleteLink>
          ),

          hasConsent: (
            <CompleteLink
              isCompleted={hasConsent}
              to={hasConsent ? consentRoute : viewConsentRoute}
              isDisabled={showHideActionButton}
            >
              <CompleteIcon isCompleted={hasConsent} />
            </CompleteLink>
          ),

          hasQuestionnaire: (
            <CompleteLink
              isCompleted={hasQuestionnaire}
              to={
                hasQuestionnaire ? questionnaireRoute : viewQuestionnaireRoute
              }
              isDisabled={showHideActionButton}
            >
              <CompleteIcon isCompleted={hasQuestionnaire} />
            </CompleteLink>
          ),

          hasPrescription: hasPrescription ? (
            <CompleteLink
              isCompleted={hasPrescription}
              to={prescriptionRoute}
              isDisabled={showHideActionButton}
            >
              <CompleteIcon isCompleted={hasPrescription} />
            </CompleteLink>
          ) : (
            <CompleteIcon isCompleted={hasPrescription} />
          ),

          consultOnly: (
            <div className={parentClasses.tableHeadCentered}>
              {p.isConsult ? tCommon("label.yes") : tCommon("label.no")}
            </div>
          ),

          actions: (
            <Button
              onClick={() =>
                openRequestModal({ info: p.mdNote, md: p.mdUser.fullName })
              }
            >
              {tCommon("providerRoom.action.viewRequest")}
            </Button>
          ),
        };
      }),
    [procedures, selected],
  );

  return {
    cols,
    data,
    selected,
    resetSelected,
  };
}
