import React from "react";

export function UnsavedChangesMessage() {
  return (
    <div>
      <div>You have unsaved changes.</div>
      <div>Would you like to save them?</div>
    </div>
  );
}
