/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { yupResolver } from "@hookform/resolvers/yup";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { daysOfWeek, timeSlots } from "../../_legacy/Constants";
import { useStripe } from "../../hooks/useStripe";
import ConvertToAppointmentModal from "../../pages/Event/components/waitList/ConvertToAppointmentModal";
import {
  WAITLIST_ANY_MONTH,
  WAIT_LIST_MONTHS,
  WAIT_LIST_YEARS,
} from "../../pages/Event/Event.consts";
import { validateWaitListMonths } from "../../pages/Event/Event.utils";
import {
  GeServicesList,
  useGetWaitlistDetailsQuery,
  useUpdateWaitlistDetailsQuery,
} from "../../_legacy/Queries";
import { Select } from "../../shared/Select/Select";
import { getClearentToken } from "../../Utils";
import { CommonDialog } from "../Common";
import MembershipBadge from "../Common/MembershipBadge";
import CCDetails from "./CCDetails";
import ClinicsProvidersServices from "./ClinicsProvidersServices";
import { clearSetupIntent } from "../../Actions/Stripe/StripeActions";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import { uiNotification } from "../../services/UINotificationService";

const FieldDisplay = ({ name, value }) => {
  return (
    <div className="field-display">
      <p className="field-title m-b-0">{name}:</p>{" "}
      <p className="field-value m-b-0">{value}</p>
    </div>
  );
};

const waitlistSchema = yup.object().shape({
  selectedDays: yup.array().required("Required").min(1, "Required"),
  selectedMonth: yup.array().required("Required").min(1, "Required"),
  selectedTimeSlot: yup.array().required("Required").min(1, "Required"),
  selectedClinics: yup
    .array()
    .min(1)
    .required("Required")
    .of(
      yup.object().shape({
        id: yup.number().nullable().required("Required").typeError("Required"),
      }),
    ),
  selectedProviders: yup
    .array()
    .min(1)
    .required("Required")
    .of(
      yup.object().shape({
        id: yup.number().nullable().required("Required").typeError("Required"),
      }),
    ),
  selectedServices: yup
    .array()
    .min(1)
    .required("Required")
    .of(
      yup.object().shape({
        id: yup.number().nullable().required("Required").typeError("Required"),
      }),
    ),
  clearentZipCode: yup
    .number()
    .nullable()
    .when(["pos_gateway", "checkCC"], {
      is: (pos_gateway, checkCC) =>
        Boolean(pos_gateway === "clearent" && checkCC),
      then: yup.number().nullable().required("Required").typeError("Required"),
    }),
  clearentEmail: yup
    .string()
    .nullable()
    .when(["pos_gateway", "checkCC"], {
      is: (pos_gateway, checkCC) =>
        Boolean(pos_gateway === "clearent" && checkCC),
      then: yup.string().nullable().email().required("Required"),
    }),
});

const formFields = [
  "selectedDays",
  "selectedMonth",
  "selectedYear",
  "selectedTimeSlot",
  "waitlistNotes",
  "editServicesStatus",
  "selectedClinics",
  "selectedProviders",
  "selectedServices",
  "clearentEmail",
  "clearentZipCode",
  "pos_gateway",
];

const AddEditWaitlistAppointment = (props) => {
  const {
    onClose,
    waitlistDetails,
    editEnabled = false,
    history,
    appointment_wait_list_providers,
    stripeIntent,
    clearSetupIntent,
  } = props;

  const { tCommon } = useAppTranslation.Common();

  const [openConvertWarningModal, setConverWarningModal] = useState({
    isOpen: false,
    data: null,
  });

  const { mutate: updateWaitlist, isLoading: isUpdatingWaitlistDetails } =
    useUpdateWaitlistDetailsQuery();

  const {
    appointment_notes,
    appointment_wait_list_services,
    appointment_wait_list_clinics,
    patient,
    appointment_wait_list_days,
    appointment_wait_list_years,
    appointment_wait_list_months,
    appointment_wait_list_timeslots,
  } = useMemo(() => waitlistDetails, [waitlistDetails.id]);

  const formMethods = useForm({
    defaultValues: {
      selectedDays: appointment_wait_list_days.map((dayDetails) =>
        parseInt(dayDetails.day, 10),
      ),
      selectedMonth:
        appointment_wait_list_months.length === 12
          ? [WAITLIST_ANY_MONTH]
          : appointment_wait_list_months.map((monthDetails) =>
              WAIT_LIST_MONTHS.find(
                (monthOption) => monthOption.id === monthDetails.month,
              ),
            ),
      selectedYear: WAIT_LIST_YEARS.find(
        (yearData) => yearData.id === appointment_wait_list_years[0]?.year,
      ),

      selectedTimeSlot: appointment_wait_list_timeslots.map(
        (selectedTimeSlotDetails) =>
          parseInt(selectedTimeSlotDetails.timeslot_id, 10),
      ),
      editServicesStatus: editEnabled,
      selectedClinics: appointment_wait_list_clinics.map(
        (selectedClinicDetails) => ({
          id: selectedClinicDetails.clinic_id,
          name: selectedClinicDetails.clinic.clinic_name,
        }),
      ),
      selectedProviders: appointment_wait_list_providers.map(
        (selectedProviderDetails) => ({
          id: selectedProviderDetails.id,
          name: selectedProviderDetails.full_name,
        }),
      ),
      selectedServices: appointment_wait_list_services.map(
        (selectedServiceDetails) => ({
          id: selectedServiceDetails.service.id,
          name: selectedServiceDetails.service.name,
          downtimes: selectedServiceDetails.service?.service_downtimes || [],
        }),
      ),
      waitlistNotes: appointment_notes,
      pos_gateway: JSON.parse(localStorage.getItem("userData")).account
        .pos_gateway,
      ...(patient.card_on_files?.length > 0 && {
        cardNumberOnFile: patient.card_on_files[0].card_number,
        changeCardNumber: false,
        clearentZipCode: patient.card_on_files[0].billing_zip,
        clearentEmail: patient.email,
      }),
    },
    resolver: yupResolver(waitlistSchema),
  });

  const stripeCardNumberRef = useRef();
  const stripeInstance = useStripe();

  const {
    register,
    setValue,
    unregister,
    handleSubmit,
    watch,
    formState: { errors },
  } = formMethods;

  const {
    selectedDays,
    selectedMonth,
    selectedYear,
    selectedTimeSlot,
    waitlistNotes,
    checkCC,
    clearentZipCode,
    clearentEmail,
    pos_gateway,
    cardNumberOnFile,
    changeCardNumber,
  } = watch();

  useEffect(() => {
    formFields.forEach((formField) => {
      register(formField);
    });

    return () => {
      formFields.forEach((formField) => {
        unregister(formField);
      });
    };
  }, []);

  const handleToggleWeekDay = (id) => {
    const isAnySelected = selectedDays.includes(daysOfWeek[0].id);
    if (daysOfWeek[0].id === id) {
      setValue(
        "selectedDays",
        isAnySelected ? [] : daysOfWeek.map((weekday) => weekday.id),
      );
    } else {
      setValue(
        "selectedDays",
        isAnySelected || selectedDays.includes(id)
          ? selectedDays.filter(
              (weekDayId) => ![id, daysOfWeek[0].id].includes(weekDayId),
            )
          : [...selectedDays, id],
      );
    }
  };

  const handleToggleTimeSlot = (id) => {
    const isAnySelected = selectedTimeSlot.includes(timeSlots[0].id);

    if (id === timeSlots[0].id) {
      setValue(
        "selectedTimeSlot",
        isAnySelected ? [] : timeSlots.map((timeslot) => timeslot.id),
      );
    } else {
      setValue(
        "selectedTimeSlot",
        isAnySelected || selectedTimeSlot.includes(id)
          ? selectedTimeSlot.filter(
              (timeSlotDetail) =>
                ![id, timeSlots[0].id].includes(timeSlotDetail),
            )
          : [...selectedTimeSlot, id],
      );
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setValue(name, value);
  };

  const validateFields = async () => {
    const error = await formMethods.trigger();
    return !error;
  };

  const updateWaitlistDetails = async (data) => {
    let ccTokenError = false;
    if (isUpdatingWaitlistDetails) {
      return false;
    }

    const formData = {
      "cc-check": 0,
      id: waitlistDetails.id,
      patient_id: patient.id,
      patient_email: patient.email,
      patient_phone: patient.phoneNumber,
      patient_name: patient.full_name,
      days: selectedDays,
      months: selectedMonth.find(
        (monthDetails) => monthDetails.id === WAITLIST_ANY_MONTH.id,
      )
        ? WAIT_LIST_MONTHS.filter(
            (monthDetails) => monthDetails.id !== WAITLIST_ANY_MONTH.id,
          ).map((monthDetails) => monthDetails.id)
        : selectedMonth.map((monthDetails) => monthDetails.id),
      years: selectedYear ? [selectedYear.id] : [],
      timeslot_ids: selectedTimeSlot,
      clinic_ids: data.selectedClinics.map(
        (selectedClinicDetails) => selectedClinicDetails.id,
      ),
      provider_ids: data.selectedProviders.map(
        (selectedProviderDetails) => selectedProviderDetails.id,
      ),
      services: data.selectedServices.map(
        (selectedServicesDetails) => selectedServicesDetails.id,
      ),
      appointment_notes: waitlistNotes,
    };

    if (
      checkCC &&
      (!cardNumberOnFile || (cardNumberOnFile && changeCardNumber))
    ) {
      if (pos_gateway === "stripe") {
        if (stripeIntent && stripeIntent.setupIntentId) {
          formData.setup_intent_id = stripeIntent.setupIntentId;
          formData["cc-check"] = 1;
        }
      } else {
        // Clearent
        let cardDataToken = await getClearentToken();

        if (cardDataToken) {
          formData.token_id = cardDataToken;
          formData.clearent_email = clearentEmail;
          formData.clearent_zip = clearentZipCode;
          formData["cc-check"] = 1;
        } else {
          ccTokenError = true;
          uiNotification.error("Invalid Card Details.");
        }
      }
    }

    if (!ccTokenError) {
      updateWaitlist(formData, {
        onSuccess: onClose,
      });
    }
  };

  const toggleConvertWarning = useCallback(() => {
    if (!openConvertWarningModal.isOpen) {
      const formData = watch();
      const { id, patient } = waitlistDetails;
      setConverWarningModal({
        isOpen: true,
        data: {
          id,
          patient,
          appointment_notes: waitlistNotes,
          appointment_wait_list_services: formData.selectedServices.map(
            (serviceDetails) => ({
              service: { id: serviceDetails.id, name: serviceDetails.name },
            }),
          ),
          selectedClinics: formData.selectedClinics,
          selectedProviders: formData.selectedProviders,
        },
      });
    } else {
      setConverWarningModal({ isOpen: false, data: null });
    }
  }, [setConverWarningModal, watch, waitlistDetails, openConvertWarningModal]);

  const convertToAppointment = async () => {
    if (isUpdatingWaitlistDetails) {
      return false;
    }
    await clearSetupIntent();

    const isValidData = await formMethods.trigger();

    const data = watch();

    if (isValidData) {
      if (
        !(
          data.selectedClinics.length === 1 &&
          data.selectedProviders.length === 1
        )
      ) {
        toggleConvertWarning();
        return false;
      }

      const formData = {
        clinic_id: data.selectedClinics[0].id,
        provider_id: data.selectedProviders[0].id,
      };

      const servicesOffered = await GeServicesList({
        providerIds: [formData.provider_id],
        clinicIds: [formData.clinic_id],
      });

      if (servicesOffered) {
        const serviceDictonary = Object.assign(
          {},
          ...servicesOffered.data?.data?.services?.map((serviceDetails) => ({
            [serviceDetails.id]: serviceDetails,
          })),
        );

        const selectedServicesTypes =
          data?.selectedServices?.map(
            ({ id }) => serviceDictonary[id].service_type,
          ) || [];

        if (
          selectedServicesTypes.includes("in_person") &&
          selectedServicesTypes.includes("virtual")
        ) {
          return uiNotification.error(
            "You can't mix in-person and virtual services.",
          );
        }

        history.push(`/appointment/create/${formData.provider_id}`, {
          patient,
          id: waitlistDetails.id,
          appointment_wait_list_clinic: data.selectedClinics[0].id,
          appointment_notes: waitlistNotes,
          appointment_wait_list_services: data.selectedServices.map(
            (serviceId) => ({
              id: serviceId.id,
              name: serviceDictonary[serviceId.id].name,
              duration: serviceDictonary[serviceId.id].duration,
              isFree: Boolean(serviceDictonary[serviceId.id].is_service_free),
              price: parseFloat(serviceDictonary[serviceId.id].price),
              downtimes: serviceId.downtimes || [],
              serviceType: serviceDictonary[serviceId.id].service_type,
            }),
          ),
        });
      }
    } else {
      setValue("editServicesStatus", true);
    }
  };

  const clientDetails = useMemo(() => {
    const subscriptionColors =
      patient.patient_membership_subscription?.map((subscriptionDetails) => ({
        id: subscriptionDetails.id,
        color: subscriptionDetails.membership_tier
          ? subscriptionDetails.membership_tier.membership_color
          : "",
      })) || [];

    return (
      <div className="client-details-with-membership-badge">
        {patient.full_name}&nbsp;
        <>
          {subscriptionColors.map((subscriptionColorDetails) => {
            const { id, color } = subscriptionColorDetails;
            return (
              <MembershipBadge
                color={color}
                displayPage="client-subscription-badge"
                key={id}
              />
            );
          })}
        </>
      </div>
    );
  }, [patient.id]);

  const onMonthsChange = (nextMonths) => {
    if (
      nextMonths.find((monthData) => monthData.id === WAITLIST_ANY_MONTH.id)
    ) {
      setValue("selectedMonth", [WAITLIST_ANY_MONTH]);
    } else {
      setValue("selectedMonth", nextMonths);
    }
  };

  const allMonthsOptions = useMemo(
    () =>
      selectedMonth.find(
        (selectedMonthDetail) =>
          selectedMonthDetail.id === WAITLIST_ANY_MONTH.id,
      )
        ? [WAITLIST_ANY_MONTH]
        : WAIT_LIST_MONTHS,
    [selectedMonth],
  );

  const visibleMonthsOption = useMemo(
    () =>
      validateWaitListMonths({
        months: allMonthsOptions,
        years: selectedYear,
      }).map((i) => ({
        label: i.name,
        value: i.id,
      })),
    [allMonthsOptions, selectedYear],
  );

  const onYearsChange = (nextYears) => {
    setValue("selectedYear", nextYears);
    setValue(
      "selectedMonth",
      validateWaitListMonths({ months: selectedMonth, years: nextYears }),
    );
  };

  useEffect(() => {
    clearSetupIntent();
  }, []);

  return (
    <div className="add-edit-waitlist-modal">
      <CommonDialog title="Waitlist Details" onClose={onClose} className="">
        {openConvertWarningModal.isOpen && (
          <ConvertToAppointmentModal
            isOpen={openConvertWarningModal.isOpen}
            onCancel={toggleConvertWarning}
            data={openConvertWarningModal.data}
            history={history}
          />
        )}
        <form
          onSubmit={
            stripeIntent && stripeIntent.setupIntentId
              ? ""
              : handleSubmit(updateWaitlistDetails)
          }
        >
          <div>
            <FormProvider {...formMethods}>
              <div className="waitlist-user-details">
                <FieldDisplay
                  name={tCommon("label.patient")}
                  value={clientDetails}
                />
                <hr />

                <ClinicsProvidersServices
                  appointment_wait_list_providers={
                    appointment_wait_list_providers
                  }
                  appointment_wait_list_services={
                    appointment_wait_list_services
                  }
                  appointment_wait_list_clinics={appointment_wait_list_clinics}
                />
              </div>

              <div className="waitlist-time-schedule-container">
                <div className="schedule-waitlist schedule-week-day">
                  <p className="schedule-title">
                    Day Preference:
                    {"selectedDays" in errors && selectedDays.length === 0 && (
                      <span className=" no-padding required error-message">
                        Required
                      </span>
                    )}
                  </p>
                  <div className="schedule-slots">
                    <ul className="day-preference-slots">
                      {daysOfWeek.map((weekDayDetails) => {
                        const { name, id } = weekDayDetails;
                        const isDaySelected = selectedDays.includes(id);
                        return (
                          <span
                            className="items-center gap-3 cursor-pointer"
                            onClick={() => handleToggleWeekDay(id)}
                            key={`weekday-preference-${id}`}
                          >
                            <input
                              type="checkbox"
                              name={name}
                              checked={isDaySelected}
                              className="filled-checkbox"
                            />
                            <label>{name}</label>
                          </span>
                        );
                      })}
                    </ul>
                  </div>
                </div>

                <div className=" schedule-waitlist  schedule-month">
                  <p className="schedule-title">Month and Year Preference:</p>
                  <div className="schedule-slots row mx-0">
                    {/* Month Preference */}
                    <div className="col-md-6 col-xs-12 p-l-0">
                      <Select
                        isError={Boolean(errors.selectedMonth)}
                        value={selectedMonth.map((i) => ({
                          value: i.id,
                          label: i.name,
                        }))}
                        onChange={(nextMonths) => {
                          onMonthsChange(
                            nextMonths.map((monthData) => ({
                              id: monthData.value,
                              name: monthData.label,
                            })),
                          );
                        }}
                        options={visibleMonthsOption}
                        isSearchable
                        isMulti
                        placeholder="Select month"
                        name="months"
                        size="small"
                      />
                    </div>
                    {/* Year Preference */}
                    <div className="col-md-6 col-xs-12 p-l-0">
                      <Select
                        value={
                          selectedYear
                            ? {
                                value: selectedYear.id,
                                label: selectedYear.name,
                              }
                            : undefined
                        }
                        onChange={(nextValues) => {
                          onYearsChange({
                            id: nextValues.value,
                            name: nextValues.label,
                          });
                        }}
                        options={WAIT_LIST_YEARS.map((yearDetail) => ({
                          value: yearDetail.id,
                          label: yearDetail.name,
                        }))}
                        isSearchable
                        placeholder="Select year"
                        name="years"
                        size="small"
                      />
                    </div>
                  </div>
                </div>

                <div className="schedule-waitlist schedule-time-slot">
                  <p className="schedule-title">
                    Time Preference:
                    {"selectedTimeSlot" in errors &&
                      selectedTimeSlot.length === 0 && (
                        <span className=" no-padding required error-message">
                          Required
                        </span>
                      )}
                  </p>
                  <div className="schedule-slots row mx-0">
                    {/* Time slots preferences */}
                    {timeSlots.map((timeSlotDetails) => {
                      const { name, id } = timeSlotDetails;
                      const isTimeSlotSelected = selectedTimeSlot.includes(id);
                      return (
                        <div
                          className="col-sm-6 col-xs-12 p-l-0 time-slot-option"
                          key={`time-slot-option-${id}`}
                        >
                          <span
                            className="items-center gap-3 cursor-pointer"
                            style={{ paddingLeft: "2px" }}
                            onClick={() => {
                              handleToggleTimeSlot(id);
                            }}
                          >
                            <input
                              type="checkbox"
                              name={name}
                              checked={isTimeSlotSelected}
                              className="filled-checkbox"
                            />
                            <label>{name}</label>
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="schedule-waitlist notes">
                  <p className="schedule-title">Notes:</p>
                  <div className="schedule-slots row">
                    <div className="col-xs-12 p-l-0">
                      <textarea
                        className="simpleTextarea"
                        row="3"
                        name="waitlistNotes"
                        value={waitlistNotes}
                        onChange={handleInputChange}
                      ></textarea>
                    </div>
                  </div>
                </div>

                <CCDetails
                  posGateway={pos_gateway}
                  stripeCardNumberRef={stripeCardNumberRef}
                  stripeInstance={stripeInstance}
                  patientId={patient.id}
                  handleSave={handleSubmit(updateWaitlistDetails)}
                  handleConvert={convertToAppointment}
                  validation={validateFields}
                />

                {stripeIntent && !stripeIntent.setupIntentId && (
                  <div className="col-xs-12">
                    <button
                      type="submit"
                      className="btn btn-primary new-blue-btn pull-right"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      onClick={convertToAppointment}
                      className="btn btn-primary new-blue-btn m-r-15 pull-right"
                    >
                      Convert to Appointment
                    </button>
                  </div>
                )}
              </div>
            </FormProvider>
          </div>
        </form>
      </CommonDialog>
    </div>
  );
};

const WaitlistEditModal = (props) => {
  const {
    id,
    data: { providers },
  } = props;

  const { data: waitlistDetails, isSuccess = false } =
    useGetWaitlistDetailsQuery(id);

  return isSuccess && id ? (
    <AddEditWaitlistAppointment
      {...props}
      waitlistDetails={
        waitlistDetails.data?.data || {
          appointment_notes: "",
          appointment_wait_list_services: [],
          appointment_wait_list_clinics: [],
          patient: {},
          appointment_wait_list_days: [],
          appointment_wait_list_years: [],
          appointment_wait_list_months: [],
          appointment_wait_list_timeslots: [],
        }
      }
      appointment_wait_list_providers={providers}
    />
  ) : null;
};

const mapStateToProps = (state) => ({
  stripeIntent: state.StripeReducer.stripeIntent,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearSetupIntent,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(WaitlistEditModal));
