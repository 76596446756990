import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { packedListOr, passOr, unwrapOr } from "../../utilities/general";
import { CURRENCY, CURRENCY_SYMBOL } from "../../consts/general";

export const prepareRedemptionCode = (code) => {
  return code.replace(/\D/g, "");
};

const requestSchema = yup.object({
  invoiceId: yup.number().required(),
});

const responseSchema = yup.object({
  id: yup.number().required(),

  totalAmount: yup.number().required(),

  subtotalAmount: yup.number().required(),

  tipAmount: yup.number().required(),

  currency: yup.string().required(),

  currencySymbol: yup.string().required(),

  invoiceNumber: yup.string().required(),

  status: yup.string().required(),

  createdAt: yup.string().required(),

  totalTax: yup.number().required(),

  eGiftCardAmount: yup.number().required(),

  prePaymentAdjustment: yup.number().required(),

  paymentSignature: yup.string().nullable(),

  isMerger: yup.bool().required(),

  packageProductData: yup.mixed().nullable(),

  discounts: yup
    .object({
      bd: yup.number().required(),
      aspire: yup.number().required(),
      xperience: yup.number().required(),
      aspireCertificates: yup.number().required(),
      evolus: yup.number().required(),
      custom: yup.number().required(),
      total: yup.number().required(),
      package: yup
        .object({
          value: yup.number().required(),
          name: yup.string().nullable(),
        })
        .required(),
    })
    .required(),

  patient: yup
    .object({
      id: yup.number().required(),
      fullName: yup.string().required(),
      email: yup.string().nullable(),
      phoneNumber: yup.string().nullable(),
      walletBalance: yup.number().required(),
      cherryPatientId: yup.string().nullable(),
      pinCode: yup.string().nullable(),
      cardOnFiles: yup
        .array()
        .of(
          yup.object({
            id: yup.number().required(),
            number: yup.string().required(),
            billingZip: yup.string().nullable(),
          }),
        )
        .required(),
    })
    .nullable(),

  user: yup
    .object({
      id: yup.number().required(),
      fullName: yup.string().required(),
      roleName: yup.string().required(),
    })
    .nullable(),

  invoiceItems: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        productType: yup.string().nullable(),
        customProductName: yup.string().nullable(),
        productUnits: yup.number().required(),
        totalProductPrice: yup.number().required(),
        perUnitDiscountText: yup.string().nullable(),
        discountPackage: yup
          .object({
            name: yup.string(),
            quantity: yup.number().required(),
            products: yup.mixed().nullable(),
          })
          .nullable(),
        product: yup
          .object({
            id: yup.number().required(),
            name: yup.string().required(),
          })
          .nullable(),
      }),
    )
    .required(),

  clinic: yup
    .object({
      id: yup.number().required(),
      publicKey: yup.string().nullable(),
    })
    .nullable(),

  paymentTransaction: yup
    .object({
      id: yup.number().required(),
      status: yup.string().nullable(),
      mode: yup.string().nullable(),
      totalAmount: yup.number().required(),
      payments: yup
        .array()
        .of(
          yup.object({
            id: yup.number().required(),
            mode: yup.string().nullable(),
            status: yup.string().nullable(),
            card: yup
              .object({
                type: yup.string().nullable(),
                number: yup.string().nullable(),
              })
              .required(),
            chequeNumber: yup.string().nullable(),
            amount: yup.number().required(),
          }),
        )
        .required(),
    })
    .nullable(),

  redeemedGiftCards: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        availableAmount: yup.number().required(),
        redeemedAmount: yup.number().required(),
        redemptionCode: yup.string().required(),
      }),
    )
    .required(),

  mergedInvoices: yup
    .array()
    .of(
      yup.object({
        id: yup.number().required(),
        tipAmount: yup.number().required(),
        totalAmount: yup.number().required(),
        procedure: yup
          .object({
            doctorName: yup.string().nullable(),
          })
          .nullable(),
        user: yup
          .object({
            fullName: yup.string().nullable(),
          })
          .nullable(),
      }),
    )
    .required(),
});

const composeResponse = (res) => {
  const details = unwrapOr(() => res.data.data, {});
  const posData = unwrapOr(() => res.data.global_settings.pos_data, {});

  return {
    id: details.id,
    totalAmount: details.total_amount || 0,
    subtotalAmount: details.sub_total || 0,
    tipAmount: details.tip_amount || 0,
    currency: posData.currency_code || CURRENCY.usd,
    currencySymbol: posData.currency_symbol || CURRENCY_SYMBOL.usd,
    invoiceNumber: details.invoice_number,
    status: details.invoice_status,
    createdAt: details.system_created_datetime,
    totalTax: details.total_tax || 0,
    eGiftCardAmount: details.egift_Card_amount || 0,
    prePaymentAdjustment: details.prepayment_adjustment || 0,
    paymentSignature: details.patient_signature || null,
    isMerger: details.is_merger || false,
    packageProductData: details.package_products_data || null,
    isTipsEnabled: details.is_tips_enabled,

    discounts: {
      bd: details.bd_discount || 0,
      aspire: details.aspire_discount || 0,
      xperience: details.xperience_discount || 0,
      aspireCertificates: details.aspire_certificates_discount || 0,
      evolus: details.evolus_discount || 0,
      custom: details.custom_discount || 0,
      total: details.total_discount || 0,
      package: {
        value: details.package_discount || 0,
        name: details.package_discount_data?.name || null,
      },
    },

    patient: passOr(details.patient, null, () => ({
      id: details.patient.id,
      fullName: details.patient.full_name,
      phoneNumber: details.patient.phoneNumber,
      email:
        [
          details.patient.email,
          details.patient.email_2,
          details.patient.email_3,
          details.patient.email_4,
        ].find(Boolean) || null,
      walletBalance: details.patient.client_wallet?.dollar_credit || 0,
      cherryPatientId: details.patient.cherry_patient_id || null,
      pinCode: details.patient.pincode || null,

      cardOnFiles: packedListOr(details.patient?.card_on_files, []).map(
        (c) => ({
          id: c.id,
          number: c.card_number,
          billingZip: c.billing_zip || null,
          cardOnFile: c.card_on_file || null,
        }),
      ),
    })),

    procedure: passOr(!details.is_merger && details.procedure, null, () => ({
      doctorName: details.procedure.doctor_name,
    })),

    user: passOr(details.user, null, () => ({
      id: details.user.id,
      fullName: details.user.full_name,
      roleName: details.user.role_name,
    })),

    invoiceItems: (details.pos_invoice_items || []).map((i) => ({
      id: i.id,
      productType: i.product_type || null,
      customProductName: i.custom_product_name || null,
      productUnits: i.product_units || 0,
      totalProductPrice: i.total_product_price || 0,
      perUnitDiscountText: i.per_unit_discount_text || null,
      discountPackage: passOr(i.discount_package, null, () => ({
        name: i.discount_package.name,
        quantity: i.discount_package.bogo_product_quantity,
        products: i.discount_package.products_given || [],
      })),
      product: passOr(i.product, null, () => ({
        id: i.product.id,
        name: i.product.product_name,
      })),
    })),

    clinic: passOr(details.clinic, null, () => ({
      id: details.clinic.id,
      publicKey: details.clinic.publickey || null,
    })),

    paymentTransaction: passOr(details.pos_transaction, null, () => ({
      id: details.pos_transaction.id,
      status: details.pos_transaction.payment_status || null,
      mode: details.pos_transaction.payment_mode || null,
      totalAmount: details.pos_transaction.total_amount || 0,
      payments: (details.pos_transaction.pos_transactions_payments || []).map(
        (p) => ({
          id: p.id,
          mode: p.payment_mode || null,
          status: p.payment_status || null,
          card: {
            type: p.cc_type || null,
            number: p.cc_number || null,
          },
          gift_card: p?.gift_card || null,
          chequeNumber: p.cheque_no || null,
          amount: p.total_amount || 0,
        }),
      ),
    })),

    redeemedGiftCards: (details?.redeemed_gift_cards || []).map((c) => ({
      id: c.id,
      availableAmount: c.amount || 0,
      redeemedAmount: c.redeemed_Amount || 0,
      redemptionCode: prepareRedemptionCode(c.redemption_code),
    })),

    mergedInvoices: (details?.merged_invoices || []).map((i) => ({
      id: i.id,
      tipAmount: i.tip_amount || 0,
      totalAmount: i.system_total_amount || 0,
      isTipsEnabled: i.is_tips_enabled,
      procedure: passOr(i?.procedure, null, () => ({
        doctorName: i.procedure?.doctor_name || null,
      })),
      user: passOr(i?.user, null, () => ({
        fullName: i.user?.full_name,
      })),
      subtotalAmount: i.sub_total || 0,
    })),
    hasRmdRedemptions: details.hasRmdRedemptions,
    additionalCharges: details.additional_charges || 0,
    customTax: details.custom_tax || 0,
    hasJoyaRedemptions: details.has_joya_redemptions,
  };
};

function createInvoiceQueryKey({ invoiceId }) {
  return [QUERY_KEYS.invoice, invoiceId];
}

export function useInvoiceQuery({ payload = {}, options = {} } = {}) {
  const req = requestSchema.validateSync(payload, {
    strict: true,
  });
  return useQuery(
    createInvoiceQueryKey({ invoiceId: req.invoiceId }),
    async () => {
      const res = await http.get(HTTP_ENDPOINTS.getInvoice(req.invoiceId));
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
