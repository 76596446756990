import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { http } from "../../../services/HttpService";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../../consts/api";

const requestSchema = yup.object({
  domainId: yup.number().required(),
});

const responseSchema = yup.object({
  id: yup.number().required(),
  domain: yup.string().required(),
  status: yup.string().required(),
  records: yup.object({
    cname: yup.array().of(
      yup.object({
        cname: yup.string().required(),
        cnameTarget: yup.string().required(),
      }),
    ),
    txt: yup.array().of(
      yup.object({
        name: yup.string().required(),
        value: yup.string().required(),
      }),
    ),
  }),
});

const composeResponse = (res) => {
  const data = res?.data?.data;

  return {
    id: data?.custom_domain?.id,
    domain: data?.custom_domain?.domain,
    status: data?.custom_domain?.status,
    records: {
      cname: [
        {
          cname: data?.records?.cname?.[0]?.cname,
          cnameTarget: data?.records?.cname?.[0]?.cname_target,
        },
      ],
      txt: data?.records?.txt,
    },
  };
};

export function useGetDomainInfoQuery(payload = {}, options = {}) {
  const domainData = useQuery({
    queryKey: [QUERY_KEYS.customDomains.customDomain, payload.domainId],
    queryFn: async () => {
      const req = requestSchema.validateSync(payload, { strict: true });

      const res = await http.get(
        HTTP_ENDPOINTS.customDomain.getCustomDomain(req.domainId),
      );

      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  });

  return {
    data: domainData.data,
    isLoading: domainData.isLoading,
    refetch: domainData.refetch,
  };
}
