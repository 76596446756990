import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { http } from "../../../services/HttpService";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../../consts/api";

const responseSchema = yup.object({
  customDomainStatus: yup.number().required(),
});

const composeResponse = (res) => {
  const data = res.data?.data;

  return {
    customDomainStatus: data?.use_custom_pp_domain,
  };
};

export function useGetCustomDomainUseStatusQuery({ onError, onSuccess } = {}) {
  return useQuery({
    queryKey: [QUERY_KEYS.customDomains.customDomainUseStatus],
    queryFn: async () => {
      const res = await http.get(
        HTTP_ENDPOINTS.customDomain.getCustomDomainUseStatus(),
      );

      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    onError: () => {
      onError?.();
    },
    onSuccess: () => {
      onSuccess?.();
    },
  });
}
