import { useMemo } from "react";
import { useMembershipTiersQuery } from "../../../../api/queries/useMembershipTiersQuery";
import { sortAlphabetically } from "../../../../utilities/general";

export const DEFAULT_MEMBERSHIP = {
  label: "None",
  value: 0,
};

export function useMemberships() {
  const { data, isLoading } = useMembershipTiersQuery();

  const preparedData = useMemo(() => {
    const memberships =
      data
        ?.filter((x) => x.active)
        ?.map((x) => ({
          label: x.name,
          value: x.id,
        })) || [];

    memberships.sort(sortAlphabetically("label"));

    return [DEFAULT_MEMBERSHIP, ...memberships];
  }, [data]);

  return {
    data: preparedData,
    isLoading,
  };
}
