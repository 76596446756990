import React, { useState } from "react";
import { connect } from "react-redux";
import { productPricingRegular } from "../../../../../../../store/inventory/productPricingRegular";
import { validateChangedPricesForRequest } from "../../../../../../../store/inventory/productPricingRegular/utilities";
import { useUpdate } from "../../hooks/useUpdate";
import { uiNotification } from "../../../../../../../services/UINotificationService";

var { selectors } = productPricingRegular;

const mapStateToProps = (state) => ({
  forAllClinics: selectors.selectForAllClinics(state),
  getChangedPricesForRequest: selectors.selectChangedPricesForRequest(state),
});

export var withSave = (Component) =>
  connect(mapStateToProps)((props) => {
    var { getChangedPricesForRequest, forAllClinics, ...restProps } = props;

    var [perClinicErrors, setPerClinicErrors] = useState(new Map());
    var [perUnitError, setPerUnitError] = useState(false);

    var updatePriceList = useUpdate();

    var onSave = async (productId, showNotification = true) => {
      setPerUnitError(false);
      setPerClinicErrors(new Map());
      const requestChangedPrices = getChangedPricesForRequest();

      const errors = validateChangedPricesForRequest({
        requestChangedPrices,
        forAllClinics,
      });

      if (!errors.perUnitError && errors.perClinicErrors.length === 0) {
        return await updatePriceList.initiate({
          productId: Number(productId),
          priceList: requestChangedPrices,
          isRegularPrice: true,
          isForAllClinics: forAllClinics,
          showNotification,
        });
      } else {
        setPerUnitError(errors.perUnitError);
        setPerClinicErrors(
          new Map(errors.perClinicErrors.map((clinicId) => [clinicId, true])),
        );
        uiNotification.error(
          "Please fill all the required fields in Regular Pricing tab",
        );
      }
    };

    return (
      <Component
        {...restProps}
        saveRegularPrices={{
          initiate: onSave,
          isLoading: updatePriceList.isLoading,
          perUnitError,
          setPerUnitError,
          perClinicErrors,
          setPerClinicErrors,
        }}
      />
    );
  });
