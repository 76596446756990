/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { SketchField, Tools } from "react-sketch";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  fetchMDRoomData,
  signProcedure,
  exportEmptyData,
} from "../../Actions/Settings/settingsActions.js";
import {
  numberFormat,
  showFormattedDate,
  isNumber,
  autoScrolling,
  isFormSubmit,
} from "../../Utils/services.js";
import { history } from "../../history.js";
import { ROUTES } from "../../consts/routes.js";
import { PATIENT_CONSENTS_ENTITY_TYPES } from "../../api/consents/patientConsents/usePatientConsentsQuery.js";
import { PATIENT_QUESTIONNAIRE_ENTITY_TYPES } from "../../api/questionnaires/patientQuestionnaires/usePatientQuestionnairesQuery.js";
import { fetchClinics } from "../../Actions/Cardreaders/cardreadersAction.js";
import ReactSelect from "../Common/ReactSelect.js";
import { tCommon, AppTranslation } from "../../i18n/useAppTranslation.js";
import { shortenClinicName } from "../../helpers/general";
import { http } from "../../services/HttpService.js";
import { uiNotification } from "../../services/UINotificationService.js";
import { providerRoom } from "../../store/providerRoom/index.js";

class ProcedureList extends Component {
  constructor(props) {
    super(props);

    window.scrollTo(0, 0);

    const userData = JSON.parse(localStorage.getItem("userData"));
    const languageData = JSON.parse(localStorage.getItem("languageData"));
    const historyState = this.props.history.location?.state;
    let showSigPopup = userData.user.show_signature_popup;
    let defTimeLine = localStorage.getItem("defTimeLine");

    if (!defTimeLine) {
      if (userData) {
        defTimeLine =
          userData.account_preference !== undefined &&
          userData.account_preference.default_template !== undefined
            ? userData.account_preference.default_template
            : "health";
      } else {
        defTimeLine = "health";
      }
      localStorage.setItem("defTimeLine", defTimeLine);
    }
    this.state = {
      loadMore: true,
      startFresh: true,
      showLoader: false,
      page: 1,
      pagesize: 15,
      term: "",
      hasMoreItems: true,
      next_page_url: "",
      procedure_name: "",
      procedure_date: "",
      consent_ids: "",
      patient: [],
      user: [],
      searchFunction: "",
      injection_array: [],
      user_changed: false,
      procedureData: {},
      MDRoomData: [],
      data: [],
      select_all_pending_procedures: false,
      childCheck: false,
      action: props.match.params.type,
      pendingProcedures: props.match.params.type === "pending" ? true : false,
      signedProcedures: props.match.params.type === "siged" ? true : false,
      total: "",
      selectVisible: "right-sign-btn",
      selectHide: "right-sign-btn no-display",
      spanHide: "search-text no-display",
      spanVisible: "search-text",
      hideCheckbox: "table-checkbox table-updated-td no-display ",
      showCheckbox: "table-checkbox table-updated-td ",
      hideHeading: "table-checkbox table-updated-th no-display ",
      showHeading: "table-checkbox table-updated-th  ",
      roomTextData: languageData.rooms,

      clinic_name: "",
      clinicId: historyState?.clinic_id || "",
      contact_no: "",
      address: "",
      clinic_business_hours: [],
      id: userData.user.id,
      tax: "",
      sortorder: "asc",
      scopes: "business_hours",
      tabClicked: false,
      totalChecked: 0,
      selected: [],
      selectAll: 0,

      showSignModal: false,
      canvasClass: "signature-box sig-div",
      inputOut: "input-outer",
      clearClass: "new-white-btn no-margin clear no-display",
      resetClass: "new-blue-btn reset no-display",
      changeClass: "new-blue-btn no-margin Change",
      uploadedSignature: "",
      uploadedSignature_url: "",

      save_sign: false,
      roomType: this.props.match.url.split("/")[1],
      globalLang: languageData.global,
      showLoadingText: false,
      falseCount: 0,
      showSigPopup: showSigPopup ? 1 : 0,
      defTimeLine: defTimeLine ? defTimeLine : "health",
      defTimeLineTabClicked: false,
      listFormData: null,
      chartMdId: historyState?.chartUserId,
      clinicList: [],
    };

    this.props.exportEmptyData({});
    localStorage.setItem("loadFresh", false);
    localStorage.setItem("sortOnly", false);
    window.onscroll = () => {
      const scrollTop = parseInt(
        Math.max(
          window.pageYOffset,
          document.documentElement.scrollTop,
          document.body.scrollTop,
        ),
      );
      if (
        document.documentElement.offsetHeight -
          (window.innerHeight + scrollTop) <=
          5 &&
        this.state.next_page_url != null
      ) {
        this.loadMore();
      }
    };
  }

  componentWillUnmount() {
    this.props.exportEmptyData({});
  }

  handleAnchor = (event) => {
    let action = event.target.dataset.action;
    if (this.props.history.location?.state) {
      const { clinic_id, isFromChartAuditReport, chartUserId } =
        this.props.history.location?.state;
      this.props.history.push({
        pathname: `/${this.state.roomType}/${action}`,
        state: { clinic_id, isFromChartAuditReport, chartUserId },
      });
    } else {
      this.props.history.push(`/${this.state.roomType}/${action}`);
    }

    if (this.state.action != action) {
      if (action === "pending") {
        let formData = {
          params: {
            page: 1,
            pagesize: this.state.pagesize,
            sortorder: "asc",
            term: "",
            action: "pending",
            procedure_type: this.state.defTimeLine,
            clinic_id: "",
            md_user_id: this.state.chartMdId,
          },
        };
        this.setState({
          tabClicked: true,
          pendingProcedures: true,
          signedProcedures: false,
          MDRoomData: [],
          startFresh: true,
          showLoader: true,
          page: 1,
          sortorder: "asc",
          term: "",
          next_page_url: "",
          action: "pending",
          listFormData: formData,
          clinicId: "",
        });
        autoScrolling(true);
        this.props.fetchMDRoomData(formData);
      } else {
        let formData = {
          params: {
            page: 1,
            pagesize: this.state.pagesize,
            sortorder: "asc",
            term: "",
            action: "signed",
            procedure_type: this.state.defTimeLine,
            clinic_id: "",
            md_user_id: this.state.chartMdId,
          },
        };
        this.setState({
          tabClicked: true,
          pendingProcedures: false,
          signedProcedures: true,
          MDRoomData: [],
          startFresh: true,
          showLoader: true,
          page: 1,
          sortorder: "asc",
          term: "",
          next_page_url: "",
          action: "signed",
          listFormData: formData,
          clinicId: "",
        });
        autoScrolling(true);
        this.props.fetchMDRoomData(formData);
      }
    }
  };

  componentDidMount() {
    let formData = {
      params: {
        page: this.state.page,
        pagesize: this.state.pagesize,
        sortorder: "asc",
        term: this.state.term,
        action: this.state.action,
        procedure_type: this.state.defTimeLine,
        clinic_id: this.state.clinicId,
        md_user_id: this.state.chartMdId,
      },
    };

    if (this.state.defTimeLine === "changes-needed") {
      formData.params.sub_action = "changes-needed";
    }

    if (this.state.defTimeLine === "changes-done") {
      formData.params.sub_action = "changes-done";
    }

    autoScrolling(true);
    this.props.fetchMDRoomData(formData);
    this.props.fetchClinics();

    this.setState({ showLoader: true, listFormData: formData });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;

    this.setState({
      [event.target.name]: value,
    });

    if (target.type === "checkbox") {
      let val = event.target.value;
      let idx = event.target.name.split("_")[1];
      this.updateSignList(Number(val));

      if (
        this.state.selected.length > 0 &&
        this.state.selected[idx] !== undefined
      ) {
        let newSelected = this.state.selected;
        let falseCount = this.state.falseCount;

        newSelected[[idx]][val] = !this.state.selected[[idx]][val];

        if (!newSelected[[idx]][val]) {
          falseCount = falseCount + 1;
        }

        if (falseCount === this.state.selected.length) {
          falseCount = 0;
        }

        this.setState({
          selected: newSelected,
          selectAll: 0,
          falseCount: falseCount,
        });
      } else {
        let newSelected = this.state.selected;
        let falseCount = this.state.falseCount;

        if (falseCount > 0) {
          falseCount = falseCount - 1;
        }

        let a = {};
        a[val] = true;

        newSelected[idx] = a;

        this.setState({
          selected: newSelected,
          selectAll: 0,
          falseCount: falseCount,
        });
      }
    }
  };

  changeTimelinePref = (type, id) => {
    localStorage.setItem("sortOnly", true);
    this.setState({ defTimeLine: type });
    localStorage.setItem("defTimeLine", type);

    let formData = {
      params: {
        page: 1,
        pagesize: this.state.pagesize,
        sortorder: "asc",
        term: "",
        action: this.state.action,
        procedure_type: type,
        clinic_id: id || "",
        md_user_id: this.state.chartMdId,
      },
    };

    if (type === "changes-needed") {
      formData.params.sub_action = "changes-needed";
    }

    if (type === "changes-done") {
      formData.params.sub_action = "changes-done";
    }

    this.setState({
      page: 1,
      pagesize: this.state.pagesize,
      sortorder: "asc",
      startFresh: true,
      loadMore: true,
      next_page_url: "",
      MDRoomData: [],
      term: "",
      defTimeLineTabClicked: true,
      clinicId: id || "",
    });
    this.setState({ showLoader: true });
    autoScrolling(true);
    this.props.fetchMDRoomData(formData);
  };

  handleSubmit = (event, defTimeLine) => {
    event.preventDefault();
    localStorage.setItem("sortOnly", true);
    let formData = {
      params: {
        page: 1,
        pagesize: this.state.pagesize,
        sortorder: this.state.sortorder,
        term: this.state.term,
        action: this.state.action,
        procedure_type: defTimeLine || this.state.defTimeLine,
        clinic_id: "",
        md_user_id: this.state.chartMdId,
      },
    };
    this.setState({
      page: 1,
      pagesize: this.state.pagesize,
      sortorder: this.state.sortorder == "asc" ? "desc" : "asc",
      startFresh: true,
      loadMore: true,
      next_page_url: "",
      MDRoomData: [],
      clinicId: "",
    });
    this.setState({ showLoader: true });
    autoScrolling(true);
    this.props.fetchMDRoomData(formData);
  };

  loadMore = () => {
    if (!autoScrolling()) {
      localStorage.setItem("sortOnly", false);
      this.setState({
        loadMore: true,
        startFresh: true,
        showLoader: false,
        showLoadingText: true,
      });
      let formData = {
        params: {
          page: this.state.page,
          pagesize: this.state.pagesize,
          sortorder:
            this.state.sortorder && this.state.sortorder === "asc"
              ? "asc"
              : this.state.sortorder == "desc"
              ? "desc"
              : "",
          term: this.state.term,
          action: this.state.action,
          procedure_type: this.state.defTimeLine,
          clinic_id: this.state.clinicId,
          md_user_id: this.state.chartMdId,
        },
      };
      autoScrolling(true);
      this.props.fetchMDRoomData(formData);
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.tabClicked === true) {
      return {
        tabClicked: false,
        MDRoomData: [],
      };
    }
    if (prevState.defTimeLineTabClicked === true) {
      return {
        page: 1,
        sortorder: "asc",
        startFresh: true,
        loadMore: true,
        next_page_url: "",
        MDRoomData: [],
        term: "",
        defTimeLineTabClicked: false,
      };
    }

    if (
      nextProps.showLoader === false &&
      nextProps.showLoaderTimeStamp !== prevState.showLoaderTimeStamp
    ) {
      if (nextProps.isReloadData) {
        let formData = prevState.listFormData;
        if (formData) {
          formData.term = prevState.term ? prevState.term : "";
        } else {
          formData = {
            params: {
              page: 1,
              pagesize: prevState.pagesize,
              sortorder:
                prevState.sortorder && prevState.sortorder === "asc"
                  ? "asc"
                  : prevState.sortorder == "desc"
                  ? "desc"
                  : "",
              term: prevState.term,
              action: prevState.action,
              procedure_type: prevState.defTimeLine,
              clinic_id: "",
              md_user_id: this.state.chartMdId,
            },
          };
        }
        nextProps.fetchMDRoomData(formData);
      } else {
        return { showLoader: false };
      }
    }
    if (
      nextProps.MDRoomData !== undefined &&
      nextProps.MDRoomData.procedures !== undefined &&
      (nextProps.MDRoomData.procedures.next_page_url !==
        prevState.next_page_url ||
        nextProps.MDRoomData.action !== prevState.action)
    ) {
      let returnState = {};
      if (nextProps.MDRoomData.login_user) {
        if (
          nextProps.MDRoomData.login_user.show_signature_popup !== undefined
        ) {
          returnState.showSigPopup = nextProps.MDRoomData.login_user
            .show_signature_popup
            ? 1
            : 0;
        }
      }
      if (
        prevState.next_page_url === null &&
        nextProps.MDRoomData.action === prevState.action
      ) {
        autoScrolling(false);
        return (returnState.next_page_url = null);
      }
      if (prevState?.clinicList?.length === 0) {
        returnState.clinicList = nextProps.clinics;
      }
      if (prevState.MDRoomData.length === 0 && prevState.startFresh === true) {
        if (localStorage.getItem("sortOnly") == "false") {
          returnState.showSignModal = false;
          returnState.canvasClass = nextProps.MDRoomData.login_user
            .signature_url
            ? "signature-box sig-div no-display"
            : "signature-box sig-div";
          returnState.inputOut = nextProps.MDRoomData.login_user.signature_url
            ? "input-outer"
            : "input-outer no-display";
          returnState.clearClass = nextProps.MDRoomData.login_user.signature_url
            ? "new-white-btn no-margin clear no-display"
            : "new-white-btn no-margin clear";
          returnState.resetClass = nextProps.MDRoomData.login_user.signature_url
            ? "new-blue-btn reset no-display"
            : "new-blue-btn reset ";
          returnState.changeClass = nextProps.MDRoomData.login_user
            .signature_url
            ? "new-blue-btn no-margin Change"
            : "new-blue-btn no-margin Change no-display";
          returnState.uploadedSignature = "";
          returnState.uploadedSignature_url = "";
          returnState.signature_url =
            nextProps.MDRoomData.login_user.signature_url;
          returnState.signature = nextProps.MDRoomData.login_user.signature;
          returnState.save_sign = false;

          returnState.MDRoomData = nextProps.MDRoomData.procedures.data;
          if (nextProps.MDRoomData.procedures.next_page_url != null) {
            returnState.page = 2;
          } else {
            returnState.next_page_url =
              nextProps.MDRoomData.procedures.next_page_url;
          }
          returnState.startFresh = false;
          returnState.showLoader = false;
          returnState.action = nextProps.MDRoomData.action;
          returnState.total = nextProps.MDRoomData.procedures.total;
          returnState.injection_array =
            nextProps.MDRoomData.procedures.injection_array;
          returnState.showLoadingText = false;
        } else {
          localStorage.setItem("sortOnly", false);
        }
      } else if (
        prevState.MDRoomData !== nextProps.MDRoomData.procedures.data &&
        prevState.MDRoomData.length !== 0
      ) {
        if (prevState.tabClicked === false) {
          returnState.MDRoomData = [
            ...prevState.MDRoomData,
            ...nextProps.MDRoomData.procedures.data,
          ];

          if (prevState.clinicList.length === 0) {
            returnState.clinicList = nextProps.clinics;
          }
          returnState.selectAll = 0;
        } else {
          returnState.tabClicked = false;
        }
        returnState.total = nextProps.MDRoomData.procedures.total;
        returnState.injection_array =
          nextProps.MDRoomData.procedures.injection_array;
        returnState.page = prevState.page + 1;
        returnState.next_page_url =
          nextProps.MDRoomData.procedures.next_page_url;
        returnState.action = nextProps.MDRoomData.action;
        returnState.showLoader = false;
        returnState.showSignModal = false;
        returnState.canvasClass = nextProps.MDRoomData.login_user.signature_url
          ? "signature-box sig-div no-display"
          : "signature-box sig-div";
        returnState.inputOut = nextProps.MDRoomData.login_user.signature_url
          ? "input-outer"
          : "input-outer no-display";
        returnState.clearClass = nextProps.MDRoomData.login_user.signature_url
          ? "new-white-btn no-margin clear no-display"
          : "new-white-btn no-margin clear";
        returnState.resetClass = nextProps.MDRoomData.login_user.signature_url
          ? "new-blue-btn reset no-display"
          : "new-blue-btn reset ";
        returnState.changeClass = nextProps.MDRoomData.login_user.signature_url
          ? "new-blue-btn no-margin Change"
          : "new-blue-btn no-margin Change no-display";

        returnState.uploadedSignature = "";
        returnState.uploadedSignature_url = "";
        returnState.signature_url =
          nextProps.MDRoomData.login_user.signature_url;
        returnState.signature = nextProps.MDRoomData.login_user.signature;
        returnState.save_sign = false;
        returnState.showLoadingText = false;
      }
      autoScrolling(false);
      return returnState;
    }

    return null;
  }

  userEdit = (id, type) => {
    let pathname;
    const pathHistoryState = this.props.history.location?.state;

    if (this.state.defTimeLine === "health" || type === "health") {
      pathname = `/${this.state.roomType}/procedure-health-detail/${id}/${this.state.action}`;
      return (
        <div>
          {pathHistoryState
            ? this.props.history.push({ pathname, state: pathHistoryState })
            : this.props.history.push(pathname)}
        </div>
      );
    } else {
      pathname = `/${this.state.roomType}/procedure-detail/${id}/${this.state.action}`;
      return (
        <div>
          {pathHistoryState
            ? this.props.history.push({ pathname, state: pathHistoryState })
            : this.props.history.push(pathname)}
        </div>
      );
    }
  };

  procedureQuestionnaireEdit = (id) => {
    if (this.props.history.location?.state?.isFromChartAuditReport) return;
    history.push(
      ROUTES.patientQuestionnaires.manyByEntity({
        type: PATIENT_QUESTIONNAIRE_ENTITY_TYPES.procedure,
        id,
      }),
    );
  };

  viewHealthQuestionnaires = (id) => {
    if (this.props.history.location?.state?.isFromChartAuditReport) return;
    if (this.state.action !== "hidden") {
      history.push(
        ROUTES.patientQuestionnaires.manyByEntity({
          type: PATIENT_QUESTIONNAIRE_ENTITY_TYPES.procedure,
          id,
        }),
      );
    }
  };

  procedurePrescriptionEdit = (id) => {
    if (this.props.history.location?.state?.isFromChartAuditReport) return;
    return (
      <div>
        {this.props.history.push(
          `/${this.state.roomType}/prescription/${id}/${this.state.action}`,
        )}
      </div>
    );
  };
  procedureConsentsEdit = (id) => {
    if (this.props.history.location?.state?.isFromChartAuditReport) return;
    history.push(
      ROUTES.patientConsents.manyByEntity({
        type: PATIENT_CONSENTS_ENTITY_TYPES.procedure,
        id,
      }),
    );
  };

  viewNotes = (obj) => {
    if (this.props.history.location?.state?.isFromChartAuditReport) return;
    if (obj && this.state.action !== "hidden") {
      return (
        <div>
          {this.props.history.push(
            `/${this.state.roomType}/notes/view/${obj.id}/${obj.patient_id}/${this.state.action}`,
          )}
        </div>
      );
    }
  };

  shouldComponentUpdate() {
    if (this.state.startFresh) {
      return true;
    }

    if (this.state.loadMore) {
      return true;
    }

    if (this.state.showLoader) {
      return true;
    }
    return false;
  }

  openSignModal = () => {
    let openModal = false;

    if (this.props?.signList?.length > 0) {
      openModal = true;
    }

    if (openModal === true) {
      if (!this.state.showSigPopup) {
        if (this.state.signature_url) {
          this.saveWithoutSign();
        } else {
          this.setState({ showSignModal: true });
        }
      } else {
        this.setState({ showSignModal: true });
      }
    }
  };

  saveWithoutSign = () => {
    let proIDArr = [];

    if (this.state.selected.length > 0) {
      this.state.selected.map((ob) => {
        for (let proID in ob) {
          if (ob[proID] === true) {
            proIDArr.push(proID);
          }
        }
      });
    }

    if (this.state.signature_url !== "") {
      let formData = {
        procedure_ids: proIDArr,
        signature_saved: this.state.save_sign ? 1 : 0,
        md_signature: this.state.signature,
      };

      let listData = {
        params: {
          page: 1,
          pagesize: this.state.pagesize,
          sortorder: "asc",
          term: this.state.term,
          action: this.props.match.params.type,
          procedure_type: this.state.defTimeLine,
          clinic_id: "",
          md_user_id: this.state.chartMdId,
        },
      };
      autoScrolling(true);
      this.props.signProcedure(
        formData,
        true,
        listData,
        this.state.roomType,
        "list",
      );

      this.setState({
        signature_url: this.state.signature_url,
        uploadedSignature_url: this.state.signature_url,
        uploadedSignature: this.state.signature,
        signature: this.state.signature,
        inputOut: "input-outer",
        canvasClass: "signature-box sig-div  no-display",
        clearClass: "new-white-btn no-margin clear no-display",
        resetClass: "new-blue-btn reset  no-display",
        changeClass: "new-blue-btn no-margin Change",
        showSignModal: false,
        tabClicked: true,
        pendingProcedures: true,
        signedProcedures: false,
        MDRoomData: [],
        startFresh: true,
        showLoader: true,
        page: 1,
        sortorder: "asc",
        term: "",
        next_page_url: "",
        selected: [],
      });
    }
  };

  checkAll = (item) => {
    return this.props.signList.includes(item.id);
  };

  removeAllChecked = () => {
    const baseArr = this.state.MDRoomData.map((item) => item.id);
    const filteredArr = this.props.signList.filter(
      (item) => !baseArr.includes(item),
    );
    this.props.fillSignlist(filteredArr);
  };

  toggleSelectAll = () => {
    const isAllSelected = this.state.MDRoomData.every(this.checkAll);

    if (isAllSelected) {
      this.removeAllChecked();
    } else {
      this.removeAllChecked();
      this.state.MDRoomData?.map((obj) =>
        this.props.addProcedureToList(obj.id),
      );
    }
  };

  dismissSignModal = () => {
    this.setState({ showSignModal: false });
  };

  handleClearReset = () => {
    this.setState({
      inputOut: "input-outer",
      canvasClass: "signature-box sig-div  no-display",
      clearClass: "new-white-btn no-margin clear no-display",
      resetClass: "new-blue-btn reset  no-display",
      changeClass: "new-blue-btn no-margin Change",
    });
  };

  clear = () => {
    this._sketch.clear();
    this._sketch.setBackgroundFromDataUrl("");
    this.setState({
      controlledValue: null,
      backgroundColor: "transparent",
      fillWithBackgroundColor: false,
      canUndo: this._sketch.canUndo(),
      canRedo: this._sketch.canRedo(),
    });
  };

  clearCanvas = () => {
    if (this._sketch) {
      this._sketch.clear();
      this._sketch.setBackgroundFromDataUrl("");
    }
    this.setState({
      canvasClass: "signature-box sig-div",
      inputOut: "input-outer no-display",
      clearClass: "new-white-btn no-margin clear",
      resetClass: "new-blue-btn reset ",
      changeClass: "new-blue-btn no-margin Change no-display",
    });
  };

  signThis = () => {
    if (isFormSubmit()) {
      if (
        (this._sketch &&
          this._sketch.toJSON().objects.length === 0 &&
          this.state.canvasClass.indexOf("no-display") === -1) ||
        (this.state.canvasClass.indexOf("no-display") > 0 &&
          this.state.signature_url === "")
      ) {
        uiNotification.clear();
        uiNotification.error(
          this.state.globalLang.validation_md_signature_required_if,
        );
      } else {
        this.setState({ showLoader: false });
        let proIDArr = [];

        if (this.state.selected.length > 0) {
          this.state.selected.map((ob) => {
            for (let proID in ob) {
              if (ob[proID] === true) {
                proIDArr.push(proID);
              }
            }
          });
        }

        if (
          this.state.signature_url !== "" &&
          this.state.canvasClass.indexOf("no-display") > 0
        ) {
          let formData = {
            procedure_ids: proIDArr,
            signature_saved: this.state.save_sign ? 1 : 0,
            md_signature: this.state.signature,
          };

          let listData = {
            params: {
              page: 1,
              pagesize: this.state.pagesize,
              sortorder: "asc",
              term: this.state.term,
              action: this.props.match.params.type,
              procedure_type: this.state.defTimeLine,
              clinic_id: "",
              md_user_id: this.state.chartMdId,
            },
          };
          autoScrolling(true);
          this.props.signProcedure(
            formData,
            true,
            listData,
            this.state.roomType,
            "list",
          );

          this.setState({
            signature_url: this.state.signature_url,
            uploadedSignature_url: this.state.signature_url,
            uploadedSignature: this.state.signature,
            signature: this.state.signature,
            inputOut: "input-outer",
            canvasClass: "signature-box sig-div  no-display",
            clearClass: "new-white-btn no-margin clear no-display",
            resetClass: "new-blue-btn reset  no-display",
            changeClass: "new-blue-btn no-margin Change",
            showSignModal: false,
            tabClicked: true,
            pendingProcedures: true,
            signedProcedures: false,
            MDRoomData: [],
            startFresh: true,
            showLoader: true,
            page: 1,
            sortorder: "asc",
            term: "",
            next_page_url: "",
            selected: [],
          });
        } else {
          http
            .post("upload/signature", {
              image_data: this._sketch.toDataURL(),
              upload_type: "signatures",
            })
            .then((response) => {
              if (response.data && response.data.status === 200) {
                let formData = {
                  procedure_ids: this?.props?.signList,
                  signature_saved: this.state.save_sign ? 1 : 0,
                  md_signature: response.data.data.file_name,
                };

                let listData = {
                  params: {
                    page: 1,
                    pagesize: this.state.pagesize,
                    sortorder: "asc",
                    term: this.state.term,
                    action: this.props.match.params.type,
                    procedure_type: this.state.defTimeLine,
                    clinic_id: "",
                    md_user_id: this.state.chartMdId,
                  },
                };
                autoScrolling(true);
                this.props.signProcedure(
                  formData,
                  true,
                  listData,
                  this.state.roomType,
                  "list",
                );

                this.setState({
                  signature_url: response.data.data.signature_url,
                  uploadedSignature_url: response.data.data.signature_url,
                  uploadedSignature: response.data.data.file_name,
                  signature: response.data.data.file_name,
                  inputOut: "input-outer",
                  canvasClass: "signature-box sig-div  no-display",
                  clearClass: "new-white-btn no-margin clear no-display",
                  resetClass: "new-blue-btn reset  no-display",
                  changeClass: "new-blue-btn no-margin Change",
                  showSignModal: false,
                  tabClicked: true,
                  pendingProcedures: true,
                  signedProcedures: false,
                  MDRoomData: [],
                  startFresh: true,
                  showLoader: true,
                  page: 1,
                  sortorder: "asc",
                  term: "",
                  next_page_url: "",
                  selected: [],
                });
              }
            })
            .catch(() => {
              uiNotification.clear();
              uiNotification.error(
                this.state.roomTextData.signature_upload_error_text,
              );
              this.setState({ showLoader: false });
            });
        }
      }
    }
  };

  handleClinicChange = ({ id }) => {
    this.changeTimelinePref(this.state.defTimeLine, id, this);
  };

  handlePendingAndSignedProducerCount = () => {
    return `${tCommon("label.mdRoomSignatureText")} ${
      this.state.action
    } ${tCommon("label.mdRoomSignatureProcedure")}`;
  };

  updateSignList = (procedureId) => {
    if (this.props.signList.includes(Number(procedureId))) {
      return this.props.removeFromSignList(procedureId);
    }
    this.props.addProcedureToList(procedureId);
  };

  render() {
    const showHideActionButton =
      this.state.action !== "signed" &&
      this.state.action === "pending" &&
      !this.props.history.location?.state?.isFromChartAuditReport;

    return (
      <div id="content" className="fullscreen">
        <div className="container-fluid content setting-wrapper">
          <div
            className={
              this.state.showSignModal
                ? "modalOverlay"
                : "modalOverlay no-display"
            }
          >
            <div className="small-popup-outer">
              <div className="small-popup-header">
                <div className="popup-name">
                  {this.state.roomTextData.md_dir_consent_text}
                </div>
                <a onClick={this.dismissSignModal} className="small-cross">
                  ×
                </a>
              </div>

              <div className="juvly-container">
                <div className="settings-subtitle signature-subtitle">
                  {this.state.roomTextData.please_sign_below_text}:
                </div>
                <div className={this.state.canvasClass} id="sig-div">
                  {this.state.showSignModal &&
                    this.state.canvasClass === "signature-box sig-div" && (
                      <SketchField
                        width="400px"
                        ref={(c) => (this._sketch = c)}
                        height="200px"
                        tool={Tools.Pencil}
                        lineColor="black"
                        lineWidth={6}
                      />
                    )}
                </div>
                <div className="img-src" id="img-src">
                  <div
                    className={this.state.inputOut}
                    style={{ background: "#fff none repeat scroll 0 0" }}
                  >
                    <img
                      alt=""
                      className=""
                      id="signature_image"
                      src={
                        this.state.uploadedSignature_url
                          ? this.state.uploadedSignature_url
                          : this.state.signature_url
                      }
                    />
                  </div>
                </div>

                <div className="right-sign-btn m-t-20">
                  <input
                    className="pull-left sel-all-visible"
                    type="checkbox"
                    name="save_sign"
                    autoComplete="off"
                    onChange={this.handleInputChange}
                  />
                  <label className="search-text" htmlFor="save_sign">
                    {" "}
                    {this.state.roomTextData.save_sig_text}
                  </label>
                </div>

                <div className="img-src change-sig">
                  <div className="pull-left">
                    <button
                      type="button"
                      id="change"
                      onClick={this.clearCanvas}
                      className={this.state.changeClass}
                    >
                      {this.state.roomTextData.btn_change_text}
                    </button>
                  </div>
                  <div className="pull-left">
                    <button
                      type="button"
                      id="change1"
                      onClick={this.clear}
                      className={this.state.clearClass}
                    >
                      {this.state.roomTextData.btn_clear_text}
                    </button>
                  </div>
                  <div className="pull-left">
                    <button
                      type="button"
                      id="change2"
                      onClick={this.handleClearReset}
                      className={this.state.resetClass}
                    >
                      {this.state.roomTextData.btn_reset_text}
                    </button>
                  </div>
                  <div className="pull-left"></div>
                </div>
              </div>
              <div className="footer-static">
                <a
                  id="saveConsultation"
                  onClick={this.signThis}
                  className="new-blue-btn pull-right"
                >
                  {this.state.roomTextData.sign_text}
                </a>
              </div>
            </div>
          </div>

          <ul className="sub-menu d-flex">
            <li>
              <a
                name="pendingProcedures"
                className={this.state.action === "pending" ? "active" : ""}
                data-action="pending"
                onClick={this.handleAnchor.bind(this)}
              >
                {this.state.roomTextData.pending_pro_text}
              </a>
            </li>
            <li>
              <a
                name="signedProcedures"
                className={this.state.action === "signed" ? "active" : ""}
                data-action="signed"
                onClick={this.handleAnchor.bind(this)}
              >
                {this.state.roomTextData.signed_pro_text}
              </a>
            </li>
            {this.props.history.location?.state && (
              <li className="md-room-back-div">
                <div className="d-flex back-arrow-md-room">
                  <i className="fa fa-angle-left"></i>
                  <Link
                    to="/business-insight/chart-audit"
                    className="md-room-link"
                  >
                    Go Back
                  </Link>
                </div>
              </li>
            )}
          </ul>

          <div className="juvly-section full-width">
            {this.state.showLoader === false && (
              <div className="setting-search-outer">
                <form onSubmit={this.handleSubmit}>
                  <div className="search-bg new-search">
                    <i className="fas fa-search" />
                    <input
                      name="term"
                      className="setting-search-input chart_search"
                      placeholder={this.state.roomTextData.search_text}
                      autoComplete="off"
                      value={this.state.term}
                      onChange={this.handleInputChange}
                    />
                  </div>
                </form>

                <span className={this.state.spanVisible}>
                  {this.handlePendingAndSignedProducerCount()} (
                  {this.state.total})
                </span>
                <div
                  className={
                    showHideActionButton
                      ? this.state.selectVisible
                      : "hide-button-show-drowpdown-div"
                  }
                >
                  {this.state.clinicList?.length > 0 && (
                    <div className="col-lg-6 col-md-8 col-sm-6">
                      <ReactSelect
                        name={"id"}
                        selectedId={this.state.clinicId}
                        list={[
                          { clinic_name: "All Clinics", id: "" },
                          ...this.state.clinicList,
                        ]?.map((i) => ({
                          value: i.id,
                          label: shortenClinicName(i.clinic_name),
                        }))}
                        value={"value"}
                        label={"label"}
                        handleReactSelect={this.handleClinicChange}
                        isValid={true}
                        placeholder={"Select Clinic"}
                        isMulti={false}
                      />
                    </div>
                  )}
                  {showHideActionButton && (
                    <>
                      {this.state.defTimeLine !== "changes-needed" && (
                        <>
                          <input
                            type="checkbox"
                            className="checkbox pull-left sel-all-visible"
                            checked={this.state.MDRoomData.every(this.checkAll)}
                            onChange={() => this.toggleSelectAll()}
                          />
                          <label
                            className="search-text col-lg-3 col-md-6 col-sm-5"
                            htmlFor="select_all_pending_procedures"
                          >
                            {this.state.roomTextData.select_all_text}
                          </label>
                        </>
                      )}
                      {this.state.defTimeLine !== "changes-needed" && (
                        <button
                          type="submit"
                          onClick={this.openSignModal}
                          className={
                            (this.state.defTimeLine === "health" &&
                              this.props?.signList?.length > 0) ||
                            (this.state.defTimeLine === "cosmetic" &&
                              this.props?.signList?.length > 0) ||
                            (this.state.defTimeLine === "changes-done" &&
                              this.props?.signList?.length > 0)
                              ? "new-blue-btn pull-right consent-model m-r-0"
                              : "new-blue-btn pull-right consent-model disable m-r-0"
                          }
                          id="sign"
                        >
                          {this.state.roomTextData.sign_text}
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
            <ul className="section-tab-outer submenuWithSearch">
              {" "}
              <li key={1}>
                <a
                  href="javascript:void(0);"
                  onClick={this.changeTimelinePref.bind(this, "cosmetic", null)}
                  id="cosmetic_tab"
                  className={
                    this.state.defTimeLine === "cosmetic"
                      ? "section-title-name r section-tab sel-merge-tab"
                      : "section-title-name r section-tab"
                  }
                  data-title="Cosmetic"
                >
                  {this.state.roomTextData.room_cosmetic_timeline}
                </a>
              </li>
              <li key={2}>
                <a
                  href="javascript:void(0);"
                  onClick={this.changeTimelinePref.bind(this, "health", null)}
                  id="health_tab"
                  className={
                    this.state.defTimeLine === "health"
                      ? "section-title-name r section-tab sel-merge-tab"
                      : "section-title-name r section-tab"
                  }
                  data-title="Health"
                >
                  {this.state.roomTextData.room_health_timeline}
                </a>
              </li>
              {this.state.pendingProcedures && (
                <li key={3}>
                  <a
                    href="javascript:void(0);"
                    onClick={this.changeTimelinePref.bind(
                      this,
                      "changes-needed",
                      null,
                    )}
                    id="str_tab"
                    className={
                      this.state.defTimeLine === "changes-needed"
                        ? "section-title-name r section-tab sel-merge-tab"
                        : "section-title-name r section-tab"
                    }
                    data-title="senToReview"
                  >
                    <AppTranslation.ProviderRoom path="tabs.sentToReview" />
                  </a>
                </li>
              )}
              {this.state.pendingProcedures && (
                <li key={4}>
                  <a
                    href="javascript:void(0);"
                    onClick={this.changeTimelinePref.bind(
                      this,
                      "changes-done",
                      null,
                    )}
                    id="tbp_tab"
                    className={
                      this.state.defTimeLine === "changes-done"
                        ? "section-title-name r section-tab sel-merge-tab"
                        : "section-title-name r section-tab"
                    }
                    data-title="returnedByProvider"
                  >
                    <AppTranslation.ProviderRoom path="tabs.returnedByProvider" />
                  </a>
                </li>
              )}
            </ul>
            <div className="table-responsive min-h-200">
              <table className="table-updated setting-table min-w-1000 ajax-view">
                <thead
                  className={
                    this.state.MDRoomData != ""
                      ? "table-updated-thead"
                      : "no-display"
                  }
                >
                  <tr>
                    <th
                      className={
                        this.state.action === "signed" ||
                        this.state.defTimeLine === "changes-needed"
                          ? this.state.hideHeading
                          : this.state.showHeading
                      }
                    />
                    <th
                      className={
                        this.state.defTimeLine === "health"
                          ? "col-xs-2 table-updated-th"
                          : "col-xs-2 table-updated-th"
                      }
                    >
                      {this.state.roomTextData.th_pro_info_text}
                    </th>
                    <th
                      className={
                        this.state.defTimeLine === "health"
                          ? "col-xs-2 table-updated-th"
                          : "col-xs-2 table-updated-th"
                      }
                    >
                      <AppTranslation.Common path={"label.patient"} />
                    </th>
                    <th className={"col-xs-2 table-updated-th"}>
                      {this.state.globalLang.label_clinic}
                    </th>
                    <th className="col-xs-2 table-updated-th">
                      {this.state.roomTextData.th_provider_text}
                    </th>
                    {(this.state.defTimeLine === "cosmetic" ||
                      this.state.defTimeLine === "changes-needed" ||
                      this.state.defTimeLine === "changes-done") && (
                      <th className="col-xs-2 table-updated-th">
                        {this.state.roomTextData.th_treat_sum_text}
                      </th>
                    )}
                    <th className="col-xs-1 table-updated-th">
                      {this.state.roomTextData.th_latest_note}
                    </th>
                    <th className="col-xs-1 table-updated-th">
                      {this.state.roomTextData.th_consent_text}
                    </th>
                    {(this.state.defTimeLine === "cosmetic" ||
                      this.state.defTimeLine === "health") && (
                      <th className="col-xs-1 table-updated-th">
                        {this.state.roomTextData.th_question_text}
                      </th>
                    )}
                    {this.state.defTimeLine === "health" && (
                      <th className="col-xs-1 table-updated-th">
                        {"Prescription"}
                      </th>
                    )}
                    <th className="col-xs-1 table-updated-th single-line-text">
                      {this.state.roomTextData.th_consult_only_text}
                    </th>
                  </tr>
                </thead>
                <tbody className="patient-list">
                  {this.state.MDRoomData !== undefined &&
                    this.state.MDRoomData.map((obj, idx) => {
                      return (
                        <tr
                          className="table-updated-tr md-rooms-checkbox"
                          key={idx}
                        >
                          <td
                            className={
                              this.state.action === "signed" ||
                              this.state.defTimeLine === "changes-needed"
                                ? this.state.hideCheckbox
                                : this.state.showCheckbox
                            }
                          >
                            <input
                              type="checkbox"
                              name={"childCheck_" + idx}
                              className="select-pending-procedure"
                              checked={this.props?.signList?.includes(
                                Number(obj.id),
                              )}
                              onChange={this.handleInputChange}
                              value={obj.id}
                            />
                          </td>

                          <td
                            className={
                              this.state.defTimeLine === "health"
                                ? "col-xs-2 table-updated-td modal-link cursor-pointer"
                                : "col-xs-2 table-updated-td modal-link cursor-pointer"
                            }
                            data-url={obj.id}
                            onClick={this.userEdit.bind(
                              this,
                              obj.id,
                              obj.procedure_type,
                            )}
                          >
                            <div>
                              {obj.procedure_name ? obj.procedure_name : "N/A"}
                            </div>
                            <div className="pro-date">
                              {obj.procedure_date
                                ? showFormattedDate(
                                    obj.procedure_date.split(" ")[0],
                                  )
                                : ""}
                            </div>
                          </td>
                          <td
                            className={
                              this.state.defTimeLine === "health"
                                ? "col-xs-2 table-updated-td"
                                : "col-xs-2 table-updated-td"
                            }
                            onClick={this.userEdit.bind(
                              this,
                              obj.id,
                              obj.procedure_type,
                            )}
                          >
                            {obj.patient && obj.patient.firstname != undefined
                              ? obj.patient.firstname
                              : ""}{" "}
                            {obj.patient && obj.patient.lastname != undefined
                              ? obj.patient.lastname
                              : ""}
                          </td>
                          <td className="col-xs-2 table-updated-td">
                            {shortenClinicName(obj?.clinic?.clinic_name || "_")}
                          </td>
                          <td
                            className="col-xs-2 table-updated-td"
                            onClick={this.userEdit.bind(
                              this,
                              obj.id,
                              obj.procedure_type,
                            )}
                          >
                            {obj && obj.user && obj.user.firstname}{" "}
                            {obj && obj.user && obj.user.lastname}
                          </td>
                          {(this.state.defTimeLine === "cosmetic" ||
                            this.state.defTimeLine === "changes-needed" ||
                            this.state.defTimeLine === "changes-done") && (
                            <td
                              className="col-xs-1 table-updated-td modal-link"
                              onClick={this.userEdit.bind(
                                this,
                                obj.id,
                                obj.procedure_type,
                              )}
                            >
                              {obj.injection_array.map((injobj, injidx) => {
                                return (
                                  <div key={injidx}>
                                    <b>{injobj.product_name}</b>{" "}
                                    {injobj.quantity
                                      ? numberFormat(injobj.quantity)
                                      : ""}{" "}
                                    {injobj.unit}
                                  </div>
                                );
                              })}
                              {(obj.type === "laser" ||
                                obj.type === "coolsculpting") &&
                                obj.procedure_information &&
                                obj.procedure_information.map(
                                  (pojobj, poidx) => {
                                    return (
                                      <div key={poidx}>
                                        <b>{pojobj.field}</b>{" "}
                                        {pojobj.value && isNumber(pojobj.value)
                                          ? numberFormat(
                                              pojobj.value,
                                              "decimal",
                                            )
                                          : pojobj.value}{" "}
                                        {pojobj.unit}
                                      </div>
                                    );
                                  },
                                )}
                            </td>
                          )}
                          <td
                            className="col-xs-1 table-updated-td"
                            title={this.state.roomTextData.th_latest_note}
                          >
                            {obj.procedure_notes_count !== null &&
                            obj.procedure_notes_count > 0 ? (
                              <img
                                alt=""
                                onClick={this.viewNotes.bind(this, obj)}
                                src={require("../../_legacy/images/green-check.png")}
                              />
                            ) : (
                              <img
                                alt=""
                                onClick={this.viewNotes.bind(this, obj)}
                                src={require("../../_legacy/images/red-cross.png")}
                              />
                            )}
                          </td>
                          {obj?.procedure_type === "cosmetic" && (
                            <td
                              className="col-xs-1 table-updated-td"
                              title={this.state.roomTextData.th_consent_text}
                            >
                              {obj.show_consents_btn > 0 ? (
                                <img
                                  alt=""
                                  onClick={this.procedureConsentsEdit.bind(
                                    this,
                                    obj.id,
                                  )}
                                  src={require("../../_legacy/images/green-check.png")}
                                />
                              ) : (
                                <img
                                  alt=""
                                  src={require("../../_legacy/images/red-cross.png")}
                                />
                              )}
                            </td>
                          )}
                          {this.state.defTimeLine === "health" && (
                            <td
                              className="col-xs-1 table-updated-td"
                              title={this.state.roomTextData.th_consent_text}
                            >
                              {obj.show_consents_btn > 0 ? (
                                <img
                                  alt=""
                                  onClick={this.procedureConsentsEdit.bind(
                                    this,
                                    obj.id,
                                  )}
                                  src={require("../../_legacy/images/green-check.png")}
                                />
                              ) : (
                                <img
                                  alt=""
                                  src={require("../../_legacy/images/red-cross.png")}
                                  onClick={this.procedureConsentsEdit.bind(
                                    this,
                                    obj.id,
                                  )}
                                />
                              )}
                            </td>
                          )}
                          {this.state.defTimeLine === "cosmetic" && (
                            <td
                              className="col-xs-1 table-updated-td"
                              title={this.state.roomTextData.th_question_text}
                            >
                              {obj.show_questionnaires_btn > 0 ? (
                                <img
                                  alt=""
                                  onClick={this.procedureQuestionnaireEdit.bind(
                                    this,
                                    obj.id,
                                  )}
                                  src={require("../../_legacy/images/green-check.png")}
                                />
                              ) : (
                                <img
                                  alt=""
                                  onClick={() =>
                                    history.push(
                                      ROUTES.createEditCosmeticProcedure({
                                        actionType: "clients",
                                        resourceType: "edit",
                                        procedureId: obj.id,
                                        clientId: obj.patient_id,
                                        type: "profile",
                                      }),
                                    )
                                  }
                                  src={require("../../_legacy/images/red-cross.png")}
                                />
                              )}
                            </td>
                          )}
                          {this.state.defTimeLine === "health" && (
                            <td
                              className="col-xs-1 table-updated-td"
                              title={this.state.roomTextData.th_question_text}
                            >
                              {obj.show_questionnaires_btn > 0 ? (
                                <img
                                  alt=""
                                  onClick={this.viewHealthQuestionnaires.bind(
                                    this,
                                    obj.id,
                                  )}
                                  src={require("../../_legacy/images/green-check.png")}
                                />
                              ) : (
                                <img
                                  alt=""
                                  onClick={() =>
                                    history.push(
                                      ROUTES.createEditHealthProcedure({
                                        actionType: "clients",
                                        resourceType: "edit",
                                        procedureId: obj.id,
                                        clientId: obj.patient_id,
                                        type: "profile",
                                        isPrescribed: false,
                                      }),
                                    )
                                  }
                                  src={require("../../_legacy/images/red-cross.png")}
                                />
                              )}
                            </td>
                          )}
                          {obj?.procedure_type === "health" && (
                            <td
                              className="col-xs-1 table-updated-td"
                              title={"Prescription"}
                            >
                              {obj.procedure_prescription_count > 0 ? (
                                <img
                                  alt=""
                                  onClick={this.procedurePrescriptionEdit.bind(
                                    this,
                                    obj.id,
                                  )}
                                  src={require("../../_legacy/images/green-check.png")}
                                />
                              ) : (
                                <img
                                  alt=""
                                  src={require("../../_legacy/images/red-cross.png")}
                                />
                              )}
                            </td>
                          )}
                          <td className="col-xs-1 table-updated-td">
                            {obj.is_consult === 1 ||
                            obj?.procedure_type === "health"
                              ? `${this.state.roomTextData.yes_text}`
                              : `${this.state.roomTextData.no_text}`}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              {this.state.showLoader !== true &&
                this.state.MDRoomData !== undefined &&
                this.state.MDRoomData.length === 0 && (
                  <div className="text-center text-loading">
                    {this.state.roomTextData.no_rec_room_text}{" "}
                  </div>
                )}
            </div>
          </div>
          <div
            className={
              this.state.showLoadingText
                ? "loading-please-wait"
                : "loading-please-wait no-display"
            }
          >
            {this.state.globalLang.loading_please_wait_text}
          </div>
        </div>
        <div
          className={
            this.state.showLoader
              ? "new-loader text-left displayBlock"
              : "new-loader text-left"
          }
        >
          <div className="loader-outer">
            <img
              alt=""
              id="loader-outer"
              src="/images/Eclipse.gif"
              className="loader-img"
            />
            <div id="modal-confirm-text" className="popup-subtitle">
              {this.state.globalLang.Please_Wait}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const languageData = JSON.parse(localStorage.getItem("languageData"));
  localStorage.setItem("sortOnly", false);

  if (state.SettingReducer.action === "MDRoom_LIST") {
    if (state.SettingReducer.data.status === 200) {
      return {
        MDRoomData: state.SettingReducer.data.data,
        clinics: state.CardreaderReducer.clinics.data,
        signList: providerRoom.selectors.selectProceduresSignList(state),
      };
    }
  } else if (state.SettingReducer.action === "SIGN_PROCEDURE") {
    uiNotification.clear();

    if (state.SettingReducer.data.status !== 201) {
      uiNotification.error(
        languageData.global[state.SettingReducer.data.message],
      );
      return {
        showLoader: false,
        isReloadData: true,
        showLoaderTimeStamp: Math.round(new Date().getTime() / 1000),
        signList: providerRoom.selectors.selectProceduresSignList(state),
      };
    } else {
      uiNotification.success(
        languageData.global[state.SettingReducer.data.message],
      );
      return {
        MDRoomData: state.SettingReducer.data.data,
        clinics: state.CardreaderReducer.clinics.data,
        signList: providerRoom.selectors.selectProceduresSignList(state),
      };
    }
  }

  if (state.SettingReducer.action === "EMPTY_DATA") {
    if (state.SettingReducer.data.status != 200) {
      uiNotification.error(
        languageData.global[state.SettingReducer.data.message],
      );
      return {};
    } else {
      return {};
    }
  }

  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      fetchMDRoomData,
      signProcedure,
      exportEmptyData,
      fetchClinics,
      addProcedureToList: providerRoom.actions.addProcedureToSignList,
      removeFromSignList: providerRoom.actions.removeProcedureFromSignList,
      clearProcedureList: providerRoom.actions.clearProcedureSignList,
      fillSignlist: providerRoom.actions.addFilledSignList,
    },
    dispatch,
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(ProcedureList));
