import React, { useState } from "react";
import { connect } from "react-redux";
import { useUpdate } from "../../hooks/useUpdate";
import { uiNotification } from "../../../../../../../services/UINotificationService";
import { productPricingMember } from "../../../../../../../store/inventory/productPricingMember";
import { validateChangedPricesForRequest } from "../../../../../../../store/inventory/productPricingMember/utilities";

var { selectors } = productPricingMember;

const mapStateToProps = (state) => ({
  forAllClinics: selectors.selectForAllClinics(state),
  getChangedPricesForRequest: selectors.selectChangedPricesForRequest(state),
});

export var withSave = (Component) =>
  connect(mapStateToProps)((props) => {
    var { getChangedPricesForRequest, forAllClinics, ...restProps } = props;

    var [perMemberErrors, setPerMemberErrors] = useState(new Map());
    var [forAllClinicsError, setForAllClinicsError] = useState(false);

    var updatePriceList = useUpdate();

    var onSave = async (productId, showNotification = true) => {
      setForAllClinicsError(false);
      setPerMemberErrors(new Map());
      const requestChangedPrices = getChangedPricesForRequest();

      const errors = validateChangedPricesForRequest({
        requestChangedPrices,
        forAllClinics,
      });

      if (!errors.forAllClinicsError && errors.perMemberErrors.length === 0) {
        return await updatePriceList.initiate({
          productId: Number(productId),
          priceList: requestChangedPrices,
          showNotification,
        });
      } else {
        setForAllClinicsError(errors.forAllClinicsError);
        setPerMemberErrors(
          new Map(errors.perMemberErrors.map((clinicId) => [clinicId, true])),
        );
        uiNotification.error(
          "Please fill all the required fields in Membership Pricing tab",
        );
      }
    };

    return (
      <Component
        {...restProps}
        saveMemberPrices={{
          initiate: onSave,
          isLoading: updatePriceList.isLoading,
          perMemberErrors,
          setPerMemberErrors,
          forAllClinicsError,
          setForAllClinicsError,
        }}
      />
    );
  });
