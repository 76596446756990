import React, { Component } from "react";
import Highcharts from "highcharts";
import variablePie from "highcharts/modules/variable-pie.js";
import "./patientReferralReport.scss";
import { isEqual } from "lodash";
import { AppTranslation } from "../../../i18n/useAppTranslation";

variablePie(Highcharts);
class PatientReferralReport extends Component {
  constructor(props) {
    super(props);
    const languageData = JSON.parse(localStorage.getItem("languageData"));

    this.state = {
      languageData: languageData.business_insights,
      globalLang: languageData.global,
      businessInsightLang: languageData.business_insights,
      graphData: this.props.graphData ? this.props.graphData : null,
      reportListData: this.props.reportListData
        ? this.props.reportListData
        : [],
      seriesData: "",
      selectedSource: "",
      sortbBy: "",
      sortOrder: "desc",
    };
  }
  componentDidMount() {
    this.renderHighChart(this.props.selectOption);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.graphData.length === 0 && this.props.graphData.length > 0) {
      this.renderHighChart(this.props.selectOption);
    }

    if (!isEqual(prevProps.graphData, this.props.graphData)) {
      this.renderHighChart(this.props.selectOption);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let returnState = {};
    if (nextProps.graphData && nextProps.graphData !== prevState.graphData) {
      returnState.graphData = nextProps.graphData;
      if (prevState.highcharts && prevState.highcharts.series != undefined) {
        let series = [];
        nextProps.graphData.map((obj) => {
          series.push({
            name: obj.name.source_name + ": " + obj.percentage + "% users",
            y: obj.count,
          });
        });
        let highcharts = prevState.highcharts;
        highcharts.update({
          series: {
            minPointSize: 10,
            innerSize: "40%",
            zMin: 0,
            name: "Referral",
            data: series,
          },
        });
        returnState.seriesData = series;
      }
    }

    if (
      nextProps.reportListData &&
      nextProps.reportListData !== prevState.reportListData
    ) {
      returnState.reportListData = nextProps.reportListData;
      returnState.tableColumns = nextProps.tableColumns;
    }
    return returnState;
  }

  renderHighChart = (callback) => {
    let colors = [
      "#7cb5ec",
      "#434348",
      "#90ed7d",
      "#f7a35c",
      "#8085e9",
      "#f15c80",
      "#e4d354",
      "#2b908f",
      "#f45b5b",
      "#91e8e1",
      "#38964e",
      "#403cb5",
      "#05f221",
      "#010301",
      "#a2b3a4",
      "#0f31db",
      "#cdff05",
      "#ff05ea",
      "#087876",
      "#cfb004",
      "#434348",
      "#f7a35c",
      "#730202",
      "#90ed7d",
      "#0f31db",
    ];
    let colorIndex = 0;
    const colorList = [];
    const filteredSources = this.props.graphData.filter((el) => {
      return el.percentage > 0;
    });

    const sourceData = filteredSources.map((source) => {
      const color = colors[colorIndex];
      if (source.percentage > 0) {
        colorIndex++;
        colorList.push(color);
      }
      return {
        name: source.name.source_name,
        sourceCode: source.name.source_code,
        y: parseFloat(source.percentage),
        color: color,
        props: this.props,
        subcategories: source.name.subcategories.filter(
          (subcategory) => parseInt(subcategory.percentage) > 0,
        ),
      };
    });

    const chart = Highcharts.chart(
      "patientReferralReportContainer",
      {
        chart: {
          type: "pie",
        },
        title: {
          text: "",
        },
        plotOptions: {
          pie: {
            shadow: false,
            cursor: "pointer",
          },
        },
        tooltip: {
          valueSuffix: "%",
          formatter: function () {
            if (this.point.subcategoryPercent != null) {
              return `${this.point.name}: ${this.point.subcategoryPercent}%`;
            }
            return `${this.point.name}: ${this.y}%`;
          },
        },
        series: [
          {
            name: "Sources",
            data: sourceData,
            size: "100%",
            dataLabels: {
              formatter: function () {
                return this.y > 0
                  ? `<span class='sourcesNameGraph'>${this.point.name}</span>`
                  : null;
              },
              color: "#ffffff",
            },
          },
        ],
      },
      () => {
        const legend = document.querySelector("#customLegend");
        legend.innerHTML = "";

        const allSourcesTab = document.createElement("p");
        allSourcesTab.textContent = "All Sources - 100%";
        allSourcesTab.setAttribute(
          "style",
          "color:#333333;cursor:pointer;font-size:16px;font-weight:normal;fill:#333333;margin-bottom: 0",
        );
        legend.appendChild(allSourcesTab);
        allSourcesTab.addEventListener("click", () =>
          this.props.selectOption(""),
        );

        sourceData.forEach((category) => {
          const unorderedList = document.createElement("ul");
          unorderedList.setAttribute("class", "no-display");
          const categoryRow = document.createElement("p");
          categoryRow.setAttribute("class", "categoryLegendRow");

          for (let i = 0; i < category.subcategories.length; i++) {
            let listItem = document.createElement("li");
            listItem.setAttribute(
              "style",
              "margin-left:15px; list-style-type: none",
            );
            listItem.textContent = `${category.subcategories[i].name} - ${category.subcategories[i].percentage}%`;
            unorderedList.appendChild(listItem);
          }

          const categoryLabel = document.createElement("p");
          const categoryColor = document.createElement("div");

          if (category.name) {
            const downArrow = document.createElement("span");
            downArrow.classList.add("angleArrow");
            downArrow.innerHTML =
              '<svg className="svg-inline--fa fa-angle-down fa-w-10" aria-hidden="true" data-prefix="fas" data-icon="angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path></svg>';
            const upArrow = document.createElement("span");
            upArrow.classList.add("angleArrow", "no-display");
            upArrow.innerHTML =
              '<svg className="svg-inline--fa fa-angle-up fa-w-10" aria-hidden="true" data-prefix="fas" data-icon="angle-up" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M177 159.7l136 136c9.4 9.4 9.4 24.6 0 33.9l-22.6 22.6c-9.4 9.4-24.6 9.4-33.9 0L160 255.9l-96.4 96.4c-9.4 9.4-24.6 9.4-33.9 0L7 329.7c-9.4-9.4-9.4-24.6 0-33.9l136-136c9.4-9.5 24.6-9.5 34-.1z"></path></svg>';

            categoryRow.setAttribute(
              "style",
              "display:flex; align-items: center; margin: 0",
            );
            categoryRow.setAttribute("data-sourceName", category.name);
            categoryLabel.setAttribute(
              "style",
              "color:#333333;cursor:pointer;font-size:16px;font-weight:normal;fill:#333333;margin-bottom: 0",
            );
            if (category.subcategories && category.subcategories.length > 0) {
              categoryRow.addEventListener("click", () => {
                if (
                  categoryRow.nextElementSibling.getAttribute("class") ===
                  "no-display"
                ) {
                  categoryRow.nextElementSibling.setAttribute("class", "");
                  upArrow.classList.remove("no-display");
                  downArrow.classList.add("no-display");
                } else {
                  categoryRow.nextElementSibling.setAttribute(
                    "class",
                    "no-display",
                  );
                  upArrow.classList.add("no-display");
                  downArrow.classList.remove("no-display");
                }
              });
            }

            categoryRow.addEventListener("mouseout", () => {
              const allGraphSlices =
                document.querySelectorAll(".highcharts-point");
              for (let i = 0; i < allGraphSlices.length; i++) {
                if (!this.state.selectedSource) {
                  allGraphSlices[i].setAttribute("opacity", "1");
                }
              }
            });

            categoryLabel.textContent = `${category.name} - ${category.y}%`;
            categoryColor.setAttribute(
              "style",
              `width:16px;height:16px;background:${category.color};border-radius: 4px;margin-right:5px`,
            );
            categoryRow.appendChild(categoryColor);
            categoryRow.appendChild(categoryLabel);

            if (category.subcategories && category.subcategories.length > 0) {
              categoryRow.appendChild(downArrow);
            }
            categoryRow.appendChild(upArrow);

            const allCategoriesRow =
              legend.querySelectorAll(".categoryLegendRow");

            if (allCategoriesRow.length < sourceData.length) {
              legend.appendChild(categoryRow);
              legend.appendChild(unorderedList);
            }
          }
        });

        const allCategoriesRow = legend.querySelectorAll(".categoryLegendRow");

        for (let i = 0; i < allCategoriesRow.length; i++) {
          allCategoriesRow[i].classList.add(`highcharts-color-${i}`);
          const allGraphSlices = document.querySelectorAll(".highcharts-point");
          const graphSlice = allGraphSlices[i];

          allCategoriesRow[i].addEventListener("mouseover", () => {
            for (let i = 0; i < allGraphSlices.length; i++) {
              allGraphSlices[i].setAttribute("opacity", "0.2");
            }
            if (graphSlice) {
              graphSlice.setAttribute("opacity", 1);
            }
          });
          allCategoriesRow[i].addEventListener("click", () => {
            let selectedSource =
              allCategoriesRow[i].getAttribute("data-sourceName");
            callback(selectedSource);
          });
        }
      },
    );
    return chart;
  };

  render() {
    return (
      <>
        <div className="table-responsive no-border m-t-20">
          {
            <div
              className={`business-insight-graph patient-referral-report-graph ${
                this.state.reportListData.length === 0 && "no-display"
              }`}
              id="patientReferralReportGraph"
              data-highcharts-chart="0"
            >
              <div
                className="business-insight-graph-container patient-referral-report-container"
                id="patientReferralReportContainer"
              />
              <div id="customLegend" />
            </div>
          }
          {this.state.reportListData.length === 0 && (
            <p className="text-center">
              No new Patients added during this time period.
            </p>
          )}
        </div>
        <div className="table-responsive clients-table no-border m-t-10">
          <table className="table-updated  table-min-width no-td-border no-hover">
            <thead className="table-updated-thead">
              <tr>
                {this.state.tableColumns &&
                  this.state.tableColumns.length > 0 &&
                  this.state.tableColumns.map((tobj, tidx) => {
                    const columnLabel =
                      tobj === "bi_client_name" ? (
                        <AppTranslation.Common path="label.patientName" />
                      ) : (
                        this.state.languageData[tobj]
                      );

                    return (
                      <th
                        key={tidx}
                        className={`table-updated-th ${
                          this.props.isSortableColumn(tobj) &&
                          "sortData sorting"
                        }`}
                        onClick={() =>
                          this.props.isSortableColumn(tobj) &&
                          this.props.onSort(tobj)
                        }
                      >
                        {columnLabel}
                        {this.props.isSortableColumn(tobj) && (
                          <i
                            className={
                              this.props.sortOrder === "asc" &&
                              this.props.sortBy === tobj
                                ? "blue-gray"
                                : this.props.sortOrder === "desc" &&
                                  this.props.sortBy === tobj
                                ? "gray-blue"
                                : "gray-gray"
                            }
                          />
                        )}
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody className="ajax_body">
              {this.state.reportListData &&
                this.state.reportListData.length > 0 &&
                this.state.reportListData.map((robj, ridx) => {
                  return (
                    <tr key={ridx} className="table-updated-tr">
                      {this.state.tableColumns &&
                        this.state.tableColumns.length > 0 &&
                        this.state.tableColumns.map((tobj, tidx) => {
                          return (
                            <td
                              key={ridx + `_` + tidx}
                              className={
                                ["bi_email", "bi_phone"].indexOf(tobj) > -1
                                  ? "table-updated-td break-all"
                                  : "table-updated-td"
                              }
                            >
                              {robj[tobj] || ""}
                            </td>
                          );
                        })}
                    </tr>
                  );
                })}

              {this.state.reportListData &&
                this.state.reportListData.length <= 0 &&
                this.props.parentState.showLoader === false &&
                this.props.parentState.startFresh === false && (
                  <tr className="table-updated-tr">
                    <td
                      className="table-updated-td no-record no-float"
                      colSpan={10}
                    ></td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
      </>
    );
  }
}

export default PatientReferralReport;
