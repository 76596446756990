import React, { useEffect, useMemo, useRef, useState } from "react";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import clsx from "clsx";
import moment from "moment";
import { useStripe } from "../../../hooks/useStripe";
import {
  WAIT_LIST_MONTHS,
  WAIT_LIST_YEARS,
  WAITLIST_ANY_MONTH,
} from "../Event.consts";
import Loader from "../components/Loader";
import CommonCCDetails from "../components/common/CommonCCDetails";
import CommonCCForm from "../components/common/CommonCCForm";
import CommonNotes from "../components/common/CommonNotes";
import CommonAdditionally from "../components/common/CommonAdditionally";
import { CommonControlButtons } from "../components/common/CommonControlButtons";
import WaitListDayTimes from "../components/waitList/WaitListDayTimes";
import WaitListMultiselectEntity from "../components/waitList/WaitListMultiselectEntity";
import { useApiClientById } from "../hooks/useApiClientById";
import { useApiClinics } from "../hooks/useApiClinics";
import { useApiPatientCardDetails } from "../hooks/useApiPatientCardDetails";
import { useApiPaymentStatuses } from "../hooks/useApiPaymentStatuses";
import { useApiProvidersByClinics } from "../hooks/useApiProvidersByClinics";
import { useApiServicesByClinicsAndProviders } from "../hooks/useApiServicesByClinicsAndProviders";
import { useCreateWaitListEntry } from "../hooks/useCreateWaitListEntry";
import { initWaitListForm, useFormWaitList } from "../hooks/useFormWaitList";
import { usePaymentInfoWaitList } from "../hooks/usePaymentInfoWaitList";
import { validateWaitListMonths } from "../Event.utils";
import { updatePageFilter } from "../../../Utils/localStorage";
import { getUserPaymentSystem } from "../../../utilities/localStorage";
import { ClientHandler } from "../widgets/ClientHandler/ClientHandler";
import { event } from "../../../store/event";
import { USER_PAYMENT_SYSTEMS } from "../../../consts/api";
import StripePaymentForm from "../../../Components/Sales/FrontdeskCheckout/StripePaymentForm";
import {
  getSetupIntent,
  getClientCardDataStripe,
} from "../../../Actions/Stripe/StripeActions";
import { EntityRow } from "../components/EntityRow";
import { Tabs } from "../../../shared/Tabs/Tabs";
import CommonCardList from "../components/common/CommonCardList";
import { usePaymentMethodsList } from "../hooks/usePaymentMethodsList";
import { useClientCreditCardsQuery } from "../../../api/queries/useClientCreditCardsQuery";
import { uiNotification } from "../../../services/UINotificationService";
import { US_STATES } from "../../../consts/general";

function WaitList({
  match: { params },
  history,
  changeIsSubmitActive,
  isEventSubmitActive,
  setupIntent,
  getSetupIntent,
  cardList,
  getClientCardDataStripe,
}) {
  const [cardSelected, setCardSelected] = useState("");
  const [newCard, setNewCard] = useState(false);
  const [savedPaymentMethodView, setSavedPaymentMethodView] = useState("card");

  const stripeCardNumberRef = useRef();
  const stripe = useStripe();

  const { clientById, isClientByIdFetching } = useApiClientById(
    params.clientId,
  );

  const { form, setFormValue, hasError, submit } = useFormWaitList(
    initWaitListForm({
      userPaymentSystem: getUserPaymentSystem(),
      clientById,
    }),
  );

  const { clinics, isClinicsFetching } = useApiClinics();

  const {
    providersByClinic,
    providersDictionary,
    isProvidersByClinicFetching,
  } = useApiProvidersByClinics({
    clinicIds: form.clinics.map((c) => c.id),
  });

  const {
    servicesByClinicsAndProviders,
    servicesDictionary,
    isServicesByClinicsAndProvidersFetching,
  } = useApiServicesByClinicsAndProviders({
    clinicIds: form.clinics.map((c) => c.id),
    providerIds: form.providers.map((p) => p.id),
  });

  const { paymentStatuses, isPaymentStatusesFetching } =
    useApiPaymentStatuses();

  const { data: clientCC } = useClientCreditCardsQuery({
    clientId: form.clientId,
  });

  const patientCCOptions = useMemo(
    () =>
      clientCC?.creditCards?.map((card) => ({
        label: card.cardNumber,
        value: card.clearentCardNumber,
      })),
    [clientCC],
  );

  const { patientCardDetails, isPatientCardDetailsFetching } =
    useApiPatientCardDetails({
      patientId: form.clientId,
    });

  const { isCanTakePayment, clearentPublicKey } = usePaymentInfoWaitList({
    paymentStatuses,
    clinics: form.clinics,
  });
  const { createWaitListEntry, isWaitListEntryCreating } =
    useCreateWaitListEntry({
      clientCardNumber: patientCardDetails.cardNumber,
      selectedClearentCard: form.selectedClearentCard?.value,
      setupIntent,
      cardSelected,
      newCard,
      resetClient: () => {
        setFormValue("clientName", "");
        setFormValue("clientId", "");
      },
      onSuccess: () => {
        updatePageFilter("WAITLIST", {
          sort_by: "created_at",
          sort_dir: "desc",
        });
        history.push("/waitlist");
      },
    });

  const isLoading =
    isClinicsFetching ||
    isClientByIdFetching ||
    isProvidersByClinicFetching ||
    isServicesByClinicsAndProvidersFetching ||
    isPaymentStatusesFetching ||
    isPatientCardDetailsFetching ||
    isWaitListEntryCreating;

  const cancelRoute = params.clientId
    ? `/clients/profile/${params.clientId}`
    : "/appointment/index";

  const monthsOptions = useMemo(
    () =>
      form.months.find((i) => i.id === WAITLIST_ANY_MONTH.id)
        ? [WAITLIST_ANY_MONTH]
        : WAIT_LIST_MONTHS,
    [form.months],
  );

  const clearentClinic = useMemo(() => {
    return clearentPublicKey ? { publickey: clearentPublicKey } : {};
  }, [clearentPublicKey]);

  const { linkList, cardsList, haveLinkAndCardSaved } =
    usePaymentMethodsList(cardList);

  /* Clinics */

  const onClinicsChange = (nextClinics) => {
    setFormValue("clinics", nextClinics);
  };

  /* Providers */

  const onProvidersChange = (nextProviders) => {
    setFormValue("providers", nextProviders);
  };

  /* Services */

  const onServicesChange = (nextServices) => {
    setFormValue("services", nextServices);
  };

  /* Clients */

  const handleClientNameChange = (nextClientName) => {
    setFormValue("clientName", nextClientName);
    setFormValue("clientId", "");
  };

  const handleClientPhoneCodeChange = (nextClientPhoneCode) => {
    setFormValue("clientPhoneCode", nextClientPhoneCode);
  };

  const handleSelectClient = (nextClient) => {
    const clientState =
      US_STATES.find(
        (usState) =>
          usState.abbr.toLowerCase() === nextClient.state?.toLowerCase(),
      ) || null;

    const stateOptions = {
      value: clientState?.abbr,
      label: clientState?.name,
    };

    setFormValue("clientId", nextClient.id);
    setFormValue("clientName", nextClient.fullName);
    setFormValue("clientEmail", nextClient.email);
    setFormValue("clientPhone", nextClient.phone);
    setFormValue("clientDob", nextClient.dateOfBirth);
    setFormValue("clearentZipCode", nextClient.pinCode);
    setFormValue("clearentEmail", nextClient.email);
    setCardSelected("");
    setFormValue("dob", moment(nextClient.dob).format("MM/DD/YYYY"));
    setFormValue("state", clientState ? stateOptions : null);
  };

  const handleClientUpdate = ({ id, name, email, phone, dob, phoneCode }) => {
    setFormValue("clientId", id);
    setFormValue("clientName", name);
    setFormValue("clientEmail", email);
    setFormValue("clientPhone", phone);
    setFormValue("clientDob", dob);
    setFormValue("clientPhoneCode", phoneCode);
    setFormValue("clearentEmail", email);
    setNewCard(true);
  };

  /* Additionally */

  const onEnterCreditCardChange = (nextState) => {
    setFormValue("isEnterCreditCardDetails", nextState);
    if (!nextState) {
      setFormValue("isAddNewCard", false);
    }
  };

  /* Credit Cards */

  const onClearentZipCodeChange = (nextClearentZipCode) => {
    setFormValue("clearentZipCode", nextClearentZipCode);
  };

  const onClearentEmailChange = (nextClearentEmail) => {
    setFormValue("clearentEmail", nextClearentEmail);
  };

  const onToggleCCForm = () => {
    setFormValue("isAddNewCard", true);
  };

  const onClearentCardChange = (nextClearentCard) => {
    setFormValue("selectedClearentCard", nextClearentCard);
  };

  /* Days and Times */

  const onDaysChange = (nextDays) => {
    setFormValue("days", nextDays);
  };

  const onTimesChange = (nextTimes) => {
    setFormValue("times", nextTimes);
  };

  /* Months */

  const onMonthsChange = (nextMonths) => {
    if (nextMonths.find((i) => i.id === WAITLIST_ANY_MONTH.id)) {
      setFormValue("months", [WAITLIST_ANY_MONTH]);
    } else {
      setFormValue("months", nextMonths);
    }
  };

  /* Years */

  const onYearsChange = (nextYears) => {
    setFormValue("years", nextYears);
    setFormValue(
      "months",
      validateWaitListMonths({ months: form.months, years: nextYears }),
    );
  };

  /* Notes */

  const onNotesChange = (nextNotes) => {
    setFormValue("notes", nextNotes);
  };

  /* Side effects */

  useEffect(() => {
    if (!isProvidersByClinicFetching) {
      setFormValue(
        "providers",
        form.providers.filter((p) => p.id in providersDictionary),
      );
    }
  }, [providersDictionary, isProvidersByClinicFetching]);

  useEffect(() => {
    if (!isServicesByClinicsAndProvidersFetching) {
      setFormValue(
        "services",
        form.services.filter((s) => s.id in servicesDictionary),
      );
    }
  }, [servicesDictionary, isServicesByClinicsAndProvidersFetching]);

  useEffect(() => {
    if (
      !isPatientCardDetailsFetching &&
      patientCardDetails &&
      Boolean(patientCardDetails.pinCode)
    ) {
      setFormValue("clearentZipCode", patientCardDetails.pinCode);
    }
  }, [isPatientCardDetailsFetching]);

  useEffect(() => {
    setFormValue("isEnterCreditCardDetails", isCanTakePayment);
  }, [isCanTakePayment]);

  useEffect(() => {
    if (
      form.clientId &&
      getUserPaymentSystem() === USER_PAYMENT_SYSTEMS.stripe
    ) {
      const formData = {
        patient_id: form.clientId,
        clinic_id: form.clinics?.[0]?.id,
      };

      getSetupIntent(formData, null, Boolean(formData.clinic_id));
      getClientCardDataStripe({ patient_id: formData.patient_id });
    }
  }, [form.clientId, params.clientId, form.type, form.clinics]);

  const handleValidation = () => {
    let error = false;

    const errors = {
      clinics: "Please select at least one clinic!",
      services: "Please select at least one service!",
      providers: "Please select at least one provider!",
      days: "Please select at least one day!",
      months: "Please select at least one month!",
      times: "Please select time preferance!",
      clientId: "Please select a client!",
    };

    Object.keys(errors).forEach((input) => {
      if (form[input].length === 0 || !form[input]) {
        uiNotification.error(errors[input]);
        error = true;
      }
    });
    return error;
  };

  useEffect(() => {
    setNewCard(form.isAddNewCard);
  }, [form.isAddNewCard]);

  useEffect(() => {
    if (cardList?.length === 1) {
      setCardSelected(cardList[0]?.id);
    }
    if (cardList?.length === 0) {
      setNewCard(true);
    } else {
      setNewCard(false);
    }
    if (cardsList?.length === 0) {
      handlePaymentMethodView("link");
    } else {
      handlePaymentMethodView("card");
    }
  }, [cardList]);

  const changeCardSelected = (e) => {
    e.preventDefault();
    setCardSelected(e.target.value);
  };

  const saveWaitlistStripe = () => {
    if (form.isEnterCreditCardDetails) {
      if (cardSelected) {
        submit(createWaitListEntry);
      } else {
        uiNotification.clear();
        if (!form.clientId)
          uiNotification.error("Select client from the search list");
        else uiNotification.error("Please select a card");
      }
    } else {
      submit(createWaitListEntry);
    }
  };

  const showStripeCommonButtons = () => {
    return (
      !form.isEnterCreditCardDetails ||
      Boolean(cardList?.length) ||
      !form.clientId
    );
  };

  const handlePaymentMethodView = (view) => {
    setCardSelected("");
    setSavedPaymentMethodView(view);
  };

  const handleDob = (value) => {
    setFormValue("dob", value);
  };

  const handleState = (option) => {
    setFormValue("state", option);
  };

  const isGfeRequired = useMemo(() => {
    const gfeRequired = form.services.some((service) => service.gfeRequired);
    return gfeRequired;
  }, [form.services]);

  useEffect(() => {
    setFormValue("isGfeRequired", isGfeRequired);
  }, [form.services]);

  /* --------- */

  return (
    <>
      <WaitListMultiselectEntity
        label="Clinic"
        name="clinics"
        values={form.clinics}
        onChange={onClinicsChange}
        isError={hasError("clinics")}
        options={clinics}
        placeholder="Select clinics"
      />
      <WaitListMultiselectEntity
        label="Provider"
        name="providers"
        values={form.providers}
        onChange={onProvidersChange}
        isError={hasError("providers")}
        options={providersByClinic}
        placeholder="Select providers"
      />
      <WaitListMultiselectEntity
        label="Service"
        name="services"
        values={form.services}
        onChange={onServicesChange}
        isError={hasError("services")}
        options={servicesByClinicsAndProviders}
        placeholder="Select services"
      />
      <WaitListDayTimes
        days={{
          value: form.days,
          onChange: onDaysChange,
        }}
        timeSlots={{
          value: form.times,
          onChange: onTimesChange,
        }}
        months={{
          values: form.months,
          onChange: onMonthsChange,
          isError: hasError("months"),
          options: monthsOptions,
        }}
        years={{
          values: form.years,
          onChange: onYearsChange,
          isError: hasError("years"),
          options: WAIT_LIST_YEARS,
        }}
      />
      <ClientHandler
        id={form.clientId}
        name={form.clientName}
        email={form.clientEmail}
        phone={form.clientPhone}
        phoneCode={form.clientPhoneCode}
        dob={form.clientDob}
        isNameError={hasError("clientName")}
        isEmailError={hasError("clientEmail")}
        isPhoneError={hasError("clientPhone") || hasError("clientPhoneCode")}
        isDobError={hasError("clientDob")}
        onSelect={handleSelectClient}
        onUpdate={handleClientUpdate}
        onNameChange={handleClientNameChange}
        onPhoneCodeChange={handleClientPhoneCodeChange}
        changeIsSubmitActive={changeIsSubmitActive}
        isGfeRequired={isGfeRequired}
        handleDob={handleDob}
        hasDobError={hasError("dob")}
        state={form.state}
        handleState={handleState}
        hasStateError={hasError("state")}
      />
      <CommonNotes notes={form.notes} onNotesChange={onNotesChange} />
      {isCanTakePayment && form.clientId && (
        <CommonAdditionally
          isEnterCreditCard={form.isEnterCreditCardDetails}
          onEnterCreditCardChange={onEnterCreditCardChange}
        />
      )}
      {form.isEnterCreditCardDetails && (
        <>
          {(form.isAddNewCard || !patientCardDetails.cardNumber) &&
            getUserPaymentSystem() === USER_PAYMENT_SYSTEMS.clearent && (
              <CommonCCForm
                ref={stripeCardNumberRef}
                stripeInstance={stripe}
                isClearentZipError={hasError("clearentZipCode")}
                clearentZip={form.clearentZipCode}
                onClearentZipChange={onClearentZipCodeChange}
                isClearentEmailError={hasError("clearentEmail")}
                clearentEmail={form.clearentEmail}
                onClearentEmailChange={onClearentEmailChange}
                clearentClinic={clearentClinic}
              />
            )}

          {!form.isAddNewCard &&
            Boolean(patientCardDetails.cardNumber) &&
            getUserPaymentSystem() === USER_PAYMENT_SYSTEMS.clearent && (
              <CommonCCDetails
                cardNumber={patientCardDetails.cardNumber}
                toggleCCForm={onToggleCCForm}
                isClearentZipError={hasError("clearentZipCode")}
                clearentZip={form.clearentZipCode}
                onClearentZipChange={onClearentZipCodeChange}
                isClearentEmailError={hasError("clearentEmail")}
                clearentEmail={form.clearentEmail}
                onClearentEmailChange={onClearentEmailChange}
                clearentCardList={patientCCOptions}
                onClearentCardChange={onClearentCardChange}
              />
            )}

          {!form.isAddNewCard &&
            form.clientId &&
            Boolean(cardList?.length) &&
            getUserPaymentSystem() === USER_PAYMENT_SYSTEMS.stripe && (
              <>
                {cardList?.length ? (
                  <div className="m-t-80">
                    <span className="width130 col-xs-2 no-padding font-size-16 color-gray0">
                      Payment Method
                    </span>
                    <span className="col-xs-5 mr-30 m-b-30">
                      {haveLinkAndCardSaved && (
                        <Tabs
                          tabClassName={clsx("m-b-15", "min-w-100")}
                          variant="tiled"
                          active={savedPaymentMethodView}
                          options={[
                            {
                              label: "Saved Cards",
                              value: "card",
                              onClick: () => handlePaymentMethodView("card"),
                            },
                            {
                              label: "Link",
                              value: "link",
                              onClick: () => {
                                handlePaymentMethodView("link");
                              },
                            },
                          ]}
                        />
                      )}
                      <div className="new-field-label">
                        {savedPaymentMethodView === "card"
                          ? "Credit Card(s)"
                          : "Link"}
                      </div>
                      <select
                        className="setting-select-box"
                        value={cardSelected}
                        onChange={changeCardSelected}
                      >
                        {!cardSelected && (
                          <option value="">
                            Select{" "}
                            {savedPaymentMethodView === "card"
                              ? "card"
                              : "link"}
                          </option>
                        )}
                        <CommonCardList
                          savedPaymentMethodView={savedPaymentMethodView}
                          cardsList={cardsList}
                          linkList={linkList}
                        />
                      </select>
                    </span>
                  </div>
                ) : (
                  ""
                )}

                <CommonCCDetails
                  cardNumber={patientCardDetails.cardNumber}
                  toggleCCForm={onToggleCCForm}
                  isClearentZipError={hasError("clearentZipCode")}
                  clearentZip={form.clearentZipCode}
                  onClearentZipChange={onClearentZipCodeChange}
                  isClearentEmailError={hasError("clearentEmail")}
                  clearentEmail={form.clearentEmail}
                  onClearentEmailChange={onClearentEmailChange}
                  cardOnFileStripe={true}
                  clearentCardList={patientCCOptions}
                  onClearentCardChange={onClearentCardChange}
                />
              </>
            )}
          {getUserPaymentSystem() === USER_PAYMENT_SYSTEMS.stripe &&
            form.clientId &&
            (form.isAddNewCard || Boolean(!cardList?.length)) && (
              <EntityRow>
                <StripePaymentForm
                  type="setupIntent"
                  onSubmit={() => submit(createWaitListEntry)}
                  onCancel={() => history.push(cancelRoute)}
                  validation={() => handleValidation()}
                  onSubmitText={"Save"}
                />
              </EntityRow>
            )}
        </>
      )}

      {getUserPaymentSystem() === USER_PAYMENT_SYSTEMS.clearent && (
        <CommonControlButtons
          onSubmit={() => submit(createWaitListEntry)}
          onCancel={() => history.push(cancelRoute)}
          isSubmitEnabled={isEventSubmitActive}
        />
      )}
      {getUserPaymentSystem() === USER_PAYMENT_SYSTEMS.stripe &&
        showStripeCommonButtons() &&
        !form.isAddNewCard && (
          <CommonControlButtons
            onSubmit={() => saveWaitlistStripe()}
            onCancel={() => history.push(cancelRoute)}
            isSubmitEnabled={isEventSubmitActive}
          />
        )}
      {isLoading && <Loader />}
    </>
  );
}

const mapStateToProps = (state) => ({
  isEventSubmitActive: event.selectors.selectIsSubmitActive(state),
  setupIntent: state.StripeReducer.stripeIntent,
  cardList: state.StripeReducer.cardList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeIsSubmitActive: event.actions.isSubmitActiveChange,
      getSetupIntent,
      getClientCardDataStripe,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(WaitList));
