import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { http } from "../../../services/HttpService";
import { QUERY_KEYS, HTTP_ENDPOINTS } from "../../../consts/api";

const requestSchema = yup.object({
  domainId: yup.number().required(),
});

const responseSchema = yup.object({
  domainStatus: yup.string().required(),
  id: yup.number().required(),
});

const composeResponse = (res) => {
  const data = res?.data?.data;
  return {
    domainStatus: data?.cloudflare_domain_status || "",
    id: data?.custom_domain.id,
  };
};

export function useGetVerificationStatusMutation({ onError, onSuccess } = {}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (dto) => {
      const req = requestSchema.validateSync(dto, {
        strict: true,
      });

      const res = await http.get(
        HTTP_ENDPOINTS.customDomain.getVerificationStatus(),
        {
          params: {
            domain_id: req.domainId,
          },
        },
      );
      return responseSchema.validateSync(composeResponse(res));
    },
    onSuccess: (response) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.customDomains.customDomains],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.customDomains.customDomain],
      });
      onSuccess?.(response);
    },
    onError: () => {
      onError?.();
    },
  });
}
