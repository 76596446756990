import moment from "moment";
import { DEFAULT_DATE_FORMAT } from "../../../../consts/general";
import { API_DATE_FORMAT } from "../../../../consts/api";

export const dobToApiDate = (value, format) => {
  return value
    ? moment.utc(value, format || DEFAULT_DATE_FORMAT).format(API_DATE_FORMAT)
    : "";
};

export const apiDateToDob = (value, format) => {
  return value
    ? moment.utc(value, API_DATE_FORMAT).format(format || DEFAULT_DATE_FORMAT)
    : "";
};
