import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Sidebar from '../../../_legacy/Containers/Settings/sidebar.js';
import FileUploader from '../../FileUploader/FileUploader.js';
import { getAccountDetails, updateAccountDetails } from '../../../Actions/Settings/settingsActions.js';
import Loader from '../../../Components/Common/Loader.js';
import defLogo from '../../../_legacy/images/no-image-vertical.png';
import { showFormattedDate, checkIfPermissionAllowed } from '../../../Utils/services.js';
import Autocomplete from 'react-google-autocomplete';
import { ACCOUNT_ID_WITH_NEW_TERMS, EXTERNAL_FILE_LINKS } from '../../../consts/general.js';
import { LOCAL_STORAGE_KEYS } from '../../../consts/localStorage.js';
import { LENGTH_UNITS, LENGTH_UNIT_OPTIONS, TEMPERATURE_UNITS, TEMPERATURE_UNIT_OPTIONS, WEIGHT_UNITS, WEIGHT_UNIT_OPTIONS } from '../../../consts/api.js';
import { lockAppUntilAccountAddressUpdated } from '../../../utilities/localStorage.js';
import { AlertBox } from '../../../shared/AlertBox/AlertBox.js';
import { TaxFreeStatus } from './shared/TaxFreeStatus/TaxFreeStatus.js';
import { uiNotification } from '../../../services/UINotificationService.js';
import { AppTranslation } from '../../../i18n/useAppTranslation.js';
import { AGREEMENT_LINK } from '../../../consts/agreements.js';

class Araccount extends Component {

  constructor(props) {
    super(props);

    const userData = JSON.parse(localStorage.getItem('userData'));
    this.state = {
      pictures: [],
      userId: userData.user.id,
      accountID: userData.user.account_id,
      pos_enabled: userData.pos_enabled,
      user_type: userData.user_type,
      accDetails: {},
      dateFormats: [{ val: 'mm/dd/yyyy', show: 'mm/dd/yyyy' }, { val: 'dd/mm/yyyy', show: 'dd/mm/yyyy' }, { val: 'yyyy/mm/dd', show: 'yyyy/mm/dd' }],
      logo: '',
      defLogo: defLogo,
      name: '',
      address: '',
      default_template: '',
      website: '',
      date_format: '',
      time_format: '',
      selectedUserEmailOptions: [],
      userEmailOptions: [],
      activeUsersList: [],
      is_bba_signed: false,
      bba_signed_date: '',
      baaLink: userData?.account?.baa,
      commission_enabled: false,
      commission_type: '',
      commissionTypeError: false,
      nameErrorClass: 'newInputField',
      dataChanged: false,
      defImageCls: 'no-display',
      cameraInPreviewCls: 'camra-icon dz-clickable no-image',
      uploadedFile: '',
      dzImgObj: {},
      business_name: userData.user.business_name,
      logo_url: '',
      dzCSS: '',
      dbBusinessName: '',
      showLoader: false,
      accountsScopes: 'accountPreference,activeUsers,accountSubscription,account_business_info',
      client_replacement_text: 'Client',
      notification_msg: "business_information_updated",
      supportPIN: '',
      userData: (localStorage.getItem('userData')) ? JSON.parse(localStorage.getItem('userData')) : null,
      address_Error: false,
      gcity_error: false,
      gstate_Error: false,
      country_error: false,
      zipcode_error: false,

      length_unit: LENGTH_UNITS.inch,
      length_unit_error: false,

      temperature_unit: TEMPERATURE_UNITS.fahrenheit,
      temperature_unit_error: false,

      weight_unit: WEIGHT_UNITS.pound,
      weight_unit_error: false,

      geoLocationClass: "googleLocationInput",
      gstate: '',
      gcity: '',
      zipcode: '',
      country_name: '',
      baaLink: "",
      termsLink: "",
      newBaaLink: "",
      newBaaDocumentSignDate: "",
      newTermsLink: "",
      newTermSignDate: "",
      newAgreementsStartDate: "2025-03-20",
      createdDate: "",
    }
    window.onscroll = () => {
      return false;
    }

  }

  handleInputChange = (event) => {
    let returnState = { dataChanged: true }
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    //this.setState({ [event.target.name]: value, dataChanged: true });
    if (target.name === 'commission_enabled' && !value) {
      returnState.commission_type = '';
      returnState.commissionTypeError = false
    } else if (target.name === 'commission_type' && this.state.commission_enabled) {
      returnState.commissionTypeError = false
    }
    returnState[event.target.name] = value;
    this.setState(returnState)
  }
  handleSelectChange = (selectedUserEmailOptions) => {
    this.setState({
      selectedUserEmailOptions: selectedUserEmailOptions,
      userChanged: true
    });
  }

  saveClientLabelToLocalStorage = (nextLabel) => {
    const prevLangData = JSON.parse(localStorage.getItem(
      LOCAL_STORAGE_KEYS.languageData
    )) || {};
    localStorage.setItem(LOCAL_STORAGE_KEYS.languageData, JSON.stringify({
      ...prevLangData,
      global: {
        ...(prevLangData?.global || {}),
        header_clients: nextLabel,
      },
    }));
  }

  handleSubmit = (event) => {
    const languageData = JSON.parse(localStorage.getItem('languageData'));
    event.preventDefault();
    //====Frontend validation=================
    let error = false;
    this.setState({
      nameError: false,
      address_Error: false,
      gcity_error: false,
      gstate_Error: false,
      country_error: false,
      zipcode_error: false,
      geoLocationClass: "googleLocationInput",
    });

    let address = document.getElementById('new-input').value;

    if (typeof this.state.name === undefined || this.state.name === null || this.state.name === '') {
      this.setState({
        nameError: true,
        nameErrorClass: 'newInputField field_error'
      })
      error = true
    } else if (this.state.name) {
      this.setState({
        nameError: false,
        nameErrorClass: 'newInputField'
      })

      if (this.state.commission_enabled && (typeof this.state.commission_type === undefined || this.state.commission_type === null || this.state.commission_type === '')) {
        this.setState({ commissionTypeError: true })
        error = true
      } else if (this.state.commission_type) {
        this.setState({ commissionTypeError: false })
      }


      if (typeof address == undefined || address == null || address == '' || address.trim() == "") {
        this.setState({
          address_Error: true
        })
        error = true;
      } else {
        this.setState({
          address_Error: false
        })
      }

      if (typeof this.state.gstate === undefined || this.state.gstate === null || this.state.gstate === '' || this.state.gstate.trim() == "") {
        this.setState({
          gstate_Error: true
        })
        error = true;
      } else {
        this.setState({
          gstate_Error: false
        })
      }


      if (typeof this.state.zipcode === undefined || this.state.zipcode === null || this.state.zipcode === '' || this.state.zipcode.trim() == "") {
        this.setState({
          zipcode_error: true
        })
        error = true;
      } else {
        this.setState({
          zipcode_error: false
        })
      }

      if (typeof this.state.gcity == undefined || this.state.gcity == null || this.state.gcity == '' || this.state.gcity.trim() == '') {
        this.setState({
          gcity_error: true
        })
        error = true;
      } else {
        this.setState({
          gcity_error: false
        })
      }

      if (typeof this.state.country_name == undefined || this.state.country_name == null || this.state.country_name == '' || this.state.country_name.trim() == '' || this.state.country_name == 'select') {
        this.setState({
          country_error: true
        })
        error = true;
      } else {
        this.setState({
          country_error: false
        })
      }

      const isLengthUnitError = !Object.values(LENGTH_UNITS).includes(this.state.length_unit);
      const isTemperatureUnitError = !Object.values(TEMPERATURE_UNITS).includes(this.state.temperature_unit);
      const isWeightUnitError = !Object.values(WEIGHT_UNITS).includes(this.state.weight_unit);

      error = isLengthUnitError || isTemperatureUnitError || isWeightUnitError;

      this.setState({
        length_unit_error: isLengthUnitError,
        temperature_unit_error: isTemperatureUnitError,
        weight_unit_error: isWeightUnitError,
      });

      if (error) {
        return;
      }

      let formData = {
        notification_msg: this.state.notification_msg,
        name: this.state.name,
        address: this.state.address,
        default_template: (this.state.default_template) ? this.state.default_template : 'cosmetic',
        website: this.state.website,
        date_format: this.state.date_format,
        time_format: this.state.time_format,
        logo: this.state.logo,
        monthly_sales_email_receivers: '', //selectedUserEmailOptions.join(','),
        commission_enabled: (this.state.commission_enabled) ? 1 : 0,
        commission_type: (this.state.commission_enabled) ? this.state.commission_type : '',
        client_replacement_text: this.state.client_replacement_text,
        length_unit: this.state.length_unit,
        temperature_unit: this.state.temperature_unit,
        weight_unit: this.state.weight_unit,
      }

      let account_business_info = {
        address: address,
        city: this.state.gcity,
        state: this.state.gstate,
        country: this.state.country_name,
        zipcode: this.state.zipcode,
        suite_number: this.state.suite_number,
      }

      formData.account_business_info = account_business_info;
      this.setState({ showLoader: true })
      this.props.updateAccountDetails(formData, this.state.accountID)
        .then(res => {
          uiNotification.success(languageData.global[res.message] || "Account Data Has Been Successfully Updated");
          this.saveClientLabelToLocalStorage(formData.client_replacement_text);
          this.fetchAccountDetails();
          lockAppUntilAccountAddressUpdated.remove();
          if (this.props.isAccountOnHold || this.props.isAccountCancelled) {
            window.location.reload();
          }
        })
        .catch(res => {
          uiNotification.error(languageData.global[res.message] || "Unable To Update Account Data");
        })
        .finally(() => {
          this.setState({ showLoader: false });
        });
    }
  }

  handleChildChange = (stateToUpdate) => {
    this.setState(stateToUpdate);
  }


  componentDidUpdate(prevProps) {

    if (prevProps.accountData !== undefined && prevProps.accountData.status === 200 && this.props.accountData !== undefined) {

      if (prevProps.accountData.data.logo_url !== this.props.accountData.data.logo_url) {
        /*this.dropzone.removeFile(this.state.dzImgObj)*/
        if (this.props.accountData.data.logo !== "") {
          this.setState({ defImageCls: 'no-display', cameraInPreviewCls: 'camra-icon dz-clickable camera-in-preview', dzCSS: '', uploadedFile: this.props.accountData.data.logo, logo: this.props.accountData.data.logo, logo_url: this.props.accountData.data.logo_url });
        } else {
          this.setState({ uploadedFile: this.props.accountData.data.logo, logo: this.props.accountData.data.logo, });
        }

        //let mockFile    = {name:this.props.accountData.data.logo_url, accepted: true, size: 7627};
        let reInitData = {};

        reInitData.dzImgObj = this.state.dzImgObj;
        reInitData.mockFile = { name: this.props.accountData.data.logo_url, accepted: true, size: 7627 };
        reInitData.logo_url = this.props.accountData.data.logo_url;
        reInitData.logo = this.props.accountData.data.logo;

        this.refs.child.reInit(reInitData);
        /*let mockFile    = {name:this.props.accountData.data.logo_url, accepted: true, size: 7627};
        this.dropzone.options.addedfile.call(this.dropzone, mockFile);
        this.dropzone.options.thumbnail.call(this.dropzone, mockFile, this.props.accountData.data.logo_url);
        this.dropzone.emit("complete", mockFile);*/
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    console.log("state",props)
    let returnState = {};
    const formatDate = (date) => { // Format date when it comes in php format
      if (date.toLowerCase() === 'd/m/y') {
        return 'dd/mm/yyyy'
      }
      if (date.toLowerCase() === 'm/d/y') {
        return 'mm/dd/yyyy'
      }
      if (date.toLowerCase() === 'y/m/d') {
        return 'yyyy/mm/dd'
      }
      return date.toLowerCase()
    }

    if (props.accountData !== undefined && props.accountData.status === 200 && props.accountData.data != state.accDetails) {
      returnState.showLoader = false
      returnState.accDetails = props.accountData.data;
      returnState.newBaaLink = props?.accountData?.data?.baa_document;
      returnState.newTermSignDate = props?.accountData?.data?.terms_of_service_document_sign_date;
      returnState.newTermsLink = props?.accountData?.data?.terms_of_service_document;
      returnState.newBaaDocumentSignDate = props?.accountData?.data?.baa_document_sign_date;
      returnState.logo = props.accountData.data.logo;
      returnState.logo_url = props.accountData.data.logo_url;
      returnState.name = (state.dataChanged) ? state.name : props.accountData.data.name;
      returnState.default_template = (state.dataChanged) ? state.default_template : props.accountData.data.account_preference.default_template;
      returnState.website = (state.dataChanged) ? state.website : props.accountData.data.website ? props.accountData.data.website : '';
      returnState.date_format = (state.dataChanged) ? state.date_format : formatDate(props.accountData.data.account_preference.date_format);
      returnState.time_format = (state.dataChanged) ? state.time_format : props.accountData.data.account_preference.time_format_original;
      returnState.is_bba_signed = (state.dataChanged) ? state.is_bba_signed : (props.accountData.data.account_preference.is_bba_signed == 1) ? true : false;
      returnState.bba_signed_date = props.accountData.data.account_preference.bba_signed_date;
      returnState.commission_enabled = (state.dataChanged) ? state.commission_enabled : props.accountData.data.account_preference.commission_enabled;
      returnState.commission_type = (state.dataChanged) ? state.commission_type : props.accountData.data.account_preference.commission_type;
      returnState.dbBusinessName = props.accountData.data.name;
      returnState.activeUsersList = (props.accountData.data.active_users != undefined) ? props.accountData.data.active_users : [];

      returnState.client_replacement_text = (state.dataChanged) ? state.client_replacement_text : props.accountData.data.account_preference.client_replacement_text;
      returnState.supportPIN = props.accountData.data.account_subscription.support_security_pin ? props.accountData.data.account_subscription.support_security_pin : '';
      returnState.createdDate = props?.accountData?.data?.created || "";
      let userEmailOptions = [];
      if (returnState.activeUsersList != undefined && returnState.activeUsersList.length) {
        userEmailOptions = returnState.activeUsersList.map((lobj, lidx) => {
          return { value: lobj.id, label: lobj.email_id }
        })
      }
      returnState.userEmailOptions = userEmailOptions;
      if (!state.dataChanged) {
        let selectedUserEmailOptions = [];
        /*
        if(props.accountData.data.account_preference!= undefined && props.accountData.data.account_preference.monthly_sales_email_receivers != undefined && props.accountData.data.account_preference.monthly_sales_email_receivers != '' && props.accountData.data.account_preference.monthly_sales_email_receivers != null  && returnState.activeUsersList.length){
          const monthlySalesEmailReceivers= props.accountData.data.account_preference.monthly_sales_email_receivers.split(',');
          returnState.activeUsersList.map((obj, idx) => {
            if(monthlySalesEmailReceivers.indexOf(obj.email_id) > -1) {
              selectedUserEmailOptions.push({value: obj.id,  label: obj.email_id})
            }
          })
        }
        */
        returnState.selectedUserEmailOptions = selectedUserEmailOptions

        returnState.gcity = (state.dataChanged) ? state.gcity : (props.accountData.data && props.accountData.data.account_business_info) ? props.accountData.data.account_business_info.city : '';
        returnState.gstate = (state.dataChanged) ? state.gstate : (props.accountData.data && props.accountData.data.account_business_info) ? props.accountData.data.account_business_info.state : '';
        returnState.zipcode = (state.dataChanged) ? state.zipcode : (props.accountData.data && props.accountData.data.account_business_info) ? props.accountData.data.account_business_info.zipcode : '';
        returnState.suite_number = (state.dataChanged) ? state.suite_number : (props.accountData.data && props.accountData.data.account_business_info) ? props.accountData.data.account_business_info.suite_number : '';
        returnState.countryList = (props.accountData.data) && props.accountData.data.country_list
        returnState.country_name = (state.dataChanged) ? state.country_name : (props.accountData.data && props.accountData.data.account_business_info) ? props.accountData.data.account_business_info.country : ''
        document.getElementById('new-input').value = (state.dataChanged) ? state.address : (props.accountData.data && props.accountData.data.account_business_info) ? props.accountData.data.account_business_info.address : '';
      }
      returnState.showLoader = false;
    }
    return returnState;
  }

  fetchAccountDetails = () => {
    this.setState({ showLoader: true });
    this.props.getAccountDetails(this.state.accountID, this.state.accountsScopes)
      .then((res) => {
        const pref = res?.data?.account_preference || {};
        this.setState({
          weight_unit: pref.weight_unit,
          temperature_unit: pref.temperature_unit,
          length_unit: pref.length_unit,
        });
      })
      .finally(() => {
        this.setState({ showLoader: false });
      });
  };

  componentDidMount() {

    const languageData = JSON.parse(localStorage.getItem('languageData'))
    this.setState({
      arAccount_header: languageData.settings['arAccount_header'],
      ar_business_name: languageData.settings['ar_business_name'],
      ar_address: languageData.settings['ar_address'],
      ar_default_timeline: languageData.settings['ar_default_timeline'],
      ar_cosmetic: languageData.settings['ar_cosmetic'],
      ar_health: languageData.settings['ar_health'],
      ar_website_url: languageData.settings['ar_website_url'],
      ar_default_date: languageData.settings['ar_default_date'],
      clinic_Please_Wait: languageData.settings['clinic_Please_Wait'],

      ar_default_time: languageData.settings['ar_default_time'],
      ar_send_monthly_sales_report_to: languageData.settings['ar_send_monthly_sales_report_to'],
      ar_aesthetic_record_terms_of_service: languageData.settings['ar_aesthetic_record_terms_of_service'],
      ar_accepted_on: languageData.settings['ar_accepted_on'],
      ar_not_accepted_yet: languageData.settings['ar_not_accepted_yet'],
      ar_view_terms_of_service: languageData.settings['ar_view_terms_of_service'],
      ar_hipaa_business_associate_addendum: languageData.settings['ar_hipaa_business_associate_addendum'],
      ar_view_hipaa_terms_of_service: languageData.settings['ar_view_hipaa_terms_of_service'],
      ar_payroll_commission_report: languageData.settings['ar_payroll_commission_report'],
      ar_net_value_services_performed: languageData.settings['ar_net_value_services_performed'],
      ar_net_value_services_performed_formula: languageData.settings['ar_net_value_services_performed_formula'],
      ar_net_sales: languageData.settings['ar_net_sales'],
      ar_net_sales_formula: languageData.settings['ar_net_sales_formula'],
      settings_net_sales: languageData.settings['settings_net_sales'],
      ar_gross_sales: languageData.settings['ar_gross_sales'],
      ar_net_profit: languageData.settings['ar_net_profit'],
      settings_twenty_four_hours: languageData.settings['settings_twenty_four_hours'],
      Edit_Appointment_Twelve_Hours: languageData.settings['Edit_Appointment_Twelve_Hours'],
      ar_net_profit_formula: languageData.settings['ar_net_profit_formula'],
      ar_please_select_payroll_commission_report: languageData.settings['ar_please_select_payroll_commission_report'],
    })
    this.fetchAccountDetails();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.accountData !== undefined && prevProps.accountData.status === 200 && this.props.accountData !== undefined) {
      if (prevProps.accountData.data.logo_url !== this.props.accountData.data.logo_url) {
        /*this.dropzone.removeFile(this.state.dzImgObj)*/

        if (this.props.accountData.data.logo !== "") {
          this.setState({ defImageCls: 'no-display', cameraInPreviewCls: 'camra-icon dz-clickable camera-in-preview', dzCSS: '', uploadedFile: this.props.accountData.data.logo, logo: this.props.accountData.data.logo, logo_url: this.props.accountData.data.logo_url });
        } else {
          this.setState({ uploadedFile: this.props.accountData.data.logo, logo: this.props.accountData.data.logo });
        }

        //let mockFile    = {name:this.props.accountData.data.logo_url, accepted: true, size: 7627};
        let reInitData = {};

        reInitData.dzImgObj = this.state.dzImgObj;
        reInitData.mockFile = { name: this.props.accountData.data.logo_url, accepted: true, size: 7627 };
        reInitData.logo_url = this.props.accountData.data.logo_url;
        reInitData.logo = this.props.accountData.data.logo;

        this.refs.child.reInit(reInitData);
        /*let mockFile    = {name:this.props.accountData.data.logo_url, accepted: true, size: 7627};
        this.dropzone.options.addedfile.call(this.dropzone, mockFile);
        this.dropzone.options.thumbnail.call(this.dropzone, mockFile, this.props.accountData.data.logo_url);
        this.dropzone.emit("complete", mockFile);*/
      }
    }
  }

  selectLocation2 = (place) => {
    let zipcode = '';
    let city = '';
    let state = '';
    let country = '';
    let address = '';
    let phoneNumber = '';
    let name = '';
    let inputName = document.getElementById('new-input').value = '';

    if (place.address_components && place.name) {
      // get name
      name = place.name
      // get address
      place.address_components.map((data, index) => {
        //get address

        if (data.types[0] == 'route' || data.types[0] == "street_number" || data.types[0] == 'neighborhood') {
          address = (data.long_name) ? (address + data.long_name + ' ') : '';
        }
        //get city
        if (data.types[0] == "locality" || data.types[0] == "postal_town") {
          city = (data.long_name) ? data.long_name : '';
        }

        if (city == '') {
          if (data.types[0] == 'administrative_area_level_2') {
            city = (data.long_name) ? data.long_name : '';
          }
        }
        //get state
        if (data.types[0] == "administrative_area_level_1") {
          state = (data.short_name) ? data.short_name : '';
        }
        //get country
        if (data.types[0] == "country") {
          country = (data.short_name) ? data.short_name : '';
        }
        //get zipcode
        if (data.types[0] == 'postal_code') {
          zipcode = (data.long_name) ? data.long_name : '';

        }
        if (zipcode == '') {
          if (data.types[0] == 'postal_code_prefix') {
            zipcode = (data.long_name) ? data.long_name : '';
          }
        }

        if (address) {
          inputName = address.trim()
        }
        document.getElementById('new-input').value = inputName;
      });

      this.setState({
        gcity: city,
        gstate: state,
        country_name: country,
        address: address.trim(),
        zipcode: zipcode
      });

    }
  }

  checkAgreementDate = () => {
    const startDate = new Date(this.state.newAgreementsStartDate)
    const currentCreateDate = new Date(this.state?.createdDate)
    return currentCreateDate > startDate 
  };

  render() {
    let optData = '';
    if (this.state.accDetails.account_preference !== undefined) {
      optData = this.state.dateFormats.map((dateFormat) => {
        return <option key={dateFormat.val} value={dateFormat.val}>{dateFormat.show}</option>;
      })
    }
    
    return (
      <div className="main protected">
        <div id="content">
          <div className="container-fluid content setting-wrapper">
            <Sidebar />
            <div className="setting-setion">
              <form id="ar-account-form" name="ar-account-form" className="nobottommargin" action="#" method="post" onSubmit={this.handleSubmit}>
                <div className="setting-container p-b-0">
                  <div className="setting-title m-b-40">{this.state.arAccount_header}</div>

                  {Boolean(lockAppUntilAccountAddressUpdated.get()) && (
                    <div className="row m-b-20">
                      <AlertBox>
                        Please update your business address to avoid an interruption in your monthly billing.
                      </AlertBox>
                    </div>
                  )}

                  <div className="row">

                    <div className="col-xs-12 profile-detail-left m-b-0">

                      <div className="main-profile-picture">
                        {this.state.dbBusinessName != '' && <FileUploader type='logo' uploadedFileName={'logo'} loader={"true"} fileUrl={'logo_url'} logo={this.state.logo} logo_url={this.state.logo_url} defLogo={defLogo} handleChildChange={this.handleChildChange} ref="child" containerClass={'dropzone-holder'} />}

                      </div>

                      <div className="row">
                        <div className="col-sm-6 col-xs-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{this.state.ar_business_name}<span className="setting-require">*</span></div>
                            <input name="name" id="name" className={this.state.nameError === true ? this.state.nameErrorClass : this.state.nameErrorClass} value={this.state.name} autoComplete="off" type="text" onChange={this.handleInputChange} />
                          </div>
                        </div>

                        <div className="col-sm-6 col-xs-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{`Business Website`}<span className="setting-require"></span></div>

                            <div className="setting-input-outer">
                              <input name="website" id="website" className="newInputField" value={this.state.website} maxLength="500" autoComplete="off" type="text" onChange={this.handleInputChange} />
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-6 col-xs-12 no-display">
                          <label className="newInputLabel m-t-15 m-b-0">{this.state.ar_default_timeline}</label>
                          <div className="radio-outer m-b-0">

                            <input type="radio" name="default_template" value="cosmetic" id="cosmetic" checked={this.state.default_template === 'cosmetic'} onChange={this.handleInputChange} />
                            <label htmlFor="cosmetic">{this.state.ar_cosmetic}</label>
                            <input type="radio" name="default_template" value="health" id="health" checked={this.state.default_template === 'health'} onChange={this.handleInputChange} />
                            <label htmlFor="health">{this.state.ar_health}</label>

                          </div>
                        </div>

                        <div className="col-sm-6 col-xs-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{this.state.ar_default_time}</div>
                            <div className="setting-input-outer">
                              <select value={this.state.time_format} name="time_format" id="time_format" className="newSelectField" onChange={this.handleInputChange}>
                                <option value="12 hours">{this.state.Edit_Appointment_Twelve_Hours}</option>
                                <option value="24 hours">{this.state.settings_twenty_four_hours}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 col-xs-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{this.state.ar_default_date}</div>
                            <div className="setting-input-outer">
                              <select value={this.state.date_format} name="date_format" id="date_format" className="newSelectField" onChange={this.handleInputChange}>
                                {optData}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-6 col-xs-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">
                              Length Unit
                              <span className="setting-require">*</span>
                            </div>
                            <div className={this.state.geoLocationClass}>
                              <select
                                onChange={this.handleInputChange}
                                name="length_unit"
                                id="length_unit"
                                className={(this.state.length_unit_error) ? "newSelectField field_error" : "newSelectField"}
                                value={this.state.length_unit}
                              >
                                {LENGTH_UNIT_OPTIONS.map((o) => (
                                  <option key={o.value} value={o.value}>{o.label}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-6 col-xs-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">
                              Temperature Unit
                              <span className="setting-require">*</span>
                            </div>
                            <div className={this.state.geoLocationClass}>
                              <select
                                onChange={this.handleInputChange}
                                name="temperature_unit"
                                id="temperature_unit"
                                className={(this.state.temperature_unit_error) ? "newSelectField field_error" : "newSelectField"}
                                value={this.state.temperature_unit}
                              >
                                {TEMPERATURE_UNIT_OPTIONS.map((o) => (
                                  <option key={o.value} value={o.value}>{o.label}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-6 col-xs-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">
                              Weight Unit
                              <span className="setting-require">*</span>
                            </div>
                            <div className={this.state.geoLocationClass}>
                              <select
                                onChange={this.handleInputChange}
                                name="weight_unit"
                                id="weight_unit"
                                className={(this.state.weight_unit_error) ? "newSelectField field_error" : "newSelectField"}
                                value={this.state.weight_unit}
                              >
                                {WEIGHT_UNIT_OPTIONS.map((o) => (
                                  <option key={o.value} value={o.value}>{o.label}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>

                        {((this.state.userData && this.state.userData.user_type === 'superadmin') || checkIfPermissionAllowed('show-support-pin')) && <div className="col-sm-6 col-xs-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">Support PIN</div>
                            <div className="setting-input-outer">
                              <input name="supportPIN" id="supportPIN" className="newInputField" value={this.state.supportPIN} maxLength="500" autoComplete="off" type="text" readOnly={true} />
                            </div>
                          </div>
                        </div>}

                        <div className="col-sm-6 col-xs-12">
                          <label className="newInputLabel m-t-15 m-b-0">{`What you will call your clients?`}</label>
                          <div className="radio-outer m-b-0">

                            <input type="radio" name="client_replacement_text" value="Patient" id="patient_rad" checked={this.state.client_replacement_text === 'Patient'} onChange={this.handleInputChange} />
                            <label htmlFor="patient_rad">Patient</label>
                            <input type="radio" name="client_replacement_text" value="Client" id="client_rad" checked={this.state.client_replacement_text === 'Client'} onChange={this.handleInputChange} />
                            <label htmlFor="client_rad">Client</label>

                          </div>
                        </div>

                        <div className='col-sm-6 col-xs-12'>
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">Address<span className="setting-require">*</span></div>
                            <Autocomplete
                              id='new-input'
                              placeholder='Search Address'
                              className={this.state.address_Error == true ? 'newInputField field_error' : 'newInputField'}
                              onPlaceSelected={(place) => this.selectLocation2(place)}
                              types={[]}
                              fields={['address_component', 'formatted_address', 'geometry', 'name', 'place_id', 'plus_code', 'type', 'formatted_phone_number', 'international_phone_number', 'opening_hours', 'website']}
                            />
                          </div>
                        </div>

                        <div className='col-sm-6 col-xs-12'>
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{"City"}<span className="setting-require">*</span></div>
                            <input name="gcity" id="gcity" className={this.state.gcity_error === true ? "newInputField field_error" : "newInputField"} autoComplete="off" placeholder="City" maxLength="255" type="text" value={this.state.gcity} onChange={this.handleInputChange} />
                          </div>
                        </div>

                        <div className="col-sm-6 col-xs-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{"State"}<span className="setting-require">*</span></div>
                            <input name="gstate" id="gstate" className={this.state.gstate_Error === true ? "newInputField field_error" : "newInputField"} autoComplete="off" placeholder="State" maxLength="255" type="text" value={this.state.gstate} onChange={this.handleInputChange} />
                          </div>
                        </div>

                        <div className="col-sm-6 col-xs-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">{"Country"}<span className="setting-require">*</span></div>

                            <select onChange={this.handleInputChange} name="country_name" id="country_name" className={(this.state.country_error) ? "newSelectField field_error" : "newSelectField"} placeholder="Country" value={this.state.country_name}>
                              <option value="select">Select Country</option>
                              {this.state.countryList !== undefined &&
                                this.state.countryList.map((obj, idx) =>
                                  <option value={obj.country_code} key={idx}>{obj.country_name}</option>
                                )}
                            </select>
                          </div>
                        </div>

                        <div className="col-sm-6 col-xs-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">Zipcode<span className="setting-require">*</span></div>
                            <div className={this.state.geoLocationClass}>
                              <input type='text' name='zipcode' className={this.state.zipcode_error == true ? 'newInputField field_error' : 'newInputField'} placeholder="Zipcode" value={this.state.zipcode} onChange={this.handleInputChange} />
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-6 col-xs-12">
                          <div className="newInputFileldOuter">
                            <div className="newInputLabel">Suite Number</div>
                            <div className={this.state.geoLocationClass}>
                              <input type='text' name='suite_number' className={this.state.suite_number_error == true ? 'newInputField field_error' : 'newInputField'} placeholder="Suite Number" value={this.state.suite_number || ""} onChange={this.handleInputChange} />
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="setting-container p-t-10 p-b-10">
                  <div className="row">
                    <div className="col-lg-12 termsServices">
                      {this.state.is_bba_signed && 
                      this.state.newTermSignDate && 
                      this.state.is_bba_signed && 
                      !this.checkAgreementDate() && <div className="p-t-5 p-b-5">
                        <AppTranslation.Common path="label.termsUpdated" />
                        </div>}
                      {this.state.accountID === ACCOUNT_ID_WITH_NEW_TERMS ? (
                        <>
                          {this.state.is_bba_signed && this.state.accDetails?.account_type == "paid" && (
                            <a href={EXTERNAL_FILE_LINKS.termsFor17041Acc} target="_blank">
                              <div className="easy-link no-padding pull-left m-l-0">{this.state.ar_aesthetic_record_terms_of_service} ( {this.state.is_bba_signed ? this.state.ar_accepted_on + ' :  ' + this.state.business_name + ' ' + showFormattedDate(this.state.userData.account.created, false) : this.state.ar_not_accepted_yet}) </div>
                            </a>
                          )}
                        </>
                      ) : (
                        <>
                          {/* Old agreements */}
                          {this.state.is_bba_signed && !this.checkAgreementDate() && this.state.accDetails?.account_type == "paid" && 
                            <a href="/settings/hipaa-terms-of-use" target="_blank">
                              <div className="easy-link no-padding pull-left m-l-0">{this.state.ar_hipaa_business_associate_addendum} ( {this.state?.is_bba_signed ? this.state.ar_accepted_on + ' :  ' + this.state.business_name + ' ' + showFormattedDate(this.state.userData.account.created, false) : this.state.ar_not_accepted_yet}) </div>
                            </a>
                          }
                          {this.state.is_bba_signed && !this.checkAgreementDate() && this.state.accDetails?.account_type == "paid" && 
                            <a href="/settings/ar-terms-of-use" target="_blank">
                              <div className="easy-link no-padding pull-left m-l-0">{this.state.ar_aesthetic_record_terms_of_service} ( {this.state?.is_bba_signed ? this.state.ar_accepted_on + ' :  ' + this.state.business_name + ' ' + showFormattedDate(this.state.userData.account.created, false) : this.state.ar_not_accepted_yet}) </div>
                            </a>
                          }
                          {/* **************************/}
                          {/* New trial agreemets*/}
                          {this.state.is_bba_signed && this.checkAgreementDate() && this.state.accDetails?.account_type == "trial" && 
                            <a href={AGREEMENT_LINK.baa} target="_blank">
                              <div className="easy-link no-padding pull-left m-l-0"><AppTranslation.Common path="label.baaAgreement" /> ( {this.state?.is_bba_signed ? this.state.ar_accepted_on + ' :  ' + this.state.business_name + ' ' + showFormattedDate(this.state.userData.account.created, false) : this.state.ar_not_accepted_yet}) </div>
                            </a>
                          }
                          {this.state.is_bba_signed && this.checkAgreementDate() && this.state.accDetails?.account_type == "trial" && 
                            <a href={AGREEMENT_LINK.termsOfService} target="_blank">
                              <div className="easy-link no-padding pull-left m-l-0">{this.state.ar_aesthetic_record_terms_of_service} ( {this.state?.is_bba_signed ? this.state.ar_accepted_on + ' :  ' + this.state.business_name + ' ' + showFormattedDate(this.state.userData.account.created, false) : this.state.ar_not_accepted_yet}) </div>
                            </a>
                          }
                          {/****************************/}
                          {this.state.newBaaDocumentSignDate && this.state.newBaaLink && this.state.accDetails?.account_type == "paid" && 
                            <a href={this.state.newBaaLink}>
                              <div className="easy-link no-padding pull-left"><AppTranslation.Common path="label.baaAgreement" /> ({this.state?.newBaaDocumentSignDate ? this.state.ar_accepted_on + ' :  ' + this.state.business_name + ' ' + showFormattedDate(this.state.newBaaDocumentSignDate) : ""})
                            </div>
                            </a>
                          }
                          {this.state.newTermSignDate && this.state.newTermsLink && this.state.accDetails?.account_type == "paid" && 
                            <a href={this.state.newTermsLink}>
                              <div className="easy-link no-padding pull-left">{this.state.ar_aesthetic_record_terms_of_service} ({this.state?.newTermSignDate ? this.state.ar_accepted_on + ' :  ' + this.state.business_name + ' ' + showFormattedDate(this.state.newTermSignDate) : ""})
                            </div>
                            </a>
                          }
                        </>
                      )}
                    </div>
                    <div className="col-lg-12 d-flex">
                      <TaxFreeStatus />
                    </div>
                  </div>
                </div>
                <div className="footer-static">
                  <input className="new-blue-btn pull-right" type="submit" value="Save" id="save-profile" />
                </div>
              </form>
            </div>
            <Loader showLoader={this.state.showLoader} isFullWidth={true} />
          </div>

        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const returnState = {
    isAccountOnHold: state.AccountHoldRedirectReducer.isAccountOnHold,
    isAccountCancelled: state.AccountHoldRedirectReducer.isAccountCancelled,
  };

  if (state.SettingReducer.action === "ACCOUNT_GET") {
    if (state.SettingReducer.data.status != 200) {
      returnState.showLoader = false
    }
    else {
      returnState.accountData = state.SettingReducer.data
    }
  }
  else if (state.SettingReducer.action === "ACCOUNT_PUT") {
    if (state.SettingReducer.data.status == 200) {
      returnState.accountData = state.SettingReducer.data
    }
    returnState.showLoader = false;
  }
  return returnState;
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getAccountDetails: getAccountDetails, updateAccountDetails: updateAccountDetails }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Araccount);
