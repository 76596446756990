import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Select } from "../../../../../../shared/Select/Select";
import { Button } from "../../../../../../shared/Button/Button";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";

const CardOnFile = ({ cardsOptions, showLoader, onSubmit }) => {
  const { values, errors, handleSubmit, setFieldValue } = useFormik({
    validationSchema: yup.object({
      selectedCard: yup.object().required(),
    }),
    initialValues: {
      selectedCard: null,
    },
    onSubmit: () =>
      onSubmit(new Event("submit"), {
        cardOnFileId: values.selectedCard?.value,
      }),
  });

  return (
    <div className="flex flex-col gap-16">
      <Select
        options={cardsOptions}
        value={values.selectedCard}
        onChange={(option) => setFieldValue("selectedCard", option)}
        isError={Boolean(errors.selectedCard)}
      />
      <Button
        size="small"
        className="w-fit"
        onClick={handleSubmit}
        leftAdornment={
          showLoader ? <CircularProgress size="tiny" color="white" /> : null
        }
        isDisabled={showLoader}
      >
        Save
      </Button>
    </div>
  );
};

export default CardOnFile;
