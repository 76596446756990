import React from "react";
import { ConfirmModal } from "../../../../../../../boxes/ConfirmModal/ConfirmModal";

const CreateClinicAdditionalSurgesDelete = ({
  deleteModal,
  setDeleteModal,
  additionalTaxes,
  setAdditionalTaxes,
}) => {
  const handleDelete = () => {
    const updatedAdditionalTaxes = [...additionalTaxes];
    updatedAdditionalTaxes.splice(deleteModal.id, 1);
    setAdditionalTaxes(updatedAdditionalTaxes);
    setDeleteModal({ show: false, id: null });
  };

  return (
    <ConfirmModal
      isOpen
      onClose={() => setDeleteModal({ show: false, id: null })}
      onCancel={() => setDeleteModal({ show: false, id: null })}
      onConfirm={handleDelete}
    >
      Are you sure you want to remove this tax?
    </ConfirmModal>
  );
};

export default CreateClinicAdditionalSurgesDelete;
