import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";

var requestSchema = yup.object({
  productIds: yup.array().of(yup.number()).required(),
});

export function useValidateProductsTaxMutation(options = {}) {
  return useMutation((dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    return http.post(HTTP_ENDPOINTS.validateProductsTax(), {
      product_ids: req.productIds,
    });
  }, options);
}
