import React from "react";
import { connect } from "react-redux";
import commonClasses from "../styles.module.scss";
import { Checkbox } from "../shared/Checkbox/Checkbox";
import { SaveButton } from "../shared/SaveButton/SaveButton";
import { Input } from "../shared/Input/Input";
import { ClinicRow } from "../shared/ClinicRow/ClinicRow";
import { productPricingRegular } from "../../../../../../store/inventory/productPricingRegular";
import { dispatch } from "../../../../../../store/store";
import { useInit } from "./hooks/useInit";
import { ContentLoader } from "../../../../../../boxes/ContentLoader/ContentLoader";
import { isValidPrice } from "../../../../../../store/inventory/utilities";

const { selectors, actions } = productPricingRegular;

const mapStateToProps = (state) => ({
  forAllClinics: selectors.selectForAllClinics(state),
  clinics: selectors.selectClinics(state),
  getPerUnitPrice: selectors.selectPerUnitPrice(state),
  isInitialized: selectors.selectInitialized(state),
});

export const PricingRegular = connect(mapStateToProps)(
  ({
    productId,
    initData,
    errors,
    onSave,
    isSaving,
    perUnitError,
    setPerUnitError,
    perClinicErrors,
    setPerClinicErrors,
    forAllClinics,
    clinics,
    getPerUnitPrice,
    isInitialized,
  }) => {
    var { isLoading } = useInit(initData, isInitialized);

    var handleChange =
      (clinicId = undefined) =>
      (e) => {
        var value = e.target.value;

        dispatch(
          actions.changePrice({
            value,
            clinicId,
          }),
        );

        var isError = !isValidPrice(value);

        if (clinicId) {
          setPerClinicErrors((prev) => prev.set(clinicId, isError));
        } else {
          setPerUnitError(isError);
        }
      };

    return (
      <ContentLoader isLoading={isLoading}>
        <div className="row">
          <div className="col-xs-12">
            <div className={commonClasses.wrap}>
              <h4>Product Price</h4>
              <div className={commonClasses.right}>
                <div className="flex-col gap-8">
                  <Checkbox
                    label="Same for All Clinics"
                    checked={forAllClinics}
                    onChange={() => {
                      dispatch(actions.setForAllClinics(!forAllClinics));
                    }}
                  />
                </div>
                {Boolean(productId) && (
                  <SaveButton noMargin isLoading={isSaving} onClick={onSave} />
                )}
              </div>
            </div>
            {forAllClinics ? (
              <div className="row">
                <div className="col-sm-4 col-xs-12">
                  <Input
                    required
                    label="Regular Product Price Per Unit"
                    placeholder="Regular Product Price Per Unit"
                    value={getPerUnitPrice()}
                    isError={!!errors?.perUnitError || perUnitError}
                    onChange={handleChange()}
                  />
                </div>
              </div>
            ) : (
              clinics.map((clinic) => (
                <ClinicRow label={clinic.name} key={clinic.id}>
                  <div className="col-sm-4 col-xs-12">
                    <Input
                      required
                      label="Regular Product Price Per Unit"
                      placeholder="Regular Product Price Per Unit"
                      value={getPerUnitPrice(clinic.id)}
                      isError={
                        !!errors?.perClinicErrors?.includes?.(clinic.id) ||
                        perClinicErrors.get(clinic.id)
                      }
                      onChange={handleChange(clinic.id)}
                    />
                  </div>
                </ClinicRow>
              ))
            )}
          </div>
        </div>
      </ContentLoader>
    );
  },
);
