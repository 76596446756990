import React from "react";
import cx from "clsx";

export function Input({
  required,
  label,
  value,
  placeholder,
  onChange,
  isError,
}) {
  return (
    <div className="newInputFileldOuter">
      <div className="newInputLabel">
        {label}
        {required && <span className="setting-require">*</span>}
      </div>
      <input
        type="text"
        className={cx("newInputField", {
          "field-error": isError,
        })}
        autoComplete="off"
        value={isNaN(value) ? "" : value}
        placeholder={placeholder}
        onChange={onChange}
      />
    </div>
  );
}
