import { ACTION_TYPES } from "./config";

export const actions = {
  /**
   * @param {boolean} status
   */
  setForAllClinics: (status) => ({
    type: ACTION_TYPES.setForAllClinics,
    payload: {
      status,
    },
  }),

  /**
   * @param {{
   *    [clinicId: string]: {
   *      perUnit: number;
   *    }
   * }} preparedPrices - result of preparePrices from utilities
   *
   * @param {Array<{
   *    id: number;
   *    clinic_id: number;
   *    price_per_unit: number;
   * }>} raw - raw response from API
   */
  setPrices: (preparedPrices = {}, raw = []) => ({
    type: ACTION_TYPES.setPrices,
    payload: {
      preparedPrices,
      raw,
    },
  }),

  /**
   * @param {{
   *    value: number | string;
   *    clinicId: string | number;
   * }} param0
   */
  changePrice: ({ value, clinicId }) => ({
    type: ACTION_TYPES.changePrice,
    payload: {
      value,
      clinicId,
    },
  }),

  /**
   * @param {boolean} status
   */
  setInitialized: (status) => ({
    type: ACTION_TYPES.setInitialized,
    payload: {
      status,
    },
  }),

  /**
   * @param {Array<{
   *    id: number;
   *    name: string;
   * }>} clinics
   */
  setClinics: (clinics = []) => ({
    type: ACTION_TYPES.setClinics,
    payload: {
      clinics,
    },
  }),

  setOriginalToChangedPrices: () => ({
    type: ACTION_TYPES.setOriginalToChangedPrices,
  }),
};
