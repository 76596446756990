import React, { useMemo, useState } from "react";
import NewCard from "./NewCard";
import CardOnFile from "./CardOnFile";
import { Tabs } from "../../../../../../shared/Tabs/Tabs";
import { Modal } from "../../../../../../shared/Modal/Modal";

const ChangeCardModalStripe = ({
  showLoader,
  onSubmit,
  cardsList,
  onClose,
}) => {
  const [selectedTab, setSelectedTab] = useState("newCard");

  const cardsOptions = useMemo(
    () =>
      cardsList.map((card) => ({
        label: `${card.card.brand} ending with ${card.card.last4}`,
        value: card.id,
      })),
    [cardsList],
  );

  return (
    <Modal
      isOpen
      onClose={onClose}
      header={<Modal.Title>Change Card</Modal.Title>}
    >
      <Tabs
        className="m-b-20"
        variant="tiled"
        active={selectedTab}
        options={[
          {
            label: "New Card",
            value: "newCard",
            onClick: () => setSelectedTab("newCard"),
          },
          {
            label: "Card on File",
            value: "cardOnFile",
            onClick: () => setSelectedTab("cardOnFile"),
          },
        ]}
      />
      {selectedTab === "newCard" && (
        <NewCard onSubmit={onSubmit} showLoader={showLoader} />
      )}
      {selectedTab === "cardOnFile" && (
        <CardOnFile
          cardsOptions={cardsOptions}
          showLoader={showLoader}
          onSubmit={onSubmit}
        />
      )}
    </Modal>
  );
};

export default ChangeCardModalStripe;
