import { cond } from "../../../utilities/fp";
import { isNullish } from "../../../utilities/general";
import { isValidPrice } from "../utilities";
import { DEFAULT_MEMBERSHIP } from "./config";

/**
 * @param {{
 *    value: number;
 * }} option
 * @returns {boolean}
 */
export const isDefaultMembership = (option) =>
  option?.value === DEFAULT_MEMBERSHIP.value;

/**
 * @param {Object} param0
 *
 * @param {{
 *    member: number;
 *    perClinic: {
 *      [clinicId: string]: {
 *        member: number;
 *      };
 *    };
 * }} param0.preparedPrices - result of preparePrices function
 *
 * @param {{
 *    member: number;
 *    perClinic: {
 *      [clinicId: string]: {
 *        member: number;
 *      };
 *    };
 * }} param0.initialPreparedPrices - result of preparePrices function
 *
 * @param {boolean} param0.forAllClinics
 *
 * @returns {boolean}
 */
export const isPricesChanged = ({
  preparedPrices,
  initialPreparedPrices,
  forAllClinics,
}) => {
  if (forAllClinics) {
    return (
      String(preparedPrices.member) !== String(initialPreparedPrices.member)
    );
  }

  const createSnapshot = (prices) => {
    return Object.entries(prices).reduce((acc, [clinicId, price]) => {
      return (acc += `#${clinicId}/${price.member}`);
    }, "");
  };

  return (
    createSnapshot(preparedPrices.perClinic) !==
    createSnapshot(initialPreparedPrices.perClinic)
  );
};

/**
 * If selectedMembershipId is provided then filter rawPrices by selectedMembershipId
 *
 * @param {Object} param0
 *
 * @param {Array<{
 *    id: number;
 * }>} param0.clinics
 *
 * @param {Array<{
 *    clinic_id: number;
 *    price_per_unit_members: number;
 *    membership_tier_id: number;
 * }>} param0.rawPrices
 *
 * @param {number?} selectedMembershipId
 *
 * @returns {Record<number, {
 *    member: number;
 *    membershipId: number;
 * }>}
 */
export function preparePrices({ clinics, rawPrices }, selectedMembershipId) {
  const rawPrices_ = cond(
    () => rawPrices,
    [
      (x) => !isNullish(x),
      () =>
        rawPrices.filter((x) => x.membership_tier_id === selectedMembershipId),
    ],
  )(selectedMembershipId);

  return (
    clinics?.reduce((carry, clinic) => {
      const clinicPrice = rawPrices_?.find((x) => x.clinic_id === clinic.id);

      const rawDefault = rawPrices?.find(
        (r) =>
          r.membership_tier_id === DEFAULT_MEMBERSHIP.value &&
          r.clinic_id === clinic.id,
      );

      const defaultPriceForAll = rawDefault?.price_per_unit_members;

      const regularPrice = rawDefault?.price_per_unit;

      const memberPrice = [
        clinicPrice?.price_per_unit_members,
        defaultPriceForAll,
        regularPrice,
        0,
      ].find((x) => !isNullish(x));

      const membershipId =
        selectedMembershipId || clinicPrice?.membership_tier_id || 0;

      return {
        ...carry,
        [clinic.id]: {
          member: memberPrice,
          membershipId,
        },
      };
    }, {}) || {}
  );
}

/**
 * @param {Array<{
 *    id: number;
 *    tier_name: string;
 * }>} tiers
 * @returns {Array<{
 *    label: string;
 *    value: number;
 * }>}
 */
export function prepareMemberships(tiers) {
  const options =
    tiers?.map((tier) => ({
      label: tier.tier_name,
      value: tier.id,
    })) || [];

  return [DEFAULT_MEMBERSHIP, ...options];
}

/**
 * @param {Object} param0
 * @param {Array<{
 *    clinic_id: number;
 *    location_price_per_member: number;
 * }>} param0.requestChangedPrices
 * @param {boolean} param0.forAllClinics
 */
export const validateChangedPricesForRequest = ({
  requestChangedPrices,
  forAllClinics,
}) => {
  if (forAllClinics) {
    return {
      forAllClinicsError: !isValidPrice(
        parseFloat(requestChangedPrices?.[0]?.location_price_per_member),
      ),
      perMemberErrors: [],
    };
  }

  return {
    perMemberErrors: requestChangedPrices
      .map(({ clinic_id, location_price_per_member }) => {
        if (!isValidPrice(parseFloat(location_price_per_member))) {
          return clinic_id;
        }

        return undefined;
      })
      .filter(Boolean),
    forAllClinicsError: false,
  };
};
