import React from "react";
import { uiNotification } from "../../../../../../../services/UINotificationService";
import { useDeleteAdditionalClinicSurges } from "../hooks/useDeleteAdditionalClinicSurges";
import { useQueryClient } from "@tanstack/react-query";
import { ConfirmModal } from "../../../../../../../boxes/ConfirmModal/ConfirmModal";

const DeleteModal = ({ deleteModal, setDeleteModal, clinicId }) => {
  const queryClient = useQueryClient();
  const { mutate: deleteTax, isLoading: isDeleting } =
    useDeleteAdditionalClinicSurges({
      clinicId,
      id: deleteModal.id,
    });

  return (
    <ConfirmModal
      isOpen
      onClose={() => setDeleteModal({ show: false, id: null })}
      onCancel={() => setDeleteModal({ show: false, id: null })}
      onConfirm={() => {
        deleteTax(null, {
          onSuccess: () => {
            setDeleteModal({ show: false, id: null });
            uiNotification.success("Tax Removed Successfully!");
            queryClient.invalidateQueries(["additionalClinicSurges"]);
          },
        });
      }}
      isConfirming={isDeleting}
    >
      Are you sure you want to remove this tax?
    </ConfirmModal>
  );
};

export default DeleteModal;
