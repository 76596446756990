import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestPayloadSchema = yup.object({
  bucketType: yup.string().required(),
  excludeIds: yup.array().of(yup.number()).nullable(),
});

const responseSchema = yup.object({
  categories: yup
    .array()
    .of(
      yup.object({
        category_name: yup.string().required(),
        id: yup.number().required(),
      }),
    )
    .required(),
  products: yup
    .array()
    .of(
      yup.object({
        count_units_by: yup.number().required(),
        id: yup.number().required(),
        product_name: yup.string().required(),
        start_at_unit: yup.number().required(),
      }),
    )
    .required(),
});

const composeResponse = (res) => {
  return {
    categories: res?.data?.data?.category_data || [],
    products: res?.data?.data?.product_data || [],
  };
};

function createProductsAndCategoriesQueryKey(...args) {
  return [QUERY_KEYS.getProductsAndCategories, ...args];
}

export function useProductsAndCategoriesQuery(payload, options = {}) {
  const dto = requestPayloadSchema.validateSync(payload, { strict: true });

  return useQuery(
    createProductsAndCategoriesQueryKey(dto.bucketType, dto.excludeIds),
    async () => {
      const res = await http.post(HTTP_ENDPOINTS.getProductsAndCategories(), {
        bucket_type: dto.bucketType,
        selected_ids: dto.excludeIds,
      });
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
