import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../consts/api";
import { http } from "../../services/HttpService";

const requestSchema = yup.object({
  invoiceId: yup.number().required(),
});

const responseSchema = yup.array().of(
  yup.object({
    id: yup.number().required(),
    procedure_date: yup.string().required(),
    procedure_name: yup.string().required(),
  }),
);

const composeResponse = (res) => {
  return res?.data?.data || [];
};

function createInvoiceUnattachedProceduresQueryKey(...args) {
  return [QUERY_KEYS.getInvoiceUnattachedProcedures, ...args];
}

export function useInvoiceUnattachedProceduresQuery({
  payload,
  options = {},
} = {}) {
  return useQuery(
    createInvoiceUnattachedProceduresQueryKey(payload.invoiceId),
    async () => {
      const req = requestSchema.validateSync(payload, {
        strict: true,
      });
      const res = await http.get(
        HTTP_ENDPOINTS.procedure.getUnattachedForInvoice(req.invoiceId),
      );
      return responseSchema.validateSync(composeResponse(res), {
        strict: true,
      });
    },
    options,
  );
}
