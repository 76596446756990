import * as yup from "yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { http } from "../../../services/HttpService";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../../consts/api";

const requestSchema = yup.object({
  isConnected: yup.boolean().required(),
});

export function useChangeConnectStatusMutation({ onSuccess, onError } = {}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (dto) => {
      const req = requestSchema.validateSync(dto, {
        strict: true,
      });

      return await http.post(
        HTTP_ENDPOINTS.customDomain.changeCustomDomainUseStatus(),
        {
          use_custom_pp_domain: req.isConnected ? 0 : 1,
        },
      );
    },
    onError: () => {
      onError?.();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.customDomains.customDomainUseStatus],
      });
      onSuccess?.();
    },
  });
}
