import React from "react";
import { Heading } from "./shared/Heading/Heading";
import { SubDomainForm } from "./shared/SubDomainForm/SubDomainForm";
import { SubDomainEditor } from "./shared/SubDomainEditor/SubDomainEditor";
import { ExistingSubDomains } from "./ExistingSubDomains/ExistingSubDomains";
import { useGetCustomDomainsQuery } from "../../../../api/customDomain/queries/useGetCustomDomainsQuery";
import { useAddCustomDomainMutation } from "../../../../api/customDomain/mutations/useAddCustomDomainMutation";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { uiNotification } from "../../../../services/UINotificationService";
import { useGetCustomDomainUseStatusQuery } from "../../../../api/customDomain/queries/useGetCustomDomainUseStatusQuery";
import { useChangeConnectStatusMutation } from "../../../../api/customDomain/mutations/useChangeConnectStatusMutation";
import { useQueryParamsState } from "../../../../utilities/hooks/useQueryParamsState";

export function CustomDomain() {
  const { tSmartConfig } = useAppTranslation.SmartConfig();
  const { data: domainsData, isLoading: isGetDomainsLoading } =
    useGetCustomDomainsQuery({
      onError: () => {
        uiNotification.error(
          tSmartConfig("patientPortal.customDomain.error.getSubdomains"),
        );
      },
    });

  const {
    mutateAsync: changeConnectionStatus,
    isLoading: isChangeConnectionLoading,
  } = useChangeConnectStatusMutation({
    onError: () => {
      uiNotification.error("");
    },
  });

  const { data: domainStatus } = useGetCustomDomainUseStatusQuery({
    onError: () => {
      uiNotification.error(
        tSmartConfig("patientPortal.customDomain.error.connectStatus"),
      );
    },
  });

  const isConnected = Boolean(domainStatus?.customDomainStatus);

  const { mutateAsync, isLoading: inAddLoading } = useAddCustomDomainMutation({
    onSuccess: (response) => {
      setCurrentSubDomainEdit(response.id);
    },
    onError: () => {
      uiNotification.error(
        tSmartConfig("patientPortal.customDomain.error.domain"),
      );
    },
  });

  const onChangeConnectionStatus = () => {
    changeConnectionStatus({
      isConnected: isConnected,
    });
  };

  const [currentSubDomainEdit, setCurrentSubDomainEdit] = useQueryParamsState({
    subdomain: "",
  });

  return (
    <div className="appointment-content">
      <Heading
        isConected={isConnected}
        changeConnection={onChangeConnectionStatus}
        isLoading={isChangeConnectionLoading}
      />
      {isConnected && !currentSubDomainEdit.subdomain && (
        <SubDomainForm onAddDomain={mutateAsync} isLoading={inAddLoading} />
      )}
      {isConnected && currentSubDomainEdit.subdomain && (
        <SubDomainEditor
          domainId={currentSubDomainEdit.subdomain}
          onClose={() => setCurrentSubDomainEdit({ subdomain: "" })}
        />
      )}
      {Boolean(domainsData?.length) &&
        isConnected &&
        !currentSubDomainEdit.subdomain &&
        !isGetDomainsLoading && (
          <ExistingSubDomains
            domainsList={domainsData}
            setSubdomain={setCurrentSubDomainEdit}
          />
        )}
    </div>
  );
}
