import React from "react";
import classes from "./EventWrapper.module.scss";
import { DOWNTIME_TYPE, Downtime } from "./shared/Downtime/Downtime";

const toPercent = (n) => {
  if (n) {
    return `${n}%`;
  }
  return 0;
};

const addAlpha = (color, opacity) => {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return color + _opacity.toString(16).toUpperCase();
};

const getDowntimeHeight = ({ apptDuration, downtimeDuration }) => {
  return `${(downtimeDuration / apptDuration) * 100}%`;
};

const DefaultWrapper = ({ style, children, className }) => (
  <div
    className={className || classes.root}
    style={{
      ...style,
      height: toPercent(style.height),
      width: toPercent(style.width),
      top: toPercent(style.top),
      left: toPercent(style.xOffset),
      zIndex: style.zIndex,
    }}
  >
    {children}
  </div>
);

export var getEventWrapper =
  ({ onDowntimeClick } = {}) =>
  ({ style, event, children }) => {
    const style_ = style || {};

    if ("availabilityGrid" in event) {
      if (event.noClickEvents) {
        return (
          <div
            data-tip={event.title}
            className={classes.root}
            style={{
              ...style_,
              height: toPercent(style_.height),
              width: "100%",
              top: toPercent(style_.top),
              left: 0,
              zIndex: event.zIndex,
            }}
          >
            <div className={classes.noInteraction}>{children}</div>
          </div>
        );
      }

      return children;
    }

    if (event.appointmentType === "outofoffice") {
      return (
        <DefaultWrapper style={{ ...style_, zIndex: event.zIndex }}>
          {children}
        </DefaultWrapper>
      );
    }

    var onDowntimeClick_ = () => {
      if (onDowntimeClick) {
        onDowntimeClick(event);
      }
    };

    return (
      <DefaultWrapper
        className={classes.rootWithDowntime}
        style={{
          ...style_,
          border: `1px solid ${event.color}`,
          backgroundColor: addAlpha(event.color, 0.3),
          zIndex: event.zIndex || 2,
          overflow: "hidden",
          // width: event.appointmentType === "break_time" ? 100 : style_.width,
        }}
      >
        <div
          tabIndex="0"
          role="button"
          onKeyDown={() => onDowntimeClick_()}
          onClick={() => onDowntimeClick_()}
          className={classes.downtime}
          style={{
            height: getDowntimeHeight({
              apptDuration: event.duration,
              downtimeDuration: event.downtimes?.prep_time || 0,
            }),
          }}
        >
          <Downtime
            id={event.id}
            showTooltip={true}
            eventColor={event.color}
            type={DOWNTIME_TYPE.prep}
            disableDoubleBook={event?.downtimes?.allow_merge_prep === 0}
            duration={event.downtimes?.prep_time}
          />
        </div>
        {children}
        <div
          tabIndex="0"
          role="button"
          onKeyDown={() => onDowntimeClick_()}
          onClick={() => onDowntimeClick_()}
          className={classes.downtime}
          style={{
            height: getDowntimeHeight({
              apptDuration: event.duration,
              downtimeDuration: event.downtimes?.finish_time || 0,
            }),
          }}
        >
          <Downtime
            id={event.id}
            showTooltip={true}
            eventColor={event.color}
            type={DOWNTIME_TYPE.finish}
            disableDoubleBook={event?.downtimes?.allow_merge_finish === 0}
            bgColor={addAlpha(event.color, 0.3)}
            duration={event.downtimes?.finish_time}
          />
        </div>
        <div
          tabIndex="0"
          role="button"
          onKeyDown={() => onDowntimeClick_()}
          onClick={() => onDowntimeClick_()}
          className={classes.downtime}
          style={{
            height: getDowntimeHeight({
              apptDuration: event.duration,
              downtimeDuration: event.downtimes?.cleanup_time || 0,
            }),
          }}
        >
          <Downtime
            id={event.id}
            showTooltip={true}
            eventColor={event.color}
            type={DOWNTIME_TYPE.cleanup}
            disableDoubleBook={event?.downtimes?.allow_merge_cleanup === 0}
            duration={event.downtimes?.cleanup_time}
          />
        </div>
      </DefaultWrapper>
    );
  };
