import { DEFAULT_MEMBERSHIP } from "./hooks/useMemberships";

export const PRICE_TYPES = {
  inventory: "Inventory",
  variation: "Variation",
  promotion: "Promotion",
};

const inventoryColumns = (isDefaultMembership = false) =>
  [
    {
      Header: "Product",
      accessor: "product_name",
      id: "product_name",
      width: "200px",
      isVisible: true,
    },
    {
      Header: "Category",
      accessor: "category_name",
      id: "category_name",
      width: "140px",
      isVisible: true,
    },
    {
      Header: "Price",
      accessor: "price_per_unit",
      id: "price_per_unit",
      width: "80px",
      type: "price",
      isVisible: true,
    },
    {
      Header: "Member Price",
      accessor: "price_per_unit_members",
      id: "price_per_unit_members",
      width: "80px",
      type: "price",
      isVisible: !isDefaultMembership,
    },
  ].filter((column) => column.isVisible);

const variationColumns = () => [
  {
    Header: "Pricing Variation Name",
    accessor: "name",
    accessor2: "product_name",
    id: "name",
    width: "400px",
    type: "concat",
  },
  {
    Header: "Price",
    accessor: "price",
    accessor2: "product_name",
    id: "price",
    width: "80px",
    type: "pricePerUnit",
  },
];

const promotionColumns = (isDefaultMembership = false) =>
  [
    {
      Header: "Promotion Name",
      accessor: "name",
      id: "name",
      width: "120px",
      isVisible: true,
    },
    {
      Header: "Type",
      accessor: "type",
      id: "type",
      width: "120px",
      type: "formatCells",
      isVisible: true,
    },
    {
      Header: "Amount",
      accessor: "discount_percentage",
      id: "discount_percentage",
      width: "80px",
      type: "formatCells",
      isVisible: true,
    },
    {
      Header: "Price",
      accessor: "package_bogo_price",
      id: "package_bogo_price",
      width: "80px",
      type: "formatCells",
      isVisible: true,
    },
    {
      Header: "Member Price",
      accessor: "package_price_for_members",
      id: "package_price_for_members",
      width: "80px",
      type: "price",
      isVisible: !isDefaultMembership,
    },
  ].filter((column) => column.isVisible);

export const pricelistOptions = [
  {
    label: "Inventory",
    value: "inventory",
  },
  {
    label: "Variations",
    value: "variation",
  },
  {
    label: "Promotions",
    value: "promotion",
  },
];

export const priceListColumns = {
  inventoryColumns,
  variationColumns,
  promotionColumns,
};

export function getPriceListColumns(type, membershipId) {
  var isDefaultMembership = membershipId === DEFAULT_MEMBERSHIP.value;
  return type
    ? priceListColumns[`${type}Columns`](isDefaultMembership)
    : priceListColumns.inventoryColumns(isDefaultMembership);
}
