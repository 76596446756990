import { removeNullishFromShape } from "../../../utilities/general";
import { DEFAULT_MEMBERSHIP } from "../productPricingMember/config";
import { REDUCER_NAME } from "./config";

export const selectors = {
  /**
   * @returns {boolean}
   */
  selectForAllClinics: (state) => state[REDUCER_NAME].forAllClinics,

  /**
   * @returns {() => {
   *    perUnit: number;
   *    perClinic: {
   *      [clinicId: string]: {
   *        perUnit: number;
   *      };
   *    };
   * }}
   */
  selectOriginalPrices: (state) => () =>
    state[REDUCER_NAME]?.prices?.original || {},

  /**
   * @returns {(clinicId: string | number) => string | number}
   */
  selectPerUnitPrice: (state) => (clinicId) => {
    if (clinicId) {
      return state[REDUCER_NAME]?.prices?.changed?.perClinic?.[clinicId]
        ?.perUnit;
    }

    return state[REDUCER_NAME]?.prices?.changed?.perUnit;
  },

  selectChangedPricesForRequest:
    (state) =>
    (isCreateMode = false) => {
      const state_ = state[REDUCER_NAME] || {};
      const raw = state_.prices?.raw || [];
      const changedPrices = state_.prices?.changed || {};
      const forAllClinics = state_.forAllClinics;
      const clinicIds = state_.clinics?.map((c) => c.id) || [];

      const perUnit = (clinicId) =>
        parseFloat(
          forAllClinics
            ? changedPrices.perUnit
            : changedPrices.perClinic?.[clinicId]?.perUnit,
        );

      const member = (clinicId) => {
        if (raw?.length === 0) {
          return perUnit(clinicId);
        }

        const memberPrice = raw.find(
          (r) =>
            r.clinic_id === clinicId &&
            r.membership_tier_id === DEFAULT_MEMBERSHIP.value,
        )?.price_per_unit_members;

        return !parseInt(memberPrice) ? 0 : memberPrice;
      };

      return clinicIds.map((clinicId) =>
        removeNullishFromShape({
          clinic_id: Number(clinicId),
          membership_tier_id: 0,
          location_price_per_unit: perUnit(clinicId),
          location_price_per_member: isCreateMode ? 0 : member(clinicId),
        }),
      );
    },

  selectInitialized: (state) => Boolean(state[REDUCER_NAME].initialized),

  /**
   * @returns {Array<{
   *    id: number;
   *    name: string;
   * }>}
   */
  selectClinics: (state) => state[REDUCER_NAME].clinics || [],

  /**
   * @returns {() => {
   *    perUnit: number | string;
   *    perClinic: {
   *      [clinicId: string]: {
   *        perUnit: number | string;
   *      };
   *    };
   * }}
   */
  selectChangedPrices: (state) => () =>
    state[REDUCER_NAME].prices?.changed || {},
};
