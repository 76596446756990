import React, { useState } from "react";
import { Modal } from "../../../../../../shared/Modal/Modal";
import { Button } from "../../../../../../shared/Button/Button";
import { UnsavedChangesMessage } from "../shared/UnsavedChangesMessage";

export function useConfirmUnsaved() {
  const [promise, setPromise] = useState(null);

  const confirm = () =>
    new Promise((resolve) => {
      setPromise({ resolve });
    });

  const handleClose = () => {
    setPromise(null);
  };

  const handleConfirm = () => {
    promise?.resolve(true);
    handleClose();
  };

  const handleCancel = () => {
    promise?.resolve(false);
    handleClose();
  };

  const ConfirmationDialog = () => (
    <Modal
      size="small"
      isOpen={!!promise}
      footerNoBorder
      shouldCloseOnOverlayClick={false}
      onClose={handleClose}
      header={<Modal.Title>Warning</Modal.Title>}
      footer={
        <div style={{ display: "flex", gap: "8px" }}>
          <Button onClick={handleConfirm}>Save</Button>
          <Button onClick={handleCancel} variant="outlined">
            Cancel
          </Button>
        </div>
      }
    >
      <UnsavedChangesMessage />
    </Modal>
  );

  return [ConfirmationDialog, confirm];
}
