import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "../../../../../shared/Modal/Modal";
import styles from "./styles.module.scss";
import { useProductsAndCategoriesQuery } from "../../../../../api/queries/useProductsAndCategoriesQuery";
import { Loading } from "../../../../../boxes/Loading/Loading";
import { AlertBox } from "../../../../../shared/AlertBox/AlertBox";
import { Input } from "../../../../../shared/Input/Input";
import { useValidateProductsTaxMutation } from "../../../../../api/mutations/useValidateProductsTaxMutation";
import { extractApiError } from "../../../../../utilities/api";
import { uiNotification } from "../../../../../services/UINotificationService";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { Footer } from "./shared/Footer";
import { List } from "./shared/List";

/**
 * @param {{
 *  bucketType: string;
 *  bundleIndex: number;
 *  bundleId: number;
 *  products: Array<{
 *    id: number;
 *    product_name: string;
 *    bundle_id: number;
 *    is_bundle: number;
 *    bundleName: string;
 *  } | {
 *    bundle_id: number;
 *    bundle_name: string;
 *    type: string;
 *  }>;
 *  onSuccess: (products) => void;
 * }} param0
 */
export function EditBundle({
  bucketType,
  bundleIndex,
  bundleId,
  products,
  onSuccess,
}) {
  var { tInventory: t } = useAppTranslation.Inventory();

  var { 0: isOpen, 1: setIsOpen } = useState(false);
  var { 0: name, 1: setName } = useState("");
  var { 0: selectedIds, 1: setSelectedIds } = useState([]);

  const bundle = useMemo(
    () =>
      products.find(
        (p) => p.bundle_id === bundleId && p.type?.toLowerCase() === "bundle",
      ),
    [products, bundleId, isOpen],
  );

  const bundleProducts = useMemo(
    () =>
      products
        .filter((p) => p.is_bundle && p.bundle_id === bundleId)
        .reduce(
          (carry, p) => ({
            ...carry,
            [String(p.id)]: p,
          }),
          {},
        ),
    [products, bundleId, isOpen],
  );

  var {
    data,
    isFetching: isLoading,
    isError,
  } = useProductsAndCategoriesQuery(
    {
      bucketType,
    },
    { staleTime: 0, enabled: isOpen },
  );

  var validate = useValidateProductsTaxMutation({
    onError: (err) => {
      const message = extractApiError(err);
      uiNotification.error(
        t(
          `saveUpdateInventory.apiError.${message}`,
          "Unable to save changes. Try again later",
        ),
      );
    },
  });

  var handleSave = () => {
    var bundleEndIndex = products.findLastIndex(
      (x) => x.bundle_id === bundleId,
    );

    if (selectedIds.length === 0) {
      onSuccess([
        ...products.slice(0, bundleIndex),
        ...products.slice(bundleEndIndex + 1),
      ]);
      setIsOpen(false);
      return;
    }

    validate
      .mutateAsync({
        productIds: selectedIds,
      })
      .then(() => {
        var nextBundle = {
          ...bundle,
          bundle_name: name,
        };

        var productsMap = data.products.reduce(
          (carry, p) => ({ ...carry, [String(p.id)]: p }),
          {},
        );

        var nextProducts = selectedIds.map((id) => {
          const existing = bundleProducts[String(id)];
          const product = productsMap[String(id)];

          return existing
            ? {
                ...existing,
                bundleName: nextBundle.bundle_name,
              }
            : {
                id: id,
                min: product.start_at_unit,
                product_name: product.product_name,
                price_per_unit: "",
                units: "",
                color: "",
                is_bundle: 1,
                bundleName: nextBundle.bundle_name,
                bundle_id: nextBundle.bundle_id,
                db_bundle_id: nextBundle.bundle_id,
              };
        });

        nextBundle.min = Math.max(...nextProducts.map((p) => p.min));

        onSuccess([
          ...products.slice(0, bundleIndex),
          nextBundle,
          ...nextProducts,
          ...products.slice(bundleEndIndex + 1),
        ]);

        setIsOpen(false);
      });
  };

  useEffect(() => {
    if (isOpen) {
      setName(bundle?.bundle_name || "");
      setSelectedIds(Object.values(bundleProducts).map((p) => p.id));
    }
  }, [isOpen, bundle, bundleProducts]);

  return (
    <>
      <button className="easy-link p-l-0" onClick={() => setIsOpen(true)}>
        <i className="fa fa-pencil-alt m-r-5"></i> Edit
      </button>
      {isOpen && (
        <Modal
          isOpen
          footerNoBorder
          onClose={() => setIsOpen(false)}
          header={<Modal.Title>Edit Bundle</Modal.Title>}
          footer={
            <Footer
              onCancel={() => setIsOpen(false)}
              onSave={handleSave}
              isSaving={validate.isLoading}
            />
          }
        >
          {isLoading && <Loading center />}
          {!isLoading && isError && (
            <AlertBox variant="error">
              Unable to retrieve products. Try again later
            </AlertBox>
          )}
          {!isLoading && !isError && (
            <div className={styles.content}>
              <Input
                value={name}
                placeholder="Bundle name"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <List
                products={data?.products || []}
                selectedIds={selectedIds}
                setSelectedIds={setSelectedIds}
              />
            </div>
          )}
        </Modal>
      )}
    </>
  );
}
