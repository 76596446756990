import React from "react";
import { Modal } from "../../../../../../../../shared/Modal/Modal";

export function MemberPriceInfoLabel() {
  const [showInfo, setShowInfo] = React.useState(false);

  return (
    <>
      Member Price
      <button
        onClick={() => setShowInfo(true)}
        className="infoIcon absolute -m-t-5 m-l-3 btn-reset"
      >
        {" "}
        <i className="fa fa-info-circle"></i>
      </button>
      {showInfo && (
        <Modal isOpen onClose={() => setShowInfo(false)}>
          <ul>
            <li>
              If the <b>Membership Pricing</b> in <b>Inventory</b> is set at a
              specific price (not $0), that price will be charged at checkout,
              even if the membership has a *<i>pre-configured discount.</i>
            </li>
            <br />
            <li>
              If the <b>Inventory Member Price</b> is set at $0, the regular
              inventory price will be charged at checkout. If a *
              <i>pre-configured discount</i>
              is included in the membership, the discount price will be applied.
            </li>
            <br />
            <li>
              If a Patient/Client is enrolled in multiple memberships, the
              highest discount will be applied at checkout.
            </li>
            <br />
            <li>
              If the <b>Inventory Member Price</b> is set at $0 and no
              membership discount is configured, the regular inventory pricing
              will be charged at checkout.
            </li>
          </ul>
          <br />
          <div>
            <i>
              *pre-configured discounts can be adjusted under{" "}
              <b>{`Settings > POS >
              Patient/Client Loyalty`}</b>
            </i>
          </div>
        </Modal>
      )}
    </>
  );
}
