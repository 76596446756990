import React from "react";
import { formatCurrency } from "../../../../utilities/general";

const Surcharges = ({ nonTaxableTaxes }) => {
  return (
    <table
      border={0}
      cellPadding={10}
      cellSpacing={0}
      width={700}
      style={{ marginTop: "25px" }}
    >
      <tbody>
        <tr>
          <td
            style={{
              borderBottom: "1px solid #dddddd",
            }}
          >
            <div
              style={{
                fontSize: "13px",
                color: "#000000",
                textTransform: "uppercase",
                padding: "10px 0px",
              }}
            >
              Surcharges
            </div>
          </td>
          <td
            style={{
              borderBottom: "1px solid #dddddd",
            }}
            align="right"
          >
            <div
              style={{
                fontSize: "13px",
                color: "#000000",
                textTransform: "uppercase",
                textAlign: "right",
                padding: "10px 0px",
              }}
            >
              Amount
            </div>
          </td>
        </tr>
        {nonTaxableTaxes.map((tax) => (
          <tr key={tax.tax_id}>
            <td
              style={{
                borderBottom: "1px solid #dddddd",
              }}
            >
              <div
                style={{
                  fontSize: "14px",
                  color: "#777777",
                  padding: "10px 0px",
                }}
              >
                {`Non-taxable "${tax.custom_tax_name}" (${parseInt(
                  tax.custom_tax_percentage,
                ).toFixed()}%)`}
              </div>
            </td>
            <td
              align="right"
              style={{
                borderBottom: "1px solid #dddddd",
                padding: "10px 0px",
              }}
            >
              <div
                style={{
                  fontSize: "13px",
                  color: "#777777",
                  textTransform: "uppercase",
                  textAlign: "right",
                }}
              >
                {formatCurrency(tax.custom_tax)}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Surcharges;
