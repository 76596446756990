import * as yup from "yup";
import React from "react";
import PropTypes from "prop-types";
import { useFormik } from "formik";
import EditorWithPreview from "../../../../Components/Common/EditorWithPreview";
import { getLanguageData } from "../../../../utilities/localStorage";
import { LANGUAGE_DATA_KEYS } from "../../../../consts/localStorage";
import { NOTIFICATION_TYPES } from "../../Event.consts";
import { AppTranslation } from "../../../../i18n/useAppTranslation";

const schema = yup.object({
  type: yup.string().required(),
  body: yup.string(),
});

const AppointmentNotificationModal = ({ notification, onClose, onSubmit }) => {
  const appointmentLang = getLanguageData(LANGUAGE_DATA_KEYS.appointments);

  const { doNotEmail, doNotSms } = notification;

  const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
    initialValues: {
      type: NOTIFICATION_TYPES.emailAndSms,
      body: notification.emailText,
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit,
  });

  const displayClientDndPreferences = () => {
    if (values.type === NOTIFICATION_TYPES.email && doNotEmail) {
      return (
        <div className="profile-header-red m-b-10">
          This patient has opted out of receiving email messages.
        </div>
      );
    }

    if (values.type === NOTIFICATION_TYPES.sms && doNotSms) {
      return (
        <div className="profile-header-red m-b-10">
          This patient has opted out of receiving text messages.
        </div>
      );
    }

    if (NOTIFICATION_TYPES.emailAndSms && (doNotEmail || doNotSms)) {
      let message;
      if (doNotEmail && doNotSms) {
        message = "text and email";
      } else if (doNotEmail) {
        message = "email";
      } else if (doNotSms) {
        message = "text";
      }

      return (
        <div className="profile-header-red m-b-10">
          This patient has opted out of receiving {message} messages.
        </div>
      );
    }

    return null;
  };

  return (
    <div className="blackOverlay">
      <div className="vert-middle">
        <div className="loyaltyMembership start-program-main">
          <div className="newPopupTitle">
            <div className="popup-name">
              {appointmentLang.appointment_send_notification}
            </div>
            <button onClick={onClose} className="popupClose">
              <img src="/images/close.png" alt="" />
            </button>
          </div>
          <div className="newPopupContent">
            <div className="juvly-subtitle m-b-10">
              {appointmentLang.appointment_your} {notification.type}{" "}
              {appointmentLang.appointment_successfully}
            </div>
            <div className="row">
              <div className="col-xs-12">
                <div className="simpleField">
                  <div className="simpleLabel">
                    {notification.patientName}{" "}
                    {appointmentLang.appoinment_will_be_notified}:
                  </div>
                  <select
                    className="simpleSelect"
                    name="type"
                    onChange={handleChange}
                    value={values.type}
                  >
                    <option value={NOTIFICATION_TYPES.email}>
                      {appointmentLang.appointment_email}
                    </option>
                    <option value={NOTIFICATION_TYPES.sms}>
                      {appointmentLang.appointment_sms}
                    </option>
                    <option value={NOTIFICATION_TYPES.emailAndSms}>
                      {appointmentLang.appointment_both}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            {displayClientDndPreferences()}
            <p className="p-text">
              {appointmentLang.appointment_notification_message}
              <br />
              <AppTranslation.Appointments path="appointmentTopNotification" />
            </p>
            <div className={"newInputFileldOuter m-b-40"}>
              <EditorWithPreview
                name="body"
                placeholder="Email Body"
                value={values.body}
                className="editor_textarea editor-preview"
                handleEditorChange={({ body }) => setFieldValue("body", body)}
              />
            </div>
          </div>
          <div className="footer-static no-border no-padding">
            <button
              className="new-red-btn pull-left no-width"
              onClick={onClose}
            >
              {appointmentLang.appointment_dont_send_notification}
            </button>
            <button className="new-blue-btn pull-right" onClick={handleSubmit}>
              {appointmentLang.appointment_send_notification}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

AppointmentNotificationModal.propTypes = {
  notification: PropTypes.shape({
    appointmentId: PropTypes.number,
    emailText: PropTypes.string,
    sms: PropTypes.string,
    subject: PropTypes.string,
    type: PropTypes.string,
    patientName: PropTypes.string,
    doNotEmail: PropTypes.bool,
    doNotSms: PropTypes.bool,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AppointmentNotificationModal;
