import { useProcedureAttachInvoiceMutation } from "../../../../../api/procedure/useProcedureAttachInvoiceMutation";
import { uiNotification } from "../../../../../services/UINotificationService";
import { extractApiError } from "../../../../../utilities/api";

var MESSAGES = {
  success: "Invoice has been successfully attached to the procedure",
  invoice_not_found: "Invoice not found",
  procedure_not_found: "Procedure not found",
  already_attached: "The invoice is already attached to the procedure",
  not_saved:
    "An error occurred while attaching the invoice to the procedure. Try again later",
};

export function useSubmit(onSuccess) {
  var { mutateAsync, isLoading } = useProcedureAttachInvoiceMutation({
    onSuccess: () => {
      uiNotification.success(MESSAGES.success);
      onSuccess();
    },
    onError: (error) => {
      var message = extractApiError(error);
      uiNotification.error(MESSAGES[message] || MESSAGES.not_saved);
    },
  });

  return {
    initiate: async ({ invoiceId, procedureId }) => {
      await mutateAsync({ invoice_id: invoiceId, procedure_id: procedureId });
    },
    isLoading,
  };
}
