import React, { useState } from "react";
import { Button } from "../../../../shared/Button/Button";
import { useProcedures } from "./hooks/useProcedures";
import { useSubmit } from "./hooks/useSubmit";
import { Modal } from "../../../../shared/Modal/Modal";
import { Footer } from "./shared/Footer";
import { AlertBox } from "../../../../shared/AlertBox/AlertBox";
import { Select } from "../../../../shared/Select/Select";
import { Loading } from "../../../../boxes/Loading/Loading";

export function AttachProcedure({ invoiceId, onSuccess }) {
  var { 0: isOpen, 1: setIsOpen } = useState(false);
  var { 0: procedure, 1: setProcedure } = useState(undefined);

  var handleClose = () => {
    setIsOpen(false);
    setProcedure(undefined);
  };

  var procedures = useProcedures(isOpen ? invoiceId : undefined);

  var submit = useSubmit(() => {
    handleClose();
    onSuccess();
  });

  var isError = procedures.isError;
  var isLoading = procedures.isLoading;
  var isEmpty = !isError && !isLoading && procedures.options?.length === 0;
  var isVisible = !isError && !isLoading && procedures.options?.length > 0;

  return (
    <>
      <Button size="small" onClick={() => setIsOpen(true)}>
        Attach Procedure
      </Button>
      {isOpen && (
        <Modal
          isOpen
          footerNoBorder
          onClose={handleClose}
          header={<Modal.Title>Attach Procedure</Modal.Title>}
          footer={
            <Footer
              isSubmitDisabled={!procedure}
              isSubmitting={submit.isLoading}
              showSubmitButton={procedures.options?.length > 0}
              onClose={handleClose}
              onSubmit={() => {
                submit.initiate({
                  invoiceId,
                  procedureId: procedure.value,
                });
              }}
            />
          }
        >
          {isLoading && <Loading center />}
          {isError && (
            <AlertBox variant="error">
              Unable to fetch procedures. Please try again later.
            </AlertBox>
          )}
          {isEmpty && (
            <AlertBox>No eligible procedures found for attachment</AlertBox>
          )}
          {isVisible && (
            <Select
              isLoading={procedures.isLoading}
              options={procedures.options}
              value={procedure}
              onChange={setProcedure}
            />
          )}
        </Modal>
      )}
    </>
  );
}
