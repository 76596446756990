import React, { useState } from "react";
import cx from "clsx";
import { Button } from "../../../../../../../shared/Button/Button";
import styles from "../scss/AdditionalClinicSurges.module.scss";
import { Table } from "../../../../../../../shared/Table/Table";
import { DeleteIcon } from "../../../../../../../assets/Icons/DeleteIcon";
import { EditIcon } from "../../../../../../../assets/Icons/EditIcon";
import { rulesOptions } from "../AdditionalClinicSurgesModal.utils";
import CreateClinicAdditionalSurgesModal from "./CreateClinicAdditionalSurgesModal";
import CreateClinicAdditionalSurgesDelete from "./CreateClinicAdditionalSurgesDelete";

const CreateClinicAdditionalSurges = ({
  additionalTaxes,
  setAdditionalTaxes,
}) => {
  const [modal, setModal] = useState({ show: false, data: null });
  const [deleteModal, setDeleteModal] = useState({ show: false, id: null });

  const tableData = additionalTaxes?.map((item, index) => {
    const rules = item.type?.map((type) => {
      return rulesOptions.find((rule) => type === rule.value)?.label;
    });

    return {
      name: item.label,
      percentage: item.percentage,
      rules: rules.join(", "),
      taxable: item.taxable ? "Yes" : "No",
      action: (
        <div className={styles.actionCol}>
          <button
            className={cx(styles.actionBtn, styles.edit)}
            onClick={() =>
              setModal({ show: true, data: { ...item, id: index } })
            }
          >
            {" "}
            <EditIcon width="15px" />
          </button>
          <button
            className={cx(styles.actionBtn, styles.delete)}
            onClick={() => setDeleteModal({ show: true, id: index })}
          >
            <DeleteIcon width="15px" />
          </button>
        </div>
      ),
    };
  });

  return (
    <>
      <div className={styles.container}>
        <Button
          variant="text"
          rightAdornment={<div className={styles.plusIcon}>+</div>}
          onClick={() => setModal({ show: true, data: null })}
          className={styles.additionalSurchargesBtn}
        >
          Additional Clinic Surcharges
        </Button>
        <Table
          className={styles.surchargesTable}
          cols={[
            { data: "Name", accessor: "name" },
            { data: "Percentage", accessor: "percentage" },
            { data: "Rules", accessor: "rules" },
            { data: "Taxable", accessor: "taxable" },
            { data: "Action", accessor: "action" },
          ]}
          data={tableData}
        />
      </div>
      {modal.show && (
        <CreateClinicAdditionalSurgesModal
          modal={modal}
          setModal={setModal}
          additionalTaxes={additionalTaxes}
          setAdditionalTaxes={setAdditionalTaxes}
        />
      )}
      {deleteModal.show && (
        <CreateClinicAdditionalSurgesDelete
          deleteModal={deleteModal}
          setDeleteModal={setDeleteModal}
          additionalTaxes={additionalTaxes}
          setAdditionalTaxes={setAdditionalTaxes}
        />
      )}
    </>
  );
};

export default CreateClinicAdditionalSurges;
