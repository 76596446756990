import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { HTTP_ENDPOINTS } from "../../consts/api";
import { http } from "../../services/HttpService";
import { removeNullishFromShape, unwrapOr } from "../../utilities/general";

const requestSchema = yup.object({
  type: yup.string().required(),
  clinicId: yup.number().required(),
  membershipId: yup.number().required(),
  searchTerm: yup.string().nullable(),
  sortBy: yup.string().nullable(),
  sortDir: yup.string().nullable(),
});

const responseSchema = yup.object({
  fileName: yup.string().nullable(),
});

const composeResponse = (res) => {
  const data = unwrapOr(() => res.data.data, {});
  return {
    fileName: data.file_name || null,
  };
};

export function useDownloadPriceListMutation(options = {}) {
  return useMutation(async (dto) => {
    const req = requestSchema.validateSync(dto, {
      strict: true,
    });
    const res = await http.get(HTTP_ENDPOINTS.getPriceListDownloadLink(), {
      params: removeNullishFromShape({
        type: req.type,
        clinic_id: req.clinicId,
        membership_id: req.membershipId,
        term: req.searchTerm,
        sortby: req.sortBy,
        sortdir: req.sortDir,
      }),
    });
    return responseSchema.validateSync(composeResponse(res), {
      strict: true,
    });
  }, options);
}
