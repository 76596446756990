import * as yup from "yup";
import { useQuery } from "@tanstack/react-query";
import { http } from "../../../services/HttpService";
import { HTTP_ENDPOINTS, QUERY_KEYS } from "../../../consts/api";

export const SUBDOMAIN_STATUSES = {
  pending: "pending",
  active: "active",
  inactive: "inactive",
};

const responseSchema = yup.array().of(
  yup.object({
    id: yup.number().required(),
    domain: yup.string().required(),
    status: yup.string().required(),
    verified_at: yup.string().nullable(),
    created_at: yup.string().required(),
    cloudflare_domain_id: yup.string().required(),
  }),
);

export function useGetCustomDomainsQuery({ onError } = {}) {
  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.customDomains.customDomains],
    queryFn: async () => {
      const res = await http.get(
        HTTP_ENDPOINTS.customDomain.getCustomDomains(),
      );
      return responseSchema.validateSync(res.data?.data, {
        strict: true,
      });
    },
    onError: () => {
      onError?.();
    },
  });

  return {
    data,
    isLoading,
  };
}
