import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import parentClasses from "../../sass/ProviderRoom.module.scss";
import { useAppTranslation } from "../../../../i18n/useAppTranslation";
import { Button } from "../../../../shared/Button/Button";
import { ProcedureTabs } from "../../components/ProcedureTabs/ProcedureTabs";
import { PROVIDER_ROOM_PROCEDURE_TYPE } from "../../../../api/queries/useProviderRoomProceduresQuery";
import { useTableHealth } from "../hooks/useTableHealth";
import { Table } from "../../../../shared/Table/Table";
import { CircularProgress } from "../../../../shared/CircularProgress/CircularProgress";
import { isPendingHealthProcedureCompleted } from "../TabChartAudit.utils";
import { ConfirmModal } from "../../../../boxes/ConfirmModal/ConfirmModal";
import { ButtonMenu } from "../../../../shared/ButtonMenu/ButtonMenu";
import { svg } from "../../../../assets/svg";
import { useTabSignActions } from "../hooks/useTabSignActions";
import ReactSelect from "../../../../Components/Common/ReactSelect";
import { providerRoom } from "../../../../store/providerRoom";

const mapStateToProps = (state) => ({
  signList: providerRoom.selectors.selectProceduresSignList(state),
});

export const TabHealth = connect(mapStateToProps)(
  ({
    onChangeTab,
    isVisible,
    procedures,
    isSigning,
    isSigned,
    isNeedMd,
    availableClinicsList = [],
    onChangeClinic,
    clinicId,
    showHideActionButton,
    providerUserId,
    signList,
  }) => {
    const { tCommon } = useAppTranslation.Common();
    const { cols, data, resetSelected } = useTableHealth({
      procedures,
      showHideActionButton,
      providerUserId,
      signList,
    });

    const {
      actions,
      isIncompleteSignModalOpen,
      setIsIncompleteSignModalOpen,
      completeSign,
    } = useTabSignActions({
      selected: signList,
      procedures,
      isNeedMd,
      isProcedureCompletedPredicate: isPendingHealthProcedureCompleted,
    });

    const handleChangeTab = (type) => {
      onChangeTab(type);
      resetSelected();
      onChangeClinic("");
    };

    useEffect(() => {
      if (isSigned && signList.length > 0) {
        resetSelected();
      }
    }, [isSigned, signList]);

    const handleClinicChange = ({ id }) => {
      onChangeClinic(id.toString());
    };

    return (
      <>
        <div className={parentClasses.procedureTabsRow}>
          <ProcedureTabs
            value={PROVIDER_ROOM_PROCEDURE_TYPE.health}
            onChange={handleChangeTab}
          />
          <div className="col-12 col-sm-5 col-md-4 col-lg-3 p-r-0">
            <ReactSelect
              name={"id"}
              selectedId={clinicId}
              list={[
                { name: "All Clinics", id: "" },
                ...availableClinicsList,
              ].map((i) => ({
                value: i.id,
                label: i.name,
              }))}
              value={"value"}
              label={"label"}
              handleReactSelect={handleClinicChange}
              isValid={true}
              placeholder={"Select Clinic"}
              isMulti={false}
            />
          </div>
          {!showHideActionButton && (
            <ButtonMenu
              options={actions}
              renderButton={({ onToggleMenu, isMenuOpen }) => (
                <Button
                  size="small"
                  onClick={onToggleMenu}
                  isHovered={isMenuOpen}
                  isDisabled={signList.length === 0 || isSigning}
                  rightAdornment={
                    isSigning ? (
                      <CircularProgress size="tiny" />
                    ) : (
                      <img src={svg.chevronDownWhite} alt="" width="16px" />
                    )
                  }
                >
                  {tCommon("label.action")} ({signList.length})
                </Button>
              )}
            />
          )}
        </div>
        {isVisible && (
          <Table
            bgColor="white"
            cols={cols}
            data={data}
            headClassName={parentClasses.tableHead}
          />
        )}
        <ConfirmModal
          isOpen={isIncompleteSignModalOpen}
          onClose={() => setIsIncompleteSignModalOpen(false)}
          onCancel={() => setIsIncompleteSignModalOpen(false)}
          onConfirm={completeSign}
        >
          {tCommon("providerRoom.confirm.signMassIncomplete")}
        </ConfirmModal>
      </>
    );
  },
);

TabHealth.propTypes = {
  onChangeTab: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  procedures: PropTypes.array.isRequired,
  isSigning: PropTypes.bool.isRequired,
  isSigned: PropTypes.bool.isRequired,
};
