import React from "react";
import { Button } from "../../../../../../shared/Button/Button";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";
import styles from "./styles.module.scss";

export function Footer({
  onClose,
  onSubmit,
  isSubmitting,
  isSubmitDisabled,
  showSubmitButton,
}) {
  return (
    <div className={styles.root}>
      <Button variant="outlined" onClick={onClose}>
        Cancel
      </Button>
      {showSubmitButton && (
        <Button
          isDisabled={isSubmitting || isSubmitDisabled}
          onClick={onSubmit}
          leftAdornment={
            isSubmitting ? (
              <CircularProgress size="small" color="white" />
            ) : undefined
          }
        >
          Attach
        </Button>
      )}
    </div>
  );
}
