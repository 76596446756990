import React from "react";
import classes from "./Header.module.scss";

export function Header({ imageUrl, fullName, email, location, id }) {
  return (
    <div className={classes.root}>
      <img src={imageUrl} alt="" className={classes.image} />
      <div className={classes.info}>
        <div className={classes.name}>
          <a
            href={`/clients/profile/${id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {fullName}
          </a>
        </div>
        {email && (
          <div className={classes.infoRow}>
            <i className="fa fa-envelope" aria-hidden="true"></i>
            {email}
          </div>
        )}
        {location && (
          <div className={classes.infoRow}>
            <i className="fa fa-map-marker" aria-hidden="true"></i>
            {location}
          </div>
        )}
      </div>
    </div>
  );
}
