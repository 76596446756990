import { useEditProductPriceListMutation } from "../../../../../../api/mutations/useEditProductPriceListMutation";
import { uiNotification } from "../../../../../../services/UINotificationService";
import { productPricingMember } from "../../../../../../store/inventory/productPricingMember";
import { productPricingRegular } from "../../../../../../store/inventory/productPricingRegular";
import { dispatch } from "../../../../../../store/store";

export const useUpdate = () => {
  const { mutateAsync, isLoading } = useEditProductPriceListMutation();

  /**
   * @param {{
   *    productId: string | number;
   *    priceList: Array<object>;
   *    isRegularPrice?: boolean;
   *    isForAllClinics?: boolean;
   * }} param0
   */
  const initiate = async ({
    productId,
    priceList,
    isRegularPrice,
    isForAllClinics,
    showNotification = true,
  }) => {
    try {
      await mutateAsync({
        product_id: Number(productId),
        price_list: priceList || [],
        regular_price: Boolean(isRegularPrice),
        price_same_for_all_clinic: isForAllClinics,
      });

      if (showNotification) {
        uiNotification.success("Price list updated successfully");
      }

      return () => {
        dispatch(productPricingRegular.actions.setInitialized(false));
        dispatch(productPricingMember.actions.setInitialized(false));
      };
    } catch {
      uiNotification.error("Failed to update price list");
    }
  };

  return {
    initiate,
    isLoading,
  };
};
