export const REDUCER_NAME = "inventory/productPricingRegular";

export const ACTION_TYPES = {
  setClinics: `${REDUCER_NAME}/setClinics`,
  setInitialized: `${REDUCER_NAME}/setInitialized`,
  setForAllClinics: `${REDUCER_NAME}/setForAllClinics`,
  setPrices: `${REDUCER_NAME}/setPrices`,
  changePrice: `${REDUCER_NAME}/changePrice`,
  setOriginalToChangedPrices: `${REDUCER_NAME}/setOriginalToChangedPrices`,
};
