import React from "react";
import cx from "clsx";
import moment from "moment";
import classes from "./Summary.module.scss";
import { LabeledBox } from "../../shared/LabeledBox/LabeledBox";
import { useAppTranslation } from "../../../../../i18n/useAppTranslation";
import { Divider } from "../../../../../shared/Divider/Divider";
import { useWindowSize } from "../../../../../utilities/hooks/useWindowSize";
import { MOBILE_BREAKPOINT } from "../../SalesCheckoutInvoice.consts";
import { useInvoice } from "../../hooks/invoice/useInvoice";
import { useCurrentUserQuery } from "../../../../../api/queries/useUserQuery";
import { formatCurrency } from "../../../../../utilities/general";
import { InfoRow } from "./shared/InfoRow/InfoRow";

export function Summary() {
  const { tCommon } = useAppTranslation.Common();
  const { tSales } = useAppTranslation.Sales();
  const { width } = useWindowSize();
  const { invoice, tipPercentage } = useInvoice();
  const { data: user } = useCurrentUserQuery();

  const getProviderName = () => {
    if (invoice.procedure) {
      return invoice.procedure.doctorName;
    }
    return invoice.user?.fullName;
  };

  const getMergerProvidersName = () => {
    const providersName = invoice.mergedInvoices.map((invoice) => {
      if (invoice.procedure) {
        return invoice.procedure.doctorName;
      }
      return invoice.user?.fullName;
    });
    return providersName.join(", ");
  };

  return (
    <LabeledBox label={tCommon("label.summary")} className={classes.root}>
      <div className={classes.meta}>
        <div>
          <div className={classes.metaLabel}>{tCommon("label.patient")}</div>
          <div className={classes.metaValue}>{invoice?.patient?.fullName}</div>
        </div>
        <div>
          <div className={classes.metaLabel}>{tCommon("label.provider")}</div>
          <div className={classes.metaValue}>
            {invoice.isMerger ? getMergerProvidersName() : getProviderName()}
          </div>
        </div>
        <div>
          <div className={classes.metaLabel}>{tCommon("label.dateTime")}</div>
          <div className={classes.metaValue}>
            {moment(invoice?.createdAt).format(
              `${user.dateFormat} ${user.timeFormat}`,
            )}
          </div>
        </div>
        <div>
          <div className={classes.metaLabel}>{tCommon("label.invoice")}</div>
          <div
            className={classes.metaValue}
          >{`#${invoice?.invoiceNumber}`}</div>
        </div>
      </div>
      <Divider gap="30px" />
      {invoice?.invoiceItems?.length > 0 && (
        <>
          {width > MOBILE_BREAKPOINT ? (
            <table className={classes.productsTable}>
              <thead>
                <tr>
                  <th
                    className={cx(classes.metaLabel, classes.productsTableHead)}
                  >
                    {tSales("checkoutInvoice.summary.productAndServices")}
                  </th>
                  <th className={cx(classes.metaLabel, classes.center)}>
                    {tSales("checkoutInvoice.summary.unityAndQuantity")}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {invoice?.invoiceItems?.map((p) => {
                  let obj = p;
                  let productType = obj.productType;
                  let productName = "";
                  let usedProduct = "";
                  let cusProName =
                    p.customProductName ||
                    p.product?.name ||
                    p.customProductName ||
                    p.discountPackage?.name;

                  if (productType === "custom") {
                    productName = cusProName;
                  } else if (productType === "service") {
                    productName =
                      obj.service && obj.service.name
                        ? obj.service.name
                        : cusProName;
                  } else if (
                    productType === "others" ||
                    productType === "injectable"
                  ) {
                    productName =
                      obj.product && obj.product.name ? obj.product.name : "";
                  } else if (productType === "package") {
                    productName =
                      obj.discountPackage && obj.discountPackage.name
                        ? obj.discountPackage.name
                        : "";
                    if (obj.discountPackage.products) {
                      if (obj.discountPackage.products.length) {
                        usedProduct = obj.discountPackage.products.join(",");
                      }
                    }
                  } else if (productType === "consultation_fee") {
                    productName = "Consultation Fee";
                  } else if (productType === "treatment_plan") {
                    productName = "Treatment Plan";
                  } else if (
                    productType === "monthly_membership" &&
                    cusProName === "monthly_membership"
                  ) {
                    productName = "Monthly membership fee";
                  } else if (
                    productType === "monthly_membership" &&
                    cusProName === "one_time_setup_fee"
                  ) {
                    productName = "One time setup Fee";
                  } else if (
                    productType === "monthly_membership" &&
                    cusProName === "yearly_membership"
                  ) {
                    productName = "Yearly Membership Fee";
                  }

                  return (
                    <tr key={p.id}>
                      <td>
                        {productName}{" "}
                        {usedProduct !== "" ? "(" + usedProduct + ")" : ""}
                        <br />{" "}
                        {obj.perUnitDiscountText
                          ? "(" + obj.perUnitDiscountText + ")"
                          : ""}
                      </td>
                      <td className={classes.center}>
                        {p.productUnits || p.discountPackage?.quantity}
                      </td>
                      <td className={classes.price}>
                        {formatCurrency(p.totalProductPrice, invoice?.currency)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <div className={classes.productsMobile}>
              {invoice?.invoiceItems?.map((p) => (
                <div key={p.id}>
                  <div>
                    {p.customProductName ||
                      p.discountPackage?.name ||
                      p.product?.name}
                  </div>
                  <div>
                    <i>x{p.productUnits || p.discountPackage?.quantity}</i> |{" "}
                    <b>
                      {formatCurrency(p.totalProductPrice, invoice?.currency)}
                    </b>
                  </div>
                </div>
              ))}
            </div>
          )}
          <Divider gap="30px" />
        </>
      )}
      <div className={classes.totalWrap}>
        <InfoRow
          label={tCommon("label.pretaxFees")}
          value={formatCurrency(invoice?.additionalCharges, invoice?.currency)}
        />
        <InfoRow
          label={tCommon("label.subTotal")}
          value={formatCurrency(invoice?.subtotalAmount, invoice?.currency)}
        />
        <InfoRow
          label={tSales("checkoutInvoice.summary.tipAmount", {
            percentage: tipPercentage.toFixed(),
          })}
          value={formatCurrency(invoice?.tipAmount, invoice?.currency)}
        />
        {!!invoice?.discounts?.bd && (
          <InfoRow
            label={tSales("checkoutInvoice.summary.bdDiscount")}
            value={`-${formatCurrency(
              invoice?.discounts?.bd,
              invoice?.currency,
            )}`}
          />
        )}
        {!!invoice?.discounts?.xperience && (
          <InfoRow
            label={tSales("checkoutInvoice.summary.xperienceDiscount")}
            value={`-${formatCurrency(
              invoice?.discounts?.xperience,
              invoice?.currency,
            )}`}
          />
        )}
        {!!invoice?.discounts?.aspire && (
          <InfoRow
            label={tSales("checkoutInvoice.summary.aspireDiscount")}
            value={`-${formatCurrency(
              invoice?.discounts?.aspire,
              invoice?.currency,
            )}`}
          />
        )}
        {!!invoice?.discounts?.aspireCertificates && (
          <InfoRow
            label={tSales("checkoutInvoice.summary.aspireCertificatesDiscount")}
            value={`-${formatCurrency(
              invoice?.discounts?.aspireCertificates,
              invoice?.currency,
            )}`}
          />
        )}
        {!!invoice?.discounts?.evolus && (
          <InfoRow
            label={tSales("checkoutInvoice.summary.evolusDiscount")}
            value={`-${formatCurrency(
              invoice?.discounts?.evolus,
              invoice?.currency,
            )}`}
          />
        )}
        {!!invoice?.discounts?.custom && (
          <InfoRow
            label={tSales("checkoutInvoice.summary.customDiscount")}
            value={`-${formatCurrency(
              invoice?.discounts?.custom,
              invoice?.currency,
            )}`}
          />
        )}
        {!!invoice?.discounts?.total && (
          <InfoRow
            label={tSales("checkoutInvoice.summary.itemDiscount")}
            value={`-${formatCurrency(
              invoice?.discounts?.total,
              invoice?.currency,
            )}`}
          />
        )}
        {!!invoice?.discounts?.package?.value && (
          <InfoRow
            label={
              invoice?.discounts?.package?.name
                ? tSales("checkoutInvoice.summary.itemPackageDiscount", {
                    name: invoice.discounts.package.name,
                  })
                : tSales("checkoutInvoice.summary.itemDiscount")
            }
            value={`-${formatCurrency(
              invoice?.discounts?.package?.value,
              invoice?.currency,
            )}`}
          />
        )}
        {!!invoice?.prePaymentAdjustment && (
          <InfoRow
            label={tSales("checkoutInvoice.summary.walletDebits")}
            value={`-${formatCurrency(
              invoice?.prePaymentAdjustment,
              invoice?.currency,
            )}`}
          />
        )}
        <InfoRow
          label={tCommon("label.tax")}
          value={formatCurrency(invoice?.totalTax, invoice?.currency)}
        />
        <InfoRow
          label={tCommon("label.nonTaxableFees")}
          value={formatCurrency(invoice?.customTax, invoice?.currency)}
        />
        <div className={classes.total}>
          <div>{tCommon("label.total")}</div>
          <div>{formatCurrency(invoice?.totalAmount, invoice?.currency)}</div>
        </div>
      </div>
    </LabeledBox>
  );
}
