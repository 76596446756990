import { useMutation } from "@tanstack/react-query";
import { http } from "../../../../../../../services/HttpService";
import { HTTP_ENDPOINTS } from "../../../../../../../consts/api";

export const useDeleteAdditionalClinicSurges = ({ clinicId, id }, options) => {
  const res = useMutation(
    (dto) =>
      http.delete(
        HTTP_ENDPOINTS.customTax.deleteCustomTax({ clinicId, id }),
        dto,
      ),
    { ...options },
  );
  return res;
};
