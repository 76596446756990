export const patientsSalesRestrictedReports = [
  {
    label: "Client has had X in X time",
    sysname: "x-done-in-x-time",
  },
  {
    label: "Client has had X and Y",

    sysname: "had-x-and-y",
  },
  {
    label: "Client has had X and not Y",

    sysname: "had-x-and-not-y",
  },
  {
    label: "Clients who spent $X in Y Months",
    sysname: "patients-who-spent-$x-in-y-Months",
  },
  {
    label: "Clients who have received a Refund in X time",
    sysname: "patients-who-have-reveived-a-refund-in-x-time",
  },
  {
    label: "Average Transaction Size",
    sysname: "average-transaction-size",
  },
  {
    label: "Top 20% VIP Clients who spend the most with our practice",
    sysname: "top-20-percent-vip-patients-who-spend-the-most-with-our-practice",
  },
  {
    label: "Client Discounts",
    sysname: "patient-discounts",
  },
];

export const inventorySalesRestrictedReports = [
  {
    label: "Previous year vs Current year",
    sysname: "previous-year-vs-current-year",
  },
  {
    label: "Inventory - Forecasting by % booked",
    sysname: "inventory-forecasting-percentage-booked",
  },
  {
    label: "Inventory - Ordering details",
    sysname: "inventory-ordering-details",
  },
  {
    label: "Previous Period vs Current period",
    sysname: "previous-period-vs-current-period",
  },
];

export const staffSalesRestrictedReports = [
  {
    label: "Commission Paid Out",
    sysname: "commission-paid-out",
  },
  {
    label: "Tips Paid Out",
    sysname: "tips-paid-out",
  },
  {
    label: "Staff Treatment Tracking",
    sysname: "staff-treatment-tracking",
  },
  {
    label: "Net Sales",
    sysname: "net-sales",
  },
  {
    label: "Average Sale Per Invoice/Appointment",
    sysname: "average-sale-per-appointment",
  },
];
