import { Maybe, identity } from "../../../utilities/fp";
import { ACTION_TYPES, DEFAULT_MEMBERSHIP } from "./config";
import { preparePrices } from "./utilities";

export const initialState = {
  initialized: false,
  forAllMemberships: true,
  forAllClinics: false,
  membership: DEFAULT_MEMBERSHIP,
  memberships: [],
  clinics: [],
  prices: {
    raw: [],
    original: {
      member: undefined,
      perClinic: {},
    },
    changed: {
      member: undefined,
      perClinic: {},
    },
    regular: {
      perUnit: undefined,
      perClinic: {},
    },
  },
  setDefaultMembershipAfterInit: true,
};

function setPrices(state, action) {
  const { preparedPrices, raw } = action.payload;
  const isForAllClinics = state.forAllClinics;

  const isForAllMemberships = Object.values(preparedPrices).every(
    (x) => x.membershipId === DEFAULT_MEMBERSHIP.value,
  );

  const regularPrices = raw.filter(
    (x) => x.membership_tier_id === DEFAULT_MEMBERSHIP.value,
  );

  const regularPricesPerClinic = regularPrices.reduce((carry, price) => {
    return {
      ...carry,
      [price.clinic_id]: {
        perUnit: price.price_per_unit || 0,
      },
    };
  }, {});

  const regularPrice = Object.values(regularPricesPerClinic)[0]?.perUnit;

  const member = isForAllClinics
    ? Maybe.of(Object.values(preparedPrices || {})[0]?.member)
        .map(identity)
        .orElse("")
        .value()
    : "";

  return {
    ...state,

    forAllMemberships: isForAllMemberships,

    membership: isForAllMemberships
      ? DEFAULT_MEMBERSHIP
      : state.memberships?.find(
          (x) => x.value === Object.values(preparedPrices)?.[0]?.membershipId,
        ) || state.membership,

    prices: {
      raw,
      original: {
        member,
        perClinic: {
          ...preparedPrices,
        },
      },
      changed: {
        member,
        perClinic: {
          ...preparedPrices,
        },
      },
      regular: {
        ...state.prices.regular,
        perUnit: regularPrice,
        perClinic: regularPricesPerClinic,
      },
    },
  };
}

function setForAllMemberships(state, action) {
  const { status } = action.payload;

  let nextPrices = {};

  if (status) {
    nextPrices = preparePrices(
      {
        clinics: state.clinics,
        rawPrices: state.prices.raw,
      },
      DEFAULT_MEMBERSHIP.value,
    );
  } else {
    const membershipId = state.memberships?.[1]?.value;

    nextPrices = preparePrices(
      {
        clinics: state.clinics,
        rawPrices: state.prices.raw,
      },
      membershipId,
    );
  }

  const stateWithPrices = setPrices(state, {
    payload: {
      preparedPrices: nextPrices,
      raw: state.prices.raw,
    },
  });

  return {
    ...state,
    ...stateWithPrices,
    forAllMemberships: status || false,
    membership: status
      ? DEFAULT_MEMBERSHIP
      : state.memberships?.[1] || state.membership,
  };
}

function setForAllClinics(state, action) {
  const { status } = action.payload;

  return {
    ...state,
    forAllClinics: status || false,
  };
}

function selectMembership(state, action) {
  const { membership } = action.payload;

  const nextPrices = preparePrices(
    {
      clinics: state.clinics,
      rawPrices: state.prices.raw,
    },
    membership.value,
  );

  const stateWithPrices = setPrices(state, {
    payload: {
      preparedPrices: nextPrices,
      raw: state.prices.raw,
    },
  });

  return {
    ...state,
    ...stateWithPrices,
    membership,
    forAllMemberships:
      String(membership.value) === String(DEFAULT_MEMBERSHIP.value),
  };
}

function setMemberships(state, action) {
  const { memberships } = action.payload;

  return {
    ...state,
    memberships,
  };
}

function changePrice(state, action) {
  const { value, clinicId } = action.payload;
  const nextChanged = state.prices.changed || {};

  if (clinicId) {
    nextChanged.perClinic[clinicId] = {
      ...nextChanged.perClinic[clinicId],
      member: value,
    };
  } else {
    nextChanged.member = value;
  }

  return {
    ...state,
    prices: {
      ...state.prices,
      changed: nextChanged,
    },
  };
}

function setInitialized(state, action) {
  const { status } = action.payload;

  return {
    ...state,
    initialized: status || false,
  };
}

function setClinics(state, action) {
  const { clinics } = action.payload;

  return {
    ...state,
    clinics,
  };
}

function setOriginalToChangedPrices(state) {
  return {
    ...state,
    prices: {
      ...state.prices,
      original: {
        ...state.prices.changed,
      },
    },
  };
}

function setDefaultMembershipAfterInit(state, action) {
  const { status } = action.payload;

  return {
    ...state,
    setDefaultMembershipAfterInit: status,
  };
}

export function reducer(state = initialState, action) {
  const actionByType = {
    [ACTION_TYPES.setForAllMemberships]: setForAllMemberships,
    [ACTION_TYPES.setForAllClinics]: setForAllClinics,
    [ACTION_TYPES.selectMembership]: selectMembership,
    [ACTION_TYPES.setMemberships]: setMemberships,
    [ACTION_TYPES.setPrices]: setPrices,
    [ACTION_TYPES.changePrice]: changePrice,
    [ACTION_TYPES.setInitialized]: setInitialized,
    [ACTION_TYPES.setClinics]: setClinics,
    [ACTION_TYPES.setOriginalToChangedPrices]: setOriginalToChangedPrices,
    [ACTION_TYPES.setDefaultMembershipAfterInit]: setDefaultMembershipAfterInit,
  };

  return actionByType?.[action.type]?.(state, action) || state;
}
