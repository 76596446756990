/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, memo } from "react";
import { isEqual } from "lodash";
import {
  checkIfPermissionAllowed,
  showFormattedDate,
} from "../../Utils/services";
import AcknowledgeHistoryModal from "./AcknowledgeHistoryModal";
import { ConfirmModal } from "../../boxes/ConfirmModal/ConfirmModal";
import { convertClientNote } from "../../_legacy/Queries";
import { uiNotification } from "../../services/UINotificationService";
import { extractApiError } from "../../utilities/api";
import StickyNoteImage from "../../_legacy/images/sticky-note.jpg";
import NoteEditModal from "./NoteEditModal";
import Loader from "../Common/Loader";
import { ReadFullNote } from "./ReadFullNote";
import { GENDERS } from "../../consts/api";

class CustomerNote extends Component {
  constructor(props) {
    super(props);

    this.state = {
      acknowledgeHistoryModalStatus: false,
      confirmConvertNoteModal: false,
      editNoteModal: false,
      isSticky: Boolean(this.props.noteobj.is_sticky),
      canUserEdit: Boolean(
        this.props.noteobj.is_sticky ||
          this.props.getLoggedInUserData() === this.props.noteobj.user_id,
      ),
      canUserDelete: Boolean(
        this.props.noteobj.is_sticky ||
          this.props.getLoggedInUserData() === this.props.noteobj.user_id,
      ),
      noteId: this.props.noteobj.id,
      modified: this.props.noteobj.modified,
      isNoteConverting: false,
      notesHistoryModalStatus: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { noteobj, getLoggedInUserData } = props;
    const { is_sticky, user_id } = noteobj;

    if (
      is_sticky !== state.isSticky ||
      noteobj.id !== this.state.noteId ||
      this.state.modified !== noteobj.modified
    ) {
      return {
        isSticky: Boolean(is_sticky),
        canUserEdit: Boolean(is_sticky || getLoggedInUserData() === user_id),
        canUserDelete: Boolean(is_sticky || getLoggedInUserData() === user_id),
        noteId: noteobj.id,
        modified: noteobj.modified,
      };
    }
  }

  toggleAcknowledgementHistoryModal = () => {
    this.setState((prevState) => ({
      acknowledgeHistoryModalStatus: !prevState.acknowledgeHistoryModalStatus,
      notesHistoryModalStatus: false,
    }));
  };

  toggleNotesHistoryModal = () => {
    this.setState((prevState) => ({
      notesHistoryModalStatus: !prevState.notesHistoryModalStatus,
      acknowledgeHistoryModalStatus: false,
    }));
  };

  toggleConfirmationConvertNote = () => {
    this.setState({
      confirmConvertNoteModal: !this.state.confirmConvertNoteModal,
    });
  };

  toggleEditNoteModal = () => {
    this.setState({ editNoteModal: !this.state.editNoteModal });
  };

  convertStickyNote = () => {
    this.setState({ isNoteConverting: true });

    convertClientNote(this.props.noteobj.id)
      .then(() => {
        uiNotification.success("Restored note successfully");
        this.props.fetchNotesList();
        this.state.isNoteConverting = false;
        this.setState({ isNoteConverting: false });
      })
      .catch((error) => {
        uiNotification.error(extractApiError(error) || error.message);
        this.setState({ isNoteConverting: false });
      })
      .finally(this.toggleConfirmationConvertNote);
  };

  editNote = (event) => {
    if (this.props.noteobj.is_sticky) {
      this.toggleEditNoteModal();
    } else {
      this.props.editThisNote(event);
    }
  };

  getIcon = (image, gender) => {
    if (image) {
      return image;
    }

    switch (gender) {
      case GENDERS.female:
        return "/images/appfemale.png";
      case GENDERS.male:
        return "/images/appmale.png";
      default:
        return "/images/appmale.png";
    }
  };

  render() {
    const {
      noteobj,
      noteidx,
      noteToShow,
      languageData,
      editThisNote,
      showDeleteModal,
      globalLang,
    } = this.props;

    const isAcknowledgeHistorHistory = this.state.acknowledgeHistoryModalStatus;
    const acknowldegeModelStatus = Boolean(
      isAcknowledgeHistorHistory || this.state.notesHistoryModalStatus,
    );

    return (
      <div id={noteobj.id} className="row" key={noteidx}>
        {this.state.isSticky ? (
          <span className="sticky-note-image">
            <img src={StickyNoteImage} alt="sticky-note" />
          </span>
        ) : (
          <span className="sticky-note-image">
            <img
              alt="sticky-note"
              src={this.getIcon(
                noteobj.user?.user_image_url,
                noteobj.user?.gender,
              )}
            />
          </span>
        )}
        <div className="col-sm-4 col-xs-12 col-md-3 note-subject">
          <h4>
            {noteobj.added_by || "Deleted User"}{" "}
            {this.state.isSticky
              ? globalLang.left_sticky_note_text
              : languageData.pro_left_a_note_text}
          </h4>
          <p>{showFormattedDate(noteobj.created, true)}</p>
          {!this.state.isSticky && noteobj.converted_at && (
            <div className="converted-history">
              <h4>
                {noteobj.converted_by ? noteobj.converted_by : "Deleted User"}{" "}
                <>{"CONVERTED A STICKY NOTE"}</>
              </h4>
              <p>{showFormattedDate(noteobj.converted_at, true)}</p>
            </div>
          )}
        </div>
        <div className="col-sm-4 col-xs-12 col-md-6" id="note-content">
          <div className="note-content" style={{ whiteSpace: "pre-wrap" }}>
            <div className="m-b-0">
              <ReadFullNote
                text={noteToShow.trim()}
                maxLines={"3"}
                title={noteobj.title || "Note"}
              />
            </div>
          </div>
          <textarea className="no-display" defaultValue="" />
        </div>
        {noteobj.user !== null && (editThisNote || showDeleteModal) && (
          <div className="col-sm-4 col-xs-12  col-md-3 no-padding">
            {checkIfPermissionAllowed("add-update-customer-notes") &&
            this.state.canUserEdit ? (
              <a
                id="edit-note"
                className="easy-link"
                name={noteobj.id}
                onClick={this.editNote}
              >
                {languageData.pro_edit_btn_text}
              </a>
            ) : (
              ``
            )}

            {checkIfPermissionAllowed("delete-customer-notes") &&
            this.state.canUserDelete ? (
              <a
                id="delete-note"
                className="easy-link"
                name={noteobj.id}
                onClick={() => showDeleteModal(noteobj)}
              >
                {languageData.pro_del_btn_text}
              </a>
            ) : (
              ``
            )}

            {(Boolean(noteobj.is_sticky) ||
              (Boolean(noteobj.converted_at) && Boolean(noteobj.title))) && (
              <a
                id="view-sticky-note-history"
                className="easy-link"
                name={noteobj.id}
                onClick={this.toggleAcknowledgementHistoryModal}
              >
                Acknowledge History
              </a>
            )}

            <a
              id="view-sticky-note-history"
              className="easy-link"
              name={noteobj.id}
              onClick={this.toggleNotesHistoryModal}
            >
              Notes History
            </a>
            {Boolean(noteobj.is_sticky) && (
              <a
                id="view-sticky-note-history"
                className="easy-link"
                name={noteobj.id}
                onClick={this.toggleConfirmationConvertNote}
              >
                Convert
              </a>
            )}
          </div>
        )}
        {acknowldegeModelStatus && (
          <AcknowledgeHistoryModal
            id={noteobj.id}
            onClose={
              isAcknowledgeHistorHistory
                ? this.toggleAcknowledgementHistoryModal
                : this.toggleNotesHistoryModal
            }
            isAcknowledgeHistorOrNotesHistory={isAcknowledgeHistorHistory}
            noteType={
              this.state.notesHistoryModalStatus ? "client-note" : false
            }
          />
        )}

        {this.state.confirmConvertNoteModal && (
          <ConfirmModal
            className="confirm-modal-center"
            isOpen
            onConfirm={this.convertStickyNote}
            onCancel={this.toggleConfirmationConvertNote}
            onClose={this.toggleConfirmationConvertNote}
          >
            Are you sure you want to convert this sticky note to a normal note?
            <br />
            <div className="delete-note-confirmation-display">
              {`"${noteToShow.trim()}"`}
            </div>
            {this.state.isNoteConverting === true && <Loader showLoader />}
          </ConfirmModal>
        )}

        {this.state.editNoteModal && (
          <NoteEditModal
            noteDetails={this.props.noteobj}
            closeNoteActionModal={this.toggleEditNoteModal}
            refetchNotesList={this.props.fetchNotesList}
            updateNotesList={this.props.updateNotesList}
          />
        )}
      </div>
    );
  }
}

export default memo(CustomerNote, (prevProps, currentProps) => {
  return isEqual(prevProps.noteobj, currentProps.noteobj);
});
