import { useEffect } from "react";
import { dispatch } from "../../../../../../../store/store";
import { preparePrices } from "../../../../../../../store/inventory/productPricingRegular/utilities";
import { productPricingRegular } from "../../../../../../../store/inventory/productPricingRegular";
import { useClinics } from "../../hooks/useClinics";

const { actions } = productPricingRegular;

export function useInit(initData, isInitialized) {
  const clinics = useClinics();

  const { product } = initData || {
    product: {},
  };

  useEffect(() => {
    if (!isInitialized && clinics.isSuccess) {
      const prices = [...(product?.product_price_per_clinic || [])];
      prices.reverse();

      dispatch(
        actions.setForAllClinics(Boolean(product?.price_same_for_all_clinic)),
      );

      dispatch(
        actions.setPrices(
          preparePrices({
            clinics: clinics.data || [],
            rawPrices: prices,
          }),
          prices,
        ),
      );

      dispatch(
        actions.setClinics(
          clinics.data.map((c) => ({
            id: c.id,
            name: c.clinic_name,
          })),
        ),
      );

      dispatch(actions.setInitialized(true));
    }
  }, [
    clinics.data,
    clinics.isSuccess,
    product?.product_price_per_clinic,
    isInitialized,
  ]);

  return { clinics: clinics.data, isLoading: clinics.isLoading };
}
