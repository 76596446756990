import React from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useMountClearentInput } from "../../../../../../hooks/useMountClearentInput";
import { Input } from "../../../../../../shared/Input/Input";
import { InputLabel } from "../../../../../../shared/InputLabel/InputLabel";
import { Button } from "../../../../../../shared/Button/Button";
import { CircularProgress } from "../../../../../../shared/CircularProgress/CircularProgress";

const NewCard = ({ onSubmit, showLoader, publicKey, clientData }) => {
  useMountClearentInput({
    publicKey,
    formId: "new-card-clearent",
  });

  const { values, setFieldValue, errors, handleSubmit } = useFormik({
    validationSchema: yup.object({
      zipCode: yup.string().required(),
      email: yup.string().email().required(),
    }),
    initialValues: {
      zipCode: clientData.zipCode,
      email: clientData.email,
    },
    onSubmit: (values) => {
      onSubmit(new Event("submit"), {
        zipCode: values.zipCode,
        email: values.email,
      });
    },
  });

  return (
    <>
      <div id="new-card-clearent" className="m-b-10" />
      <div className="flex gap-16 m-b-15">
        <div className="grow">
          <InputLabel>Zip Code</InputLabel>
          <Input
            size="small"
            value={values.zipCode}
            onChange={(event) => setFieldValue("zipCode", event.target.value)}
            isError={Boolean(errors.zipCode)}
          />
        </div>
        <div className="grow">
          <InputLabel>Email</InputLabel>
          <Input
            size="small"
            value={values.email}
            onChange={(event) => setFieldValue("email", event.target.value)}
            isError={Boolean(errors.email)}
          />
        </div>
      </div>
      <Button
        onClick={handleSubmit}
        size="small"
        isDisabled={showLoader}
        leftAdornment={
          showLoader ? <CircularProgress size="tiny" color="white" /> : null
        }
      >
        Save
      </Button>
    </>
  );
};

export default NewCard;
