import React from "react";
import PropTypes from "prop-types";
import { Modal } from "../../shared/Modal/Modal";
import classes from "./ConfirmModal.module.scss";
import { Button } from "../../shared/Button/Button";
import { CircularProgress } from "../../shared/CircularProgress/CircularProgress";

export function ConfirmModal({
  isOpen,
  title,
  onClose,
  onConfirm,
  onCancel,
  children,
  confirmTitle,
  cancelTitle,
  className,
  headerClassName,
  footerClassName,
  isConfirming,
  contentClassName,
  size,
}) {
  return (
    <Modal
      size={size}
      isOpen={isOpen}
      onClose={onClose}
      headerClassName={headerClassName}
      footerClassName={footerClassName}
      contentClassName={contentClassName}
      footerNoBorder
      header={title && <Modal.Title>{title}</Modal.Title>}
      footer={
        (onConfirm || onCancel) && (
          <div className={classes.buttons}>
            {onConfirm && (
              <Button
                onClick={onConfirm}
                isDisabled={isConfirming}
                leftAdornment={
                  isConfirming ? (
                    <CircularProgress size="small" color="white" />
                  ) : undefined
                }
              >
                {confirmTitle}
              </Button>
            )}
            {onCancel && (
              <Button variant="outlined" onClick={onCancel}>
                {cancelTitle}
              </Button>
            )}
          </div>
        )
      }
      className={className}
      shouldCloseOnOverlayClick={Boolean(onClose)}
      withCloseBtn={Boolean(onClose)}
    >
      {children}
    </Modal>
  );
}

ConfirmModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  children: PropTypes.node.isRequired,
  confirmTitle: PropTypes.string,
  cancelTitle: PropTypes.string,
  className: PropTypes.string,
  headerClassName: PropTypes.string,
  footerClassName: PropTypes.string,
  isConfirming: PropTypes.bool,
};

ConfirmModal.defaultProps = {
  title: "Confirmation Required!",
  confirmTitle: "Confirm",
  cancelTitle: "Cancel",
  className: "",
  headerClassName: undefined,
  footerClassName: undefined,
  isConfirming: false,
};
