import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Redirect, Route, Switch, withRouter } from "react-router";
import { connect } from "react-redux";
import { LayoutMain } from "../../boxes/LayoutMain/LayoutMain";
import { unwrapOr } from "../../utilities/general";
import { Tabs } from "../../shared/Tabs/Tabs";
import { PROVIDER_ROOM_ROUTES } from "../../consts/routes";
import TabChartAudit from "./TabChartAudit/TabChartAudit";
import TabSignedProcedures from "./TabSignedProcedures/TabSignedProcedures";
import TabSentToMd from "./TabSentToMd/TabSentToMd";
import TabSignedByMd from "./TabSignedByMd/TabSignedByMd";
import { TabHiddenProcedures } from "./TabHiddenProcedures/TabHiddenProcedures";
import classes from "./sass/ProviderRoom.module.scss";
import { useAppTranslation } from "../../i18n/useAppTranslation";
import { PROCEDURE_STATE_TYPES } from "../../consts/general";
import { providerRoom } from "../../store/providerRoom";
import { ModalSign } from "./components/ModalSign/ModalSign";
import { dispatch } from "../../store/store";
import { PROVIDER_ROOM_PROCEDURE_TYPE } from "../../api/queries/useProviderRoomProceduresQuery";

function ProviderRoom({ match, history, signData }) {
  const { tCommon } = useAppTranslation.Common();
  const activeUrlTab = unwrapOr(
    () => match.params.type,
    PROCEDURE_STATE_TYPES.pending,
  );

  const isFromCharAudit = history?.location?.state;
  const tabs = [
    {
      label: tCommon("providerRoom.nav.pending"),
      type: PROCEDURE_STATE_TYPES.pending,
      component: TabChartAudit,
    },
    {
      label: tCommon("providerRoom.nav.signedProcedures"),
      type: PROCEDURE_STATE_TYPES.signedProcedures,
      component: TabSignedProcedures,
    },
    {
      label: tCommon("providerRoom.nav.sentToMd"),
      type: PROCEDURE_STATE_TYPES.sentToMd,
      component: TabSentToMd,
    },
    {
      label: tCommon("providerRoom.nav.signedByMd"),
      type: PROCEDURE_STATE_TYPES.signedByMd,
      component: TabSignedByMd,
    },
    {
      label: tCommon("providerRoom.nav.hiddenProcedures"),
      type: PROCEDURE_STATE_TYPES.hiddenProcedures,
      component: TabHiddenProcedures,
    },
  ];

  const changeTab = (type) => {
    dispatch(
      providerRoom.actions.setActiveTab(PROVIDER_ROOM_PROCEDURE_TYPE.cosmetic),
    );
    if (isFromCharAudit) {
      history.push({
        pathname: PROVIDER_ROOM_ROUTES.root(type),
        state: isFromCharAudit,
      });
    } else {
      history.push(PROVIDER_ROOM_ROUTES.root(type));
    }
  };
  return (
    <LayoutMain>
      <Tabs
        active={activeUrlTab}
        className={classes.tabs}
        options={tabs.map((tab) => ({
          label: tab.label,
          value: tab.type,
          onClick: () => changeTab(tab.type),
        }))}
      />

      {isFromCharAudit && (
        <div className={classes.backBtn}>
          <i className="fa fa-angle-left m-r-3"></i>
          <Link to="/business-insight/chart-audit" className={classes.link}>
            Go Back
          </Link>
        </div>
      )}

      <Switch>
        {tabs.map((tab) => (
          <Route
            key={tab.type}
            exact
            path={PROVIDER_ROOM_ROUTES.root(tab.type)}
            component={tab.component}
          />
        ))}
        <Route
          path="*"
          render={() => (
            <Redirect
              to={PROVIDER_ROOM_ROUTES.root(PROCEDURE_STATE_TYPES.pending)}
            />
          )}
        />
      </Switch>
      {Boolean(signData) && (
        <ModalSign
          isOpen
          data={signData}
          onClose={() =>
            dispatch(providerRoom.actions.signProceduresDataReset())
          }
        />
      )}
    </LayoutMain>
  );
}

ProviderRoom.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  signData: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    signData: providerRoom.selectors.selectSignProceduresData(state),
  };
}

export default connect(mapStateToProps)(withRouter(ProviderRoom));
