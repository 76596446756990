import { useEffect, useRef } from "react";
import { dispatch } from "../../../store/store";
import { providerRoom } from "../../../store/providerRoom";

export function useRoomStoreMultiselect({ allEntities, roomSignList }) {
  const prevSelectedCount = useRef(0);

  const isSelected = (entity) => {
    return roomSignList.includes(entity);
  };

  const isSelectedAll = () => {
    return allEntities?.length === roomSignList?.length;
  };

  const onToggleAll = () => {
    const selected = isSelectedAll() ? [] : allEntities;

    dispatch(providerRoom.actions.addFilledSignList(selected));
  };

  const onRemoveOne = (entity) => {
    dispatch(providerRoom.actions.removeProcedureFromSignList(entity));
  };

  const onToggleOne = (entity) => {
    const check = isSelected(entity);

    if (!check) {
      return dispatch(providerRoom.actions.addProcedureToSignList(entity));
    }

    onRemoveOne(entity);
  };

  const resetSelected = () => {
    dispatch(providerRoom.actions.clearProcedureSignList());
  };

  const isSelectedPartial = () => {
    return (
      roomSignList?.length < allEntities?.length && roomSignList?.length !== 0
    );
  };

  useEffect(() => {
    if (
      roomSignList.length > 0 &&
      prevSelectedCount.current === roomSignList.length &&
      prevSelectedCount.current !== allEntities.length
    ) {
      onToggleAll();
    }

    if (prevSelectedCount.current !== allEntities.length) {
      prevSelectedCount.current = allEntities.length;
    }
  }, [allEntities]);

  return {
    isSelected,
    onToggleOne,
    onToggleAll,
    onRemoveOne,
    resetSelected,
    isSelectedAll,
    isSelectedPartial,
  };
}
