import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { daysOfWeek, monthsOfYear, timeSlots } from "../../_legacy/Constants";
import { uiNotification } from "../../services/UINotificationService";
import { axios } from "../../Utils";
import { extractApiError } from "../../utilities/api";
import { QueryKeys } from "./";
import { tCommon } from "../../i18n/useAppTranslation";

const GetWaitlist = (payload) => {
  const url = "/waitlist/list";

  let filters = { ...payload };
  const checkAnyPreference = () => {
    Object.keys(filters).forEach((property) => {
      switch (property) {
        case "month":
          if (filters[property] === monthsOfYear[0].id) {
            filters[property] = null;
          }
          break;
        case "day":
          if (filters[property] === daysOfWeek[0].id) {
            filters[property] = null;
          }
          break;
        case "timeslot_id":
          if (filters[property] === timeSlots[0].id) {
            filters[property] = null;
          }
          break;
        default:
          break;
      }
    });
    return filters;
  };
  return axios({ url, params: checkAnyPreference() });
};

export const useGetWaitlistQuery = (payload) => {
  const { open: _, ...filters } = payload;

  return useQuery(
    [QueryKeys.GET_WAITLIST, filters],
    () => GetWaitlist(filters),
    {
      enabled: true,
    },
  );
};

const GetWaitlistDetails = (id) => {
  const url = `/waitlist/details/${id}`;
  return axios({ url });
};

export const useGetWaitlistDetailsQuery = (id) => {
  return useQuery([QueryKeys.GET_WAITLIST_DETAILS, id], () =>
    GetWaitlistDetails(id),
  );
};

const UpdateWaitlistDetails = (data) => {
  const url = `/waitlist/update/${data.id}`;
  const method = "POST";
  return axios({ url, data, method });
};

var ERROR_MESSAGES = {
  cant_mix_in_person_and_virtual_services:
    "You can't mix in-person and virtual services.",
};

export const useUpdateWaitlistDetailsQuery = () => {
  const queryClient = useQueryClient();
  return useMutation(UpdateWaitlistDetails, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.GET_WAITLIST);
      queryClient.invalidateQueries(QueryKeys.GET_WAITLIST_DETAILS);
      uiNotification.success("Waitlist details updated successfully");
    },
    onError: (error) => {
      const message = extractApiError(error);
      uiNotification.error(ERROR_MESSAGES[message] || message || error.message);
    },
  });
};

const DeleteWaitlist = (id) => {
  const url = `/waitlist/delete/${id}`;
  const method = "POST";
  return axios({ url, method });
};

export const useDeleteWaitlistQuery = () => {
  return useMutation(DeleteWaitlist, {
    onSuccess: () => {
      uiNotification.success(tCommon("waitlist.appointment_waitlist_deleted"));
    },
  });
};
